import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { LieuStockageArticleDto, LieuStockageArticlesLexiClient, LieuStockageDto, TrackingField, UpsertLieuStockageArticleDto, Permissions } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { formatDate } from '@angular/common';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../../references/references';
import DataSource from 'devextreme/data/data_source';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { ArticleListService } from 'lexi-angular/src/app/services/article.service';
import { DxDataGridComponent } from 'devextreme-angular';

const DATAGRID_CONF_STORAGE_KEY_BASE_LIEU_STOCKAGE = 'grille-lieu-stockage-article-from-lieu-stockage';
const DATAGRID_CONF_STORAGE_KEY_BASE_ARTICLE = 'grille-lieu-stockage-article-from-article';

@Component({
  selector: 'app-lieu-stockage-article',
  templateUrl: './lieu-stockage-article.component.html',
  styleUrls: ['./lieu-stockage-article.component.scss'],
})
export class LieuStockageArticleComponent implements OnInit{
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value) {
      this.setDatagridHeight();
      this.cdr.detectChanges();
    }
  }

  _offsetTopInPx: number;
  get offsetTopInPx(): number { return this._offsetTopInPx; }
  @Input() set offsetTopInPx(value: number) {
    this._offsetTopInPx = value;
    this.setDatagridHeight();
  }

  @Input() articleId: number;
  @Input() lieuStockageId: number;
  @Input() siteId: number;
  @Input() titre: string = "";
  @Input() loadDataOnInit = false;

  public dataGridStorageKey: string;
  articleDataSource: DataSource;
  lieuStockageSociete: LieuStockageDto[];
  lieuStockageArticleDataSource: LieuStockageArticleDto[];
  lieuStockageDataSource: LieuStockageDto[] = [];
  dataSource: LieuStockageArticleDto[];
  isAdding: boolean = false;
  canGererLieuStockage: boolean = false;
  switchValue: boolean = false;
  accessFromFicheArticle: boolean = false;
  selectedArticleId: number;

  constructor(
    private readonly lieuStockageArticle: LieuStockageArticlesLexiClient,
    private readonly authService: AuthService,
    private readonly articleService: ArticleListService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.canGererLieuStockage = this.authService.securityUserisGrantedWith(Permissions.canGererLieuStockages);
  }

  ngOnInit(): void {
    this.dataGridStorageKey = this.articleId != null ? DATAGRID_CONF_STORAGE_KEY_BASE_ARTICLE : DATAGRID_CONF_STORAGE_KEY_BASE_LIEU_STOCKAGE;
    this.accessFromFicheArticle = this.articleId != null;
    this.setArticleDataSource();
    if (this.loadDataOnInit) {
      this.setDataSource();
    }
  }

  async setDataSource() {
    if (this.articleId != null) {
      this.lieuStockageArticleDataSource = await lastValueFrom(this.lieuStockageArticle.getByArticleId(this.articleId));
    } else if (this.lieuStockageId != null) {
      this.lieuStockageArticleDataSource = await lastValueFrom(this.lieuStockageArticle.getByLieuStockageId(this.lieuStockageId));
    }
  }

  switchValueChanged() {
    this.setLieuStockage();
  }

  setLieuStockage() {
    this.lieuStockageDataSource = this.switchValue
      ? this.lieuStockageSociete.filter(x => x.siteId == this.siteId)
      : this.lieuStockageSociete
  }

  setArticleId(articleId: number) {
    this.articleId = articleId;
  }

  setArticleDataSource() {
    const additionalParams = new Map().set('isRechercheAvancee', false);
    additionalParams.set("onlyStockable", true);
    additionalParams.set("avecVariante", true);
    this.articleDataSource = new DataSource({
      key: 'id',
      paginate: true,
      pageSize: 10,
      store: new CustomStoreService(this.articleService).getSelectBoxCustomStore(additionalParams)
    });
  }

  onRowInserting = async (event) => {
    let data: UpsertLieuStockageArticleDto = event.data;
    data.articleId ??= this.articleId ?? this.selectedArticleId;
    data.lieuStockageId ??= this.lieuStockageId;
    try {
      await lastValueFrom(this.lieuStockageArticle.create(data));
      notify(
        { closeOnClick: true, message: "Lieu de stockage ajouté avec succès." },
        NotificationType.Success,
      );
    } catch {
      notify(
        { closeOnClick: true, message: "Erreur lors de l'ajout du lieu de stockage." },
        NotificationType.Error,
      );
    }
    finally {
      this.isAdding = false;
      await this.setDataSource();
    }
  }

  onArticleChanged = (event) => {
    this.selectedArticleId = event.value;
  }

  onRowRemoving = async (event) => {
    try {
      await lastValueFrom(this.lieuStockageArticle._delete(event.data.articleId, event.data.lieuStockageId));
      notify(
        { closeOnClick: true, message: "Lieu de stockage supprimé avec succès." },
        NotificationType.Success,
      );
    } catch {
      notify(
        { closeOnClick: true, message: "Erreur lors de la suppression du lieu de stockage." },
        NotificationType.Error,
      );
    } finally {
      await this.setDataSource();
    }
  }

  onRowUpdating = async (event) => {
    let data: UpsertLieuStockageArticleDto = event.newData;
    data.lieuStockageId = event.key.lieuStockageId;
    data.articleId ??= this.articleId;
    try {

      await lastValueFrom(this.lieuStockageArticle.createOrUpdate(data));
      await this.setDataSource();
      notify(
        { closeOnClick: true, message: "Lieu de stockage modifié avec succès." },
        NotificationType.Success,
      );
    } catch {
      notify(
        { closeOnClick: true, message: "Erreur lors de la modification du lieu de stockage." },
        NotificationType.Error,
      );
    } finally {
      await this.setDataSource();
    }
  }

  onInitNewRow() {
    this.isAdding = true;
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('LieuStockageArticle');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {

          // Données
          if (gridCell.column.caption === 'Données' && gridCell.value != null) {
            excelCell.value = `${(gridCell.data.data?.fields?.map((x: TrackingField) => `${x.name}        ${x.oldValue ?? '∅'} ➞ ${x.newValue}\n`))}`;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-lieu-stockage-article.xlsx`);
      });
    });
  }

  onEditCanceled() {
    this.isAdding = false;
  }

  setDatagridHeight() {
    if (this.dataGrid == null || this.offsetTopInPx == null) return;
    this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
  }
}
