import { TypeUtilisateur } from "@lexi-clients/lexi";

export class LexiUser {
  constructor(
    readonly id: number,
    readonly subject: string,
    readonly username: string,
    readonly email: string,
    readonly isRoot: boolean,
    readonly typeUtilisateur: TypeUtilisateur,
    readonly accesAutorise: boolean,
    readonly partenaireId: number,
    readonly partenaireIds: Array<number> | null,
    readonly activites: { [key: string]: Array<string> },
    readonly siteId: number
  ) {}

  public static readonly ANONYMOUS = new LexiUser(null, null, '', null, false, null, null, null, null, {}, null);

  public isGrantedWith(activite: string, scope?: number[]) {
    const userRoles = Object.values(this.activites).reduce((accumulator, value) => accumulator.concat(value), []);
    return userRoles.includes(activite);
  }

  public isAuthenticated(): boolean {
    return !!this.subject;
  }

  public isAuthorized(): boolean {
    return !!this.accesAutorise;
  }
}
