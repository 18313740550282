import { Component, Input } from '@angular/core';
import { FluxEtapeDto, FluxLexiClient, ObjectType } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-flux-etape-icon',
  templateUrl: './flux-etape-icon.component.html',
  styleUrls: ['./flux-etape-icon.component.scss'],
})
export class FluxEtapeIconComponent {
  private _objectType: ObjectType;
  @Input() set objectType(value: ObjectType) {
    this._objectType = value;
    if (value != null) {
      this.loadComponentData();
    }
  }

  @Input() evenementTypeId: number;
  @Input() title: string;
  @Input() backgroundColor: string = "white";
  @Input() imageHeight: string = "16";
  @Input() imageWidth: string = "16";
  @Input() wrapperHeight: string = "24px";
  @Input() wrapperWidth: string = "24px";
  fluxEtapeDataSource: FluxEtapeDto[];

  constructor(private readonly fluxLexiClient: FluxLexiClient) { }

  async loadComponentData() {
    if (!this._objectType) return;
    this.fluxEtapeDataSource = await lastValueFrom(this.fluxLexiClient.getEtapesByObjectType(this._objectType));
  }

  getEtapeLogo() {
    if (!this.evenementTypeId || !this.fluxEtapeDataSource) return null;
    const logo = this.fluxEtapeDataSource.find(x => x.evenementTypeId == this.evenementTypeId)?.evenementTypeLogo;
    return logo
      ? 'data:image/png;base64,' + this.fluxEtapeDataSource.find(x => x.evenementTypeId == this.evenementTypeId)?.evenementTypeLogo
      : "assets/images/Discount.png";
  }
}
