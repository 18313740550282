import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentService } from 'lexi-angular/src/app/environment.service';
import { InternationalisationService } from 'lexi-angular/src/app/services/internationalisation.service';

// Exemples d'utilisation
// {{ 'referenceKey' | internationalisation }}
// {{ 'referenceKey' | internationalisation:'defaultValue' }}
@Pipe({ name: 'internationalisation' })
export class InternationalisationPipe implements PipeTransform {
  useInternationalisation: boolean = false;

  constructor(
    private readonly internationalisationService: InternationalisationService,
    envService: EnvironmentService
  ) {
    this.useInternationalisation = envService.environment.useInternationalisation;
  }

  transform(referenceKey: string, defaultValue?: string): string {
    if (this.useInternationalisation && referenceKey) {
      return this.keepFirstLetterUppercase(
        this.internationalisationService.currenReferences?.[referenceKey.toLowerCase()] ?? defaultValue ?? referenceKey,
        referenceKey
      );
    }
    return defaultValue ?? referenceKey;
  }

  private keepFirstLetterUppercase(input: string, reference: string): string {
    if (reference.charAt(0) == reference.charAt(0).toUpperCase()) {
      return this.capitalizeFirstLetter(input);
    }
    else {
      return input;
    }
  }

  private capitalizeFirstLetter(str) {
    if (!str) return '';

    var firstCodeUnit = str[0];

    if (firstCodeUnit < '\uD800' || firstCodeUnit > '\uDFFF') {
      return str[0].toUpperCase() + str.slice(1);
    }

    return str.slice(0, 2).toUpperCase() + str.slice(2);
  }
}
