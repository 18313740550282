<div style="margin: 12px;">
  <dx-button text="Créer" hint="Ajouter un paramétrage" (onClick)="dataGrid?.instance?.addRow()" style="margin-right: 12px;"></dx-button>
</div>

<dx-data-grid 
  id=parametrageSeuilsDataGrid
  [dataSource]="dtoDataSource.parametrageSeuils"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onSaved)="onCreateUpdateDeleteSeuil()"
  (onKeyDown)="onKeyDown($event)"
>

  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        Paramétrages supplémentaires pour {{ dtoDataSource.codeBo }}
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="methode" dataType="string" [width]="200">
    <dxo-lookup [dataSource]="methodeDataSource" displayExpr="intitule" valueExpr="id" width="25%"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="value" dataType="number" format="#,##0.##" [width]="200"></dxi-column>
  <dxi-column dataField="seuilQuantite" dataType="number" sortOrder="asc" [width]="200"></dxi-column>
  <dxi-column dataField="seuilMontant" dataType="number" format="#,##0.## F" sortOrder="asc" [width]="200"></dxi-column>
</dx-data-grid>