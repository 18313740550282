<h2 class="content-block">Liste des transporteurs</h2>

<div style="padding: 12px;">
  <div style="margin-bottom: 6px;">
    <dx-button text="Rafraîchir" (onClick)="setTransporteurs()"></dx-button>
  </div>

  <dx-data-grid
    id="transporteurListDataGrid"
    [dataSource]="transporteurs"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    (onRowUpdated)="updateTransporteur($event)"
  >
    <!-- Toolbar -->
    <dxo-toolbar>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setTransporteurs()"></dx-button>
        </div>
      </dxi-item>
    </dxo-toolbar>

    <!-- Options -->
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-editing [allowUpdating]="true"></dxo-editing>

    <!-- Colonnes -->
    <dxi-column dataField="codeBo" caption="Code" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="designation" caption="Intitulé" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="codeInternational" caption="Code Revatua">
      <dxi-validation-rule type="stringLength" [max]="10" message="Le code Revatua ne peut pas dépasser 10 caractères."></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="partenaireId" caption="Société" cellTemplate="displayPartenaireTemplate" editCellTemplate="editPartenaireTemplate">
      <div *dxTemplate="let cellInfo of 'displayPartenaireTemplate'">
        {{ cellInfo.data.partenaireCodeBo }}<span *ngIf="cellInfo.data.partenaireCodeBo"> - </span>{{ cellInfo.data.partenaireIntitule }}
      </div>

      <!-- SelectBox affiché lors de l'édition de la ligne -->
      <div *dxTemplate="let cellInfo of 'editPartenaireTemplate'">
        <dx-select-box
          [dataSource]="partenaireDataSource"
          [searchEnabled]="true"
          [showClearButton]="true"
          [searchExpr]="['intitule', 'codeBo']"
          [showDataBeforeSearch]="true"
          valueExpr="id"
          displayExpr="intitule"
          value="{{cellInfo.data.partenaireId}}"
          selectedItem="{{cellInfo.data.partenaireId}}"
          (onValueChanged)="cellInfo.setValue($event.value)"
        ></dx-select-box>
      </div>
    </dxi-column>
  </dx-data-grid>
</div>