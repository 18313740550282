import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GetSiteListDto, LieuStockageDto, LieuStockageNatureDto, LieuStockageNaturesLexiClient, LieuStockagesLexiClient, Permissions, SitesLexiClient } from '@lexi-clients/lexi';
import notify from 'devextreme/ui/notify';
import { lastValueFrom } from 'rxjs';
import { NotificationType } from '../../references/references';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { DxTreeListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';

@Component({
  selector: 'app-lieu-stockage-tree-list',
  templateUrl: './lieu-stockage-tree-list.component.html',
  styleUrls: ['./lieu-stockage-tree-list.component.scss'],
})
export class LieuStockageTreeListComponent {
  private _treeList: DxTreeListComponent;
  get treeList() { return this._treeList; }
  @ViewChild('treeList', { static: false }) set treeList(value: DxTreeListComponent) {
    this._treeList = value;
    if (value && this.heightToDeduce != null) {
      value.instance.element().style.height = `calc(100vh - ${this.heightToDeduce}px)`;
    }
  }

  private _heightToDeduce: number;
  get heightToDeduce() { return this._heightToDeduce; }
  @Input() set heightToDeduce(value: number) {
    this._heightToDeduce = value;
    if (value && this.treeList != null) {
      this.treeList.instance.element().style.height = `calc(100vh - ${value}px)`;
    }
  }

  // collapse tree list
  expandedRowKeys: Number[] = [];

  // Inputs
  private _partenaireId: number;
  get partenaireId(): number {
    return this._partenaireId;
  }
  @Input() set partenaireId(value: number) {
    if (value != null && value != undefined) {
      this._partenaireId = value;
      this.setLieuxStockage();
    }
  }
  @Input() enableActions: boolean = false;
  @Input() currentSiteId: number;
  @Input() partenaireCodeBo: string;

  // output
  @Output() onUpdateLieuStockage = new EventEmitter();

  // filtres partenaires société
  filterByCurrentPartenaire: boolean = true;
  filtres = [
    { id: true, intitule: "Partenaire en cours" },
    { id: false, intitule: "Société en cours" }
  ];

  dataSource: LieuStockageDto[];
  siteDataSource: GetSiteListDto[];
  naturesLieuStockageDataSource: LieuStockageNatureDto[];
  partenaireDataSource: DataSource;

  // permission de voir les lieux de la société
  canUseFilterBySociete = false;

  constructor(
    private readonly lieuStockagesLexiClient: LieuStockagesLexiClient,
    private readonly sitesLexiClient: SitesLexiClient,
    private readonly auth: AuthService,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly lieuStockageNatureService: LieuStockageNaturesLexiClient
  ) {
    this.canUseFilterBySociete = this.auth.securityUserisGrantedWith(Permissions.accesVuesReserveesAuNiveauSociete)
      && this.partenaireId != null;
    this.partenaireDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox();
  }

  // get data source
  async setLieuxStockage() {
    this.siteDataSource = await lastValueFrom(this.sitesLexiClient.getBySociete());
    this.naturesLieuStockageDataSource = await lastValueFrom(this.lieuStockageNatureService.getAll());

    this.dataSource = this.filterByCurrentPartenaire
    ? await lastValueFrom(this.lieuStockagesLexiClient.getByPartenaire(this.partenaireId))
    : await lastValueFrom(this.lieuStockagesLexiClient.getAll());
  }

  sortByIntitule (rowData) {
    const column = this as any;
    const value = column.calculateCellValue(rowData);
    return column.lookup.calculateCellValue(value);
  }

  // #region Gestion des évènements
  onCollapse = () => {
    this.expandedRowKeys = [];
  }

  async onFilterByCurrentSiteChanged() {
    await this.setLieuxStockage();

    if(this.filterByCurrentPartenaire) {
      this.treeList.instance.columnOption("path", "visibleIndex", 0);
      this.treeList.instance.columnOption("siteId", "visibleIndex", 1);
    }
    else {
      this.treeList.instance.columnOption("path", "visibleIndex", 1);
      this.treeList.instance.columnOption("siteId", "visibleIndex", 0);
    }
  }

  // Update lieux de stockage
  onRowUpdating = async (e) => {
    try {
      const lieuStockage: LieuStockageDto = e.oldData;
      for(const field of Object.keys(e.newData)) {
        e.oldData[field] = e.newData[field];
      }

      // Association du lieu de stockage à un partenaire
      e.oldData.partenaireId = this.partenaireId;

      if (e.oldData?.lieuParentId == 0) e.oldData.lieuParentId = null;
      await lastValueFrom(this.lieuStockagesLexiClient.update(lieuStockage.id, e.oldData));
      notify({closeOnClick: true, message: "Lieu de stockage modifié avec succès"}, NotificationType.Success);
      this.onUpdateLieuStockage.emit();
    }
    finally {
      await this.setLieuxStockage();
    }
  }

  onRowRemoving = async (e) => {
    try {
      const lieuStockage: LieuStockageDto = e.data;
      await lastValueFrom(this.lieuStockagesLexiClient._delete(lieuStockage.id));
      notify({closeOnClick: true, message: "Lieu de stockage supprimé avec succès"}, NotificationType.Success);
      this.onUpdateLieuStockage.emit();
    }
    finally {
      await this.setLieuxStockage();
    }
  }

  onRowInserting = async (e) => {
    try {
      const lieuStockage: LieuStockageDto = e.data;
      // Association du lieu de stockage à un partenaire
      lieuStockage.partenaireId = this.partenaireId;

      if (lieuStockage?.lieuParentId == 0) lieuStockage.lieuParentId = null;
      await lastValueFrom(this.lieuStockagesLexiClient.create(lieuStockage));
      notify({closeOnClick: true, message: "Lieu de stockage créé avec succès"}, NotificationType.Success);
      this.onUpdateLieuStockage.emit();
    }
    finally {
      await this.setLieuxStockage();
    }
  }

  onInitNewRow(e: {data: LieuStockageDto}) {
    e.data.stockageFonds = true;
    e.data.stockageMarchandise = true;
    e.data.venteAutorisee = true;
    e.data.stockNegatifAutorise = false;
    e.data.sessionOuverte = false;
    e.data.suiviNumeroSerie = false;
    e.data.isDeleted = false;
    e.data.siteId = this.currentSiteId;
    e.data.codeBo = this.partenaireCodeBo;
  }

  async onKeyDown(e) {
    if (this.enableActions && e.event.key === "Insert") {
        this.treeList.instance.addRow();
    }
  }
}
