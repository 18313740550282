import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { PartenaireListResult } from '../models/partenaire-list';
import { PartenaireDto } from '@lexi-clients/lexi';

@Injectable({
  providedIn: 'root'
})
export class PartenaireListService implements IGridService<PartenaireDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<PartenaireListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Partenaires/recherche-devexpress`, { params: params });
  }

  getById(partenaireId: number): Observable<PartenaireDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Partenaires/${partenaireId}`);
  }

  post(valeur: PartenaireDto): Observable<any> {
    return null;
  }

  put(valeur: PartenaireDto): Observable<any> {
    return null;
  }

  delete(valeur: PartenaireDto): Observable<any> {
    return null;
  }
}
