import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationCollaborateurMenuId, NavigationTop } from '../../models/navigation-top';
import {  ParametrageRevatuaLexiClient, Permissions } from '@lexi-clients/lexi';
import { AuthService } from '../../settings/auth.service';

@Injectable()
export class ModuleService {
  public currentModule = new BehaviorSubject<number>(null);

  dicoLicencesModules: { idMenu: NavigationCollaborateurMenuId; allowAccess: boolean }[] = [];

  constructor(
    private parametrageRevatuaLexiClient: ParametrageRevatuaLexiClient,
    private authService: AuthService
  ) {
    this.currentModule.next(1);
  }

  set selectModule(moduleId: number) {
    this.currentModule.next(moduleId);
  }

  enableModulesActifs(navigation: NavigationTop[]): NavigationTop[] {
    this.setDicoLicencesModules();
    const modulesActifsMenuIds = this.dicoLicencesModules.filter((menuAndModule) =>
      menuAndModule.allowAccess
    ).map(menuAndModule => menuAndModule.idMenu);

    // On affiche toujours l'onglet Comptabilite tant que l'utilisateur a la permission
    if (this.authService.securityUserisGrantedWith(Permissions.canGererEcrituresComptables)) {
      modulesActifsMenuIds.push(NavigationCollaborateurMenuId.comptabilite);
    }

    return navigation
      // Pour chaque NavigationTop, consèrve ceux étant associés à un Module Actif
      .filter(menuItem => modulesActifsMenuIds.some(menuId => menuId === menuItem.id))
      // Active tous ces NavigationTop sauf cas spécifique (eg. Fret Local)
      .map(menuItem => {

        const item: NavigationTop = {
          id: menuItem.id,
          title: menuItem.title,
          visible: true,
          navigation: menuItem.navigation,
        };

        return item;
      });
  }

  private setDicoLicencesModules() {
    this.dicoLicencesModules = [
      {  idMenu: NavigationCollaborateurMenuId.vente, allowAccess: this.authService.securityUserisGrantedWith(Permissions.canAccederModuleVente)}, // Point de vente
      {  idMenu: NavigationCollaborateurMenuId.logistique, allowAccess: this.authService.securityUserisGrantedWith(Permissions.canAccederModuleLogistique) }, // Logistique
      {  idMenu: NavigationCollaborateurMenuId.sav, allowAccess: this.authService.securityUserisGrantedWith(Permissions.canAccederModuleSav)}, // Sav
      //{ idMenu: NavigationCollaborateurMenuId.douane }, // Douane
      {  idMenu: NavigationCollaborateurMenuId.fretLocal, allowAccess: this.authService.securityUserisGrantedWith(Permissions.canAccederModuleFretLocal) }, // Fret local
      {  idMenu: NavigationCollaborateurMenuId.kpi, allowAccess: this.authService.securityUserisGrantedWith(Permissions.canAccederModuleKpi) }, // KPI
    ];
  }
}
