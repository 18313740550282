import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ConditionnementsLexiClient, GetConditionnementDto, UniteDouaneDto, UniteDto, UnitesDouanesLexiClient, UnitesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-conditionnement-list',
  templateUrl: './conditionnement-list.component.html',
  styleUrls: ['./conditionnement-list.component.scss']
})
export class ConditionnementListComponent implements OnInit {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value) {
      this.setDatagridHeight();
      this.cdr.detectChanges();
    }
  }

  _offsetTopInPx: number;
  get offsetTopInPx(): number { return this._offsetTopInPx; }
  @Input() set offsetTopInPx(value: number) {
    this._offsetTopInPx = value;
    this.setDatagridHeight();
  }

  @Input() articleId: number;
  @Input() uniteBaseId: number;
  conditionnements: GetConditionnementDto[];
  uniteDataSource: UniteDto[];
  uniteDouaneDataSource: UniteDouaneDto[];
  uniteBase: UniteDto;

  constructor(
    private readonly conditionnementsLexiClient: ConditionnementsLexiClient,
    private readonly uniteService: UnitesLexiClient,
    private readonly uniteDouaneService: UnitesDouanesLexiClient,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    await this.setConditionnements();
    await this.setUnites();
  }

  async onRowInserting(conditionnement: GetConditionnementDto) {
    // synchronized est obligatoire alors on force une valeur dans le cas où
    // synchronized n'est pas défini
    if (!conditionnement._synchronized) {
      conditionnement._synchronized = false;
    }
    if (!conditionnement.poidsNet) {
      conditionnement.poidsNet = 0;
    }

    conditionnement.articleId = this.articleId;
    await lastValueFrom(this.conditionnementsLexiClient.createOrUpdate(conditionnement));
    await this.setConditionnements();
  }

  async onRowUpdating(e: any) {
    try {
      for (const field of Object.keys(e.newData)) {
        e.oldData[field] = e.newData[field];
      }
      await lastValueFrom(this.conditionnementsLexiClient.createOrUpdate(e.oldData));
    }
    finally {
      await this.setConditionnements();
    }
  }

  async onRowRemoving(conditionnement: GetConditionnementDto) {
    try {
      await lastValueFrom(this.conditionnementsLexiClient._delete(conditionnement.id));
    }
    finally {
      await this.setConditionnements();
    }
  }

  async setConditionnements() {
    this.conditionnements = await lastValueFrom(this.conditionnementsLexiClient.getByArticleId(this.articleId));
  }

  private async setUnites() {
    this.uniteDataSource = await lastValueFrom(this.uniteService.getListeUnites());
    this.uniteBase = this.uniteDataSource.find(u => u.id === this.uniteBaseId);
    this.uniteDouaneDataSource = await lastValueFrom(this.uniteDouaneService.getListeUnitesDouanes());
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
      this.dataGrid.instance.addRow();
    }
  }

  setDatagridHeight() {
    if (this.dataGrid == null || this.offsetTopInPx == null) return;
    this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
  }
}
