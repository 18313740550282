import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LieuStockageDto, MouvementSens, LieuStockagesLexiClient, DocumentType, FluxLexiClient, ObjectType, FluxEtapeDto,
  LexiMouvementTypeDto, LexiMouvementTypesLexiClient, BonsLexiClient, TypeImportBon, ImportBonResult, FluxStatut, Permissions } from '@lexi-clients/lexi';
import { DxSelectBoxComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { filter, lastValueFrom, Subscription } from 'rxjs';
import { NotificationType } from '../../references/references';
import { BonListGlobaleDatagridComponent } from '../bon-list-globale-datagrid/bon-list-globale-datagrid.component';
import { BonListLieuStockageDatagridComponent } from '../bon-list-lieu-stockage-datagrid/bon-list-lieu-stockage-datagrid.component';
import { LexiUser } from '@lexi/oidc-uaa';

enum FluxStatutSimplifieEnum {
  ouvert,
  ferme,
  tous
}

const includeInfosCalculeesStorageKey = "bonListIncludeInfosCalculeesValue";

@Component({
  selector: 'app-bon-list',
  templateUrl: './bon-list.component.html',
  styleUrls: ['./bon-list.component.scss'],
})
export class BonListComponent implements OnInit, OnDestroy, AfterContentInit {
  private subscriptions = new Subscription();
  @ViewChild("lieuStockageSelectBox") lieuStockageSelectBox: DxSelectBoxComponent;
  @ViewChild("bonListGlobaleDatagridComponent") bonListGlobaleDatagridComponent: BonListGlobaleDatagridComponent;
  @ViewChild("bonListLieuStockageDatagridComponent") bonListLieuStockageDatagridComponent: BonListLieuStockageDatagridComponent;
  readonly LIEU_STOCKAGE_STORAGE_KEY = "bon_list_lieu_stockage";
  MouvementSens: MouvementSens;
  lieuStockages: LieuStockageDto[];
  selectedLieuStockage: LieuStockageDto;
  currentSiteId: number;
  fluxEtapeDataSource: FluxEtapeDto[];
  mouvementTypeDataSource: LexiMouvementTypeDto[] = [];
  dropDownOptionsCreerBon = {
    height: '50vh'
  };

  files: File[] = [];
  showImportPopup = false;
  showLoader = false;
  isCreerBonBtnEnabled = false;
  messageCannotCreerBon = "";
  currentPartenaireId: number;
  currentFiltreStatut = FluxStatutSimplifieEnum.ouvert;
  fluxStatutSimplifieEnum = FluxStatutSimplifieEnum;
  includeInfosCalculees = false;

  readonly btnStatuts: Array<{text: string, clef: FluxStatutSimplifieEnum, statuts: FluxStatut[]}> = [
    { text: "Ouvert", clef: FluxStatutSimplifieEnum.ouvert, statuts: [FluxStatut.new, FluxStatut.opened, FluxStatut.paused] },
    { text: "Fermé", clef: FluxStatutSimplifieEnum.ferme, statuts: [FluxStatut.closed] },
    { text: "Tous", clef: FluxStatutSimplifieEnum.tous, statuts: null }
  ];

  selectedTypeFichierImportBon: TypeImportBon;
  typeFichierImportBon: any[] = [
    {id: TypeImportBon.stockAgenceParSousCaisse, label: "Stock agence par sous caisse"},
    // {id: TypeImportBon.import2, label: "Type d'import 2"}
  ]

  constructor(
    private readonly lieuStockagesLexiClient: LieuStockagesLexiClient,
    private readonly auth: AuthService,
    private readonly mouvementTypesLexiClient: LexiMouvementTypesLexiClient,
    private readonly router: Router,
    private readonly bonsLexiClient: BonsLexiClient,
    fluxLexiClient: FluxLexiClient
  ) {
    fluxLexiClient.getEtapesByObjectType(ObjectType.bon).subscribe(e => {
      this.fluxEtapeDataSource = e;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.includeInfosCalculees = localStorage.getItem(includeInfosCalculeesStorageKey) != null;
    this.setSelectedLieuStockageOnInit();
    this.subscriptions.add(
      this.auth.currentSiteId$.pipe(
        filter((s: number) => s != null)
      ).subscribe(async (siteId: number) => {
        this.currentSiteId = siteId;
        this.currentPartenaireId = this.auth.currentSite.partenaireId;
        this.lieuStockages = await lastValueFrom(this.lieuStockagesLexiClient.getBySite(siteId));
        await this.setMouvementTypes();
        this.dropDownOptionsCreerBon = { height: `${(this.mouvementTypeDataSource?.length ?? 1) * 40}px` };
        this.checkCurrentLieuStockageValid();// Clear selectedLieuStockage only if SiteId change
      })
    );

    this.subscriptions.add(
      this.auth.currentUser$.subscribe(user => {
        if (user) {
          this.setCreerBonPermission();
        }
      })
    )
  }

  ngAfterContentInit(): void {
    if (this.bonListGlobaleDatagridComponent || this.bonListLieuStockageDatagridComponent) {
      this.onStatutSelected(FluxStatutSimplifieEnum.ouvert);
    }
  }

  async setMouvementTypes() {
    const mouvementTypes = await lastValueFrom(this.mouvementTypesLexiClient.getMouvementType(DocumentType.stock, false, true, ObjectType.bon, true));
    this.mouvementTypeDataSource = mouvementTypes.filter(x => x.autorisationBonSeul && !x.bloque);
    this.setCreerBonPermission();
  }

  private setCreerBonPermission() {
    const canGererBons = this.auth.securityUserisGrantedWith(Permissions.canGererBons);

    if (this.mouvementTypeDataSource?.length > 0) {
      this.isCreerBonBtnEnabled = canGererBons;
      this.messageCannotCreerBon = canGererBons ? "" : "Vous n'avez pas la permission pour créer des bons";
    } else {
      this.isCreerBonBtnEnabled = false;
      this.messageCannotCreerBon = this.auth.securityUser?.isRoot
        ? "Activez l'option 'AutorisationBonSeul' sur les types de mouvements que vous voulez pouvoir créer directement"
        : "Aucun type de mouvement n'est disponible, il n'est pas possible de créer de bon sans passer par un document logistique";
    }
  }

  private setSelectedLieuStockageOnInit() {
    const storedString = localStorage.getItem(this.LIEU_STOCKAGE_STORAGE_KEY);
    if (storedString) {
      this.selectedLieuStockage = JSON.parse(storedString);
    }
  }

  private clearSelectedLieuStockage() {
    this.selectedLieuStockage = null;
    localStorage.removeItem(this.LIEU_STOCKAGE_STORAGE_KEY);
  }

  onValueChanged = (e: { value: number }) => {
    if (e.value) {
      this.selectedLieuStockage = this.lieuStockages.find(x => x.id == e.value);
      localStorage.setItem(this.LIEU_STOCKAGE_STORAGE_KEY, JSON.stringify(this.selectedLieuStockage));
    }
    else {
      this.clearSelectedLieuStockage();
    }
  }

  refreshDataSource() {
    this.selectedLieuStockage
      ? this.bonListLieuStockageDatagridComponent && this.bonListLieuStockageDatagridComponent.refreshDataGrid()
      : this.bonListGlobaleDatagridComponent && this.bonListGlobaleDatagridComponent.refreshDataGrid();
  }

  onStatutSelected(statutChoisi: FluxStatutSimplifieEnum) {
    if (this.currentFiltreStatut == statutChoisi) return;
    this.currentFiltreStatut = statutChoisi;
    if (statutChoisi == FluxStatutSimplifieEnum.tous) {
      this.selectedLieuStockage
        ? this.bonListLieuStockageDatagridComponent.resetFilterColumnHeader('fluxStatut')
        : this.bonListGlobaleDatagridComponent.resetFilterColumnHeader('fluxStatut');
    } else {
      const statuts = this.btnStatuts.find(x => x.clef == statutChoisi).statuts;
      this.selectedLieuStockage
        ? this.bonListLieuStockageDatagridComponent.filterColumnHeader('fluxStatut', statuts)
        : this.bonListGlobaleDatagridComponent.filterColumnHeader('fluxStatut', statuts);
    }
  }

  onCreateNewBon(e: { itemData: LexiMouvementTypeDto }) {
    this.router.navigate([`/bon/nouveau`], { queryParams: { mouvementTypeCodeBo: e.itemData.codeBo } });
  }

  async importBon(e: { itemData: LexiMouvementTypeDto }) {
    this.showLoader = true;
    this.bonsLexiClient.importFileToCreateDraft(this.selectedTypeFichierImportBon, e.itemData.id, this.files[0])
    var result: ImportBonResult = await lastValueFrom(this.bonsLexiClient.importFileToCreateDraft(this.selectedTypeFichierImportBon, e.itemData.id, this.files[0]));

    notify({closeOnClick: true, message: result.nombreBonsCrees }, NotificationType.Success, 3500);

    if (result.erreurs.length > 0) {
      result.erreurs.forEach(elem => {
        notify({closeOnClick: true, message: elem }, NotificationType.Error, 3500);
      });
    }

    this.refreshDataSource();
    this.onCloseImportPopup();
  }

  onTypeFichierImportValueChanged = (e: { value: TypeImportBon }) => {
    if (e.value) {
      this.selectedTypeFichierImportBon = e.value;
    }
  }

  onCloseImportPopup() {
    this.selectedTypeFichierImportBon = null;
    this.showLoader = false;
    this.showImportPopup = false;
    this.files = [];
  }

  checkCurrentLieuStockageValid() {
    const storedString = localStorage.getItem(this.LIEU_STOCKAGE_STORAGE_KEY);
    if (storedString) {
      const currentLieuStockage: LieuStockageDto = JSON.parse(storedString);

      if (!(this.lieuStockages.filter(e => e.id === currentLieuStockage.id).length > 0)) {
        this.selectedLieuStockage = null;
        localStorage.removeItem(this.LIEU_STOCKAGE_STORAGE_KEY);
      }
    }
  }

  storeIncludeInfosCalculeesValue() {
    if (this.includeInfosCalculees) {
      localStorage.setItem(includeInfosCalculeesStorageKey, "true");
    }
    else {
      localStorage.removeItem(includeInfosCalculeesStorageKey);
    }
  }
}
