import { Component, Input, ViewChild } from '@angular/core';
import { LogistiqueSettings } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-gestion-logistique',
  templateUrl: './gestion-logistique.component.html',
  styleUrls: ['./gestion-logistique.component.scss'],
})
export class GestionLogistiqueComponent {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  @Input() colCount: number = 2;
  @Input() colSpan: number = 1;
  @Input() logistiqueSettings: LogistiqueSettings = {};
  @Input() formIsReadOnly: boolean = false;
  @Input() isFromSociete: boolean = false;
  @Input() resetCompteur: () => Promise<void>;

  constructor() { }

  async onKeyDown(e) {
    if (!this.formIsReadOnly && e.event.key === "Insert") {
      this.datagrid.instance.addRow();
    }
  }
}
