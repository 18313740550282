import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  DxBoxModule, DxButtonModule, DxCheckBoxModule, DxDataGridModule,
  DxDropDownButtonModule, DxFormModule, DxLoadPanelModule, DxLookupModule, DxNumberBoxModule, DxPopoverModule, DxPopupModule,
  DxProgressBarModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule,
  DxTabPanelModule, DxTagBoxModule, DxTemplateModule, DxTooltipModule,
  DxSwitchModule,
  DxFileUploaderModule,
  DxLoadIndicatorModule,
  DxTreeListModule,
  DxTextBoxModule,
  DxListModule,
  DxTextAreaModule,
  DxButtonGroupModule,
  DxToolbarModule,
  DxFilterBuilderModule, DxDateBoxModule
} from "devextreme-angular";
import { RouterModule } from '@angular/router';
import { ReferenceService } from './references/references.service';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { CellCodebarreLinkComponent } from './cell-codebarre-link/cell-codebarre-link.component';
import { BackButton } from './back-button/back-button.component';
import { ComptageDetailComponent } from './numera/comptage-detail/comptage-detail.component';
import { CellEcartComponent } from './cell-ecart/cell-ecart.component';
import { XmlPipe } from './xml-pipe/xml.pipe'
import { GestionTerminauxComponent } from './gestion-terminaux/gestion-terminaux.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ParametrageGrilleComponent } from './parametrage-grille/parametrage-grille.component';
import { InternationalisationPipe } from './pipes/internationalisation.pipe';
import { MagasinPieceNatureDatagridComponent } from './magasin-piece-nature-datagrid/magasin-piece-nature-datagrid.component';
import { MouvementStockDatagridComponent } from './mouvement-stock-datagrid/mouvement-stock-datagrid.component';
import { LotStockageDatagridComponent } from './lot-stockage-datagrid/lot-stockage-datagrid.component';
import { StockViewComponent } from './stock-view/stock-view.component';
import { BonListGlobaleDatagridComponent } from './bon/bon-list-globale-datagrid/bon-list-globale-datagrid.component';
import { BonListLieuStockageDatagridComponent } from './bon/bon-list-lieu-stockage-datagrid/bon-list-lieu-stockage-datagrid.component';
import { BonListComponent } from './bon/bon-list/bon-list.component';
import { BonDetailComponent } from './bon/bon-detail/bon-detail.component';
import { BonDetailComptageComponent } from './bon/bon-detail/bon-detail-comptage/bon-detail-comptage.component';
import { MouvementStockViewComponent } from './mouvement-stock-view/mouvement-stock-view.component';
import { OperationCommercialeDetailListComponent } from './operation-commerciale-detail-list/operation-commerciale-detail-list.component';
import { OperationsCommercialesPourArticleDetailComponent } from './operations-commerciales-pour-article-detail/operations-commerciales-pour-article-detail.component';
import { TpvSettingsFormComponent } from './tpv-settings-form/tpv-settings-form.component';
import { BoSettingsFormComponent } from './bo-settings-form/bo-settings-form.component';
import { AuditPisteComponent } from './audit-piste/audit-piste.component';
import { CodeBarreArticleDetailComponent } from './code-barre-article-detail/code-barre-article-detail.component';
import { ArticleListDatagridComponent } from './article-list-datagrid/article-list-datagrid.component';
import { LotStockageMagasinDatagridComponent } from './lot-stockage-magasin-datagrid/lot-stockage-magasin-datagrid.component';
import { BonDetailEnteteComponent } from './bon/bon-detail/bon-detail-entete/bon-detail-entete.component';
import { BonDetailLieuStockageComponent } from './bon/bon-detail/bon-detail-lieu-stockage/bon-detail-lieu-stockage.component';
import { BonDetailLignesComponent } from './bon/bon-detail/bon-detail-lignes/bon-detail-lignes.component';
import { OperationCommercialeDetailsSeuilsComponent } from './operation-commerciale-detail-list/operation-commerciale-details-seuils/operation-commerciale-details-seuils/operation-commerciale-details-seuils.component';
import { NoFilterStorageDirective } from './directives/no-filter-storage.directive';
import { VoyageFretLocalSettingsFormComponent } from './voyage-fret-local-settings-form/voyage-fret-local-settings-form.component';
import { PartenaireFretLocalSettingsFormComponent } from './partenaire-fret-local-settings-form/partenaire-fret-local-settings-form.component';
import { DocumentListComponent } from '../../components/document-list/document-list.component';
import { FluxEtapePossibleListComponent } from './flux-etape-possible-list/flux-etape-possible-list.component';
import { BonDetailGestionDesColisComponent } from './bon/bon-detail/bon-detail-gestion-des-colis/bon-detail-gestion-des-colis.component';
import { BonDetailParametresComponent } from './bon/bon-detail/bon-detail-parametres/bon-detail-parametres.component';
import { LieuStockageTreeListComponent } from './lieux-stockage/lieu-stockage-tree-list/lieu-stockage-tree-list.component';
import { FluxHistoriqueListComponent } from './flux-historique-list/flux-historique-list.component';
import { BonDetailNumeroSerieComponent } from './bon/bon-detail/bon-detail-numero-serie/bon-detail-numero-serie.component';
import { FormsModule } from '@angular/forms';
import { BonListParArticleComponent } from './bon-list-par-article/bon-list-par-article.component';
import { NotesListComponent } from './notes-list/notes-list.component';
import { DocumentLogistiqueEnteteComponent } from './document-logistique-entete/document-logistique-entete.component';
import { FluxStatutIconComponent } from './flux-statut-icon/flux-statut-icon.component';
import { FluxEtapeIconComponent } from './flux-etape-icon/flux-etape-icon.component';
import { RegistreNumeroSerieListComponent } from './registre-numero-serie-list/registre-numero-serie-list.component';
import { StorageDirective } from './directives/storage.directive';
import { TooltipDateCreationComponent } from './tooltip-date-creation/tooltip-date-creation.component';
import { ArticleDetailBonListComponent } from './article-detail-bon-list/article-detail-bon-list.component';
import { ResetGridStateComponent } from './reset-grid-state/reset-grid-state.component';
import { GestionCaracteristiquesComponent } from './gestion-caracteristiques/gestion-caracteristiques.component';
import { PopupImportFichierExcelComponent } from './popup-import-fichier-excel/popup-import-fichier-excel.component';
import { BonAchatDatagridComponent } from './bon-achat-datagrid/bon-achat-datagrid.component';
import { ObjecttypeEditionPossibleListComponent } from './objecttype-edition-possible-list/objecttype-edition-possible-list.component';
import { ValorisationStockComponent } from "./valorisation-stock/valorisation-stock.component";
import { AllBonListComponent } from "./bon/all-bon-list/all-bon-list.component";
import { BonListTousSiteGlobaleDatagridComponent } from "./bon/all-bon-list/bon-list-tous-site-globale-datagrid/bon-list-tous-site-globale-datagrid.component";
import { LieuStockageArticleComponent } from "./lieux-stockage/lieu-stockage-article/lieu-stockage-article.component";
import { DossierListSubComponent } from './dossier-list/dossier-list-sub.component';
import { BackButtonDirective } from './directives/backbutton.directive';
import { ReglementCreationComponent } from './reglement-creation/reglement-creation.component';
@NgModule({
  declarations: [
    CellCodebarreLinkComponent,
    BackButton,
    ComptageDetailComponent,
    CellEcartComponent,
    XmlPipe,
    GestionTerminauxComponent,
    ContactListComponent,
    ParametrageGrilleComponent,
    InternationalisationPipe,
    MagasinPieceNatureDatagridComponent,
    MouvementStockDatagridComponent,
    LotStockageDatagridComponent,
    StockViewComponent,
    ValorisationStockComponent,
    MouvementStockViewComponent,
    AllBonListComponent,
    BonListComponent,
    BonListGlobaleDatagridComponent,
    BonListLieuStockageDatagridComponent,
    BonDetailComponent,
    BonDetailEnteteComponent,
    BonDetailLieuStockageComponent,
    BonDetailLignesComponent,
    OperationCommercialeDetailListComponent,
    OperationCommercialeDetailsSeuilsComponent,
    OperationsCommercialesPourArticleDetailComponent,
    TpvSettingsFormComponent,
    AuditPisteComponent,
    CodeBarreArticleDetailComponent,
    ArticleListDatagridComponent,
    DossierListSubComponent,
    LotStockageMagasinDatagridComponent,
    NoFilterStorageDirective,
    VoyageFretLocalSettingsFormComponent,
    PartenaireFretLocalSettingsFormComponent,
    DocumentListComponent,
    FluxEtapePossibleListComponent,
    BonDetailGestionDesColisComponent,
    LieuStockageTreeListComponent,
    FluxHistoriqueListComponent,
    BonDetailNumeroSerieComponent,
    BonListParArticleComponent,
    NotesListComponent,
    DocumentLogistiqueEnteteComponent,
    FluxStatutIconComponent,
    FluxEtapeIconComponent,
    BonDetailComptageComponent,
    RegistreNumeroSerieListComponent,
    StorageDirective,
    BackButtonDirective,
    TooltipDateCreationComponent,
    ArticleDetailBonListComponent,
    BonDetailParametresComponent,
    ResetGridStateComponent,
    GestionCaracteristiquesComponent,
    BoSettingsFormComponent,
    PopupImportFichierExcelComponent,
    BonAchatDatagridComponent,
    ObjecttypeEditionPossibleListComponent,
    BonListTousSiteGlobaleDatagridComponent,
    LieuStockageArticleComponent,
    ReglementCreationComponent
  ],
  imports: [
    CommonModule,

    // Devextreme
    DxDataGridModule, DxTemplateModule, DxProgressBarModule,
    DxPopupModule, DxButtonModule, DxDropDownButtonModule,
    DxScrollViewModule, DxTooltipModule, DxCheckBoxModule,
    DxFormModule, DxSelectBoxModule, DxRadioGroupModule,
    DxTabPanelModule, DxLookupModule, DxBoxModule, DxNumberBoxModule,
    DxLoadPanelModule, DxTagBoxModule, DxSwitchModule, DxPopoverModule,
    DxFileUploaderModule, DxLoadIndicatorModule, DxTreeListModule,
    DxTextBoxModule, DxListModule, DxTextAreaModule, DxDateBoxModule,
    DxTextBoxModule, DxToolbarModule, DxButtonGroupModule, DxFilterBuilderModule,
    // end Devextreme
    RouterModule,
    NgxBarcode6Module,
    FormsModule,
    DecimalPipe
  ],
  exports: [
    CellCodebarreLinkComponent,
    BackButton,
    ComptageDetailComponent,
    CellEcartComponent,
    XmlPipe,
    GestionTerminauxComponent,
    ContactListComponent,
    ParametrageGrilleComponent,
    InternationalisationPipe,
    MagasinPieceNatureDatagridComponent,
    MouvementStockDatagridComponent,
    LotStockageDatagridComponent,
    StockViewComponent,
    MouvementStockViewComponent,
    BonListComponent,
    BonListGlobaleDatagridComponent,
    BonListLieuStockageDatagridComponent,
    BonDetailComponent,
    OperationCommercialeDetailListComponent,
    OperationCommercialeDetailsSeuilsComponent,
    OperationsCommercialesPourArticleDetailComponent,
    TpvSettingsFormComponent,
    AuditPisteComponent,
    CodeBarreArticleDetailComponent,
    ArticleListDatagridComponent,
    VoyageFretLocalSettingsFormComponent,
    PartenaireFretLocalSettingsFormComponent,
    DocumentListComponent,
    FluxEtapePossibleListComponent,
    BonDetailGestionDesColisComponent,
    BonDetailComptageComponent,
    LieuStockageTreeListComponent,
    FluxHistoriqueListComponent,
    BonListParArticleComponent,
    NotesListComponent,
    DocumentLogistiqueEnteteComponent,
    FluxStatutIconComponent,
    FluxEtapeIconComponent,
    RegistreNumeroSerieListComponent,
    NoFilterStorageDirective,
    StorageDirective,
    BackButtonDirective,
    TooltipDateCreationComponent,
    ArticleDetailBonListComponent,
    BonDetailParametresComponent,
    ResetGridStateComponent,
    GestionCaracteristiquesComponent,
    BonAchatDatagridComponent,
    ObjecttypeEditionPossibleListComponent,
    LieuStockageArticleComponent,
    DossierListSubComponent,
    ReglementCreationComponent,
  ],
  providers: [
    ReferenceService,
    InternationalisationPipe,
    DecimalPipe
  ]
})
export class SharedModule { }
