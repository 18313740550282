import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArticlePartenaireDto, LotStockageMarchandiseBySiteDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { LotStockageMarchandiseBySiteListResult } from '../models/lot-stockage-marchandise-by-site-list';
import { AuthService } from '../settings/auth.service';

@Injectable({ providedIn: 'root' })
export class LotStockageMarchandiseBySiteListService implements IGridService<LotStockageMarchandiseBySiteDto> {
  baseUrl: string;

  constructor(
    envService: EnvironmentService,
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<LotStockageMarchandiseBySiteListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/LotStockages/recherche-devexpress/marchandise/stock-magasin`, { params: params });
  }

  getById(lotStockageId: number): Observable<LotStockageMarchandiseBySiteDto> {
    return this.http.get<any>(`${this.baseUrl}/api/LotStockages/${lotStockageId}/marchandise/stock-magasin`);
  }

  post(valeur: LotStockageMarchandiseBySiteDto): Observable<any> {
    return null;
  }

  put(valeur: LotStockageMarchandiseBySiteDto): Observable<any> {
    const siteId = this.authService.currentSiteId;
    const dto: ArticlePartenaireDto = {
      articleId: valeur.articleId,
      siteId,
      quantiteMinimale: valeur.quantiteMinimale,
      quantiteMaximale: valeur.quantiteMaximale,
      bloque: false
    };
    return this.http.put<any>(`${this.baseUrl}/api/ArticlePartenaires`, dto);
  }

  delete(valeur: LotStockageMarchandiseBySiteDto): Observable<any> {
    return null;
  }
}
