import { NotificationType } from '../modules/shared/references/references';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { AuthService } from '../settings/auth.service';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { UaaService } from '@lexi/oidc-uaa';
import { environment } from '../../environments/environment';

@Injectable()
export class ScopeHeaderHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private uaa: UaaService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const domain = environment.baseServiceUrl ? environment.baseServiceUrl : window.location.origin;
    if (req.url.startsWith(domain)) {
      const siteScopeId = this.authService.currentSite?.id;
      const societeScopeId = this.authService.currentSociete?.id;
      const asUserScopeId = this.authService.currentAsUser?.id;

      let request: HttpRequest<any>;

      if (!siteScopeId && !societeScopeId && !asUserScopeId) {
        request = req;
      }

      else {
        const customHeaders = {};
        if (siteScopeId) {
          customHeaders["X-SiteId"] = siteScopeId.toString();
        }

        if (societeScopeId) {
          customHeaders["X-SocieteId"] = societeScopeId.toString();
        }

        if (asUserScopeId) {
          customHeaders["X-AsUserId"] = asUserScopeId.toString();
        }

        request = req.clone({ setHeaders: customHeaders });
      }

      return next.handle(request).pipe(catchError(err => {

        if (err.status === 403) {
          if (this.authService.authenticatedUser) {
            notify({ closeOnClick: true, message: "Vous n'êtes pas autorisé à effectuer cette action." }, NotificationType.Error);
            return;
          }
          this.logout();
          return;
        }

        return throwError(() => new Error(err))

      }));
    }
    return next.handle(req);
  }

  private logout() {
    this.uaa.logout();
    this.router.navigate(['/']);
  }
}
