import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { UniteListResult } from '../models/unite-list';
import { UniteDto } from '@lexi-clients/lexi';

@Injectable({
  providedIn: 'root'
})
export class UniteListService implements IGridService<UniteDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<UniteListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Unites/recherche-devexpress`, { params: params });
  }

  getById(uniteId: number): Observable<UniteDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Unites/${uniteId}`);
  }

  post(valeur: UniteDto): Observable<any> {
    return null;
  }

  put(valeur: UniteDto): Observable<any> {
    return null;
  }

  delete(valeur: UniteDto): Observable<any> {
    return null;
  }
}
