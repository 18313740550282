import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BonDto, BonAvecReferenceEtPaireDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { BonListResult } from '../models/bon-list';
import { IGridService } from '../models/igrid-service';

@Injectable({ providedIn: 'root' })
export class AllBonListService implements IGridService<BonDto | BonAvecReferenceEtPaireDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<BonListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Bons/recherche-devexpress-all`, { params: params });
  }

  getById(bonId: number): Observable<BonDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Bons/${bonId}`);
  }

  post(valeur: BonDto): Observable<any> {
    return null;
  }

  put(valeur: BonDto): Observable<any> {
    return null;
  }

  delete(valeur: BonDto): Observable<any> {
    return null;
  }
}
