import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ArticleForUpdateDto, ArticleType, ClassificationTypeDto, ClassificationTypesLexiClient, ObjectType, Permissions, RechercheDxArticleDto } from '@lexi-clients/lexi';
import { DxDataGridComponent, DxSwitchComponent, DxTextBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ArticleListService } from 'lexi-angular/src/app/services/article.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { PartenaireListService } from 'lexi-angular/src/app/services/partenaire.service';
import { Subscription, filter, lastValueFrom } from 'rxjs';
import { UniteListService } from 'lexi-angular/src/app/services/unite.service';
import { TaxeGroupeListService } from 'lexi-angular/src/app/services/taxe-groupe.service';
import { PrixListService } from 'lexi-angular/src/app/services/prix.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { ColumnButtonClickEvent, ExportingEvent } from 'devextreme/ui/data_grid';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { LotStockageDatagridComponent } from '../lot-stockage-datagrid/lot-stockage-datagrid.component';

@Component({
  selector: 'app-article-list-datagrid',
  templateUrl: './article-list-datagrid.component.html',
  styleUrls: ['./article-list-datagrid.component.scss'],
})
export class ArticleListDatagridComponent implements OnInit, OnDestroy {
  readonly articleTypeDataSource = [
    { id: ArticleType.backOffice, intitule: "Stockable" },
    { id: ArticleType.commentaire, intitule: "Commentaire" },
    { id: ArticleType.metadata, intitule: "Metadata" },
    { id: ArticleType.kit, intitule: "Kit" },
    { id: ArticleType.prestation, intitule: "Service" },
    { id: ArticleType.interne, intitule: "Interne" },
    { id: ArticleType.libre, intitule: "Libre" },
    { id: ArticleType.generique, intitule: "Générique" },
    { id: ArticleType.fret, intitule: "Frêt" },
    { id: ArticleType.assurance, intitule: "Assurance" },
  ];

  @ViewChild("dataGrid") dataGrid: DxDataGridComponent;
  @ViewChild("sansClassificationSwitchRef") sansClassificationSwitchRef: DxSwitchComponent;
  @ViewChild(LotStockageDatagridComponent) lotStockageDatagridRef: LotStockageDatagridComponent;

  private _textBox: DxTextBoxComponent;
  get textBox() { return this._textBox }
  @ViewChild('textBox') set textBox(value: DxTextBoxComponent) {
    this._textBox = value;
    this._textBox?.instance?.focus();
  }
  @Output() selectedArticlesIdsChanged = new EventEmitter<number[]>();
  @Output() selectedArticlesChanged = new EventEmitter<RechercheDxArticleDto[]>();
  @Output() closePopup = new EventEmitter();
  @Output() sansClassification = new EventEmitter<boolean>();
  @Output() classificationTypeChanged = new EventEmitter();

  @Input() set initComponent(value: boolean) { if (value === true) this.loadComponentData(); }
  @Input() grilleStorageKey = "grille_article_list";
  @Input() typeSelection: 'single' | 'multiple' | 'none' = 'none';
  @Input() showStockSociete = false;
  @Input() showStockSite = false;
  @Input() showBoutonArticleStock  = false;
  /** Set de cette valeur uniquement si on est hors d'une popup */
  @Input() set marginTop(value: number) {
    if (value != null) {
      this.resizeDataGrid(null, value);
    }
  }
  @Input() autoriserFiltreGlobal = true;
  @Input() articleDataSource: CustomStore | RechercheDxArticleDto[];

  // stock
  @Input() sourceStockageId = null;
  @Input() destinationStockageId = null;
  @Input() onlyStockable = null;
  @Input() enableRowDragging = false;
  @Input() classificationValueIds: number[];
  @Input() isInPopup = false;
  showStockSourceLegende = false;
  showStockDestinationLegende = false;

  inclureVariante: boolean = true;
  currentSiteIntitule: string;
  selectedArticlesIds: number[];
  selectedArticles: RechercheDxArticleDto[];
  uniteCustomStore: CustomStore;
  partenaireCustomStore: CustomStore;
  taxeGroupeCustomStore: CustomStore;
  prixCustomStore: CustomStore;
  classificationTypes: ClassificationTypeDto[];
  selectedClassificationTypeId: number;
  readonly selectedClassificationTypeIdStorageKey = "SelectionArticlesClassificationTypeId";
  selectedPrixId: number;
  readonly selectedPrixIdStorageKey = "SelectionArticlesPrixId";
  rechercheGlobale: string;
  filterValue = [];

  // popup stock par site
  private subscriptions = new Subscription();
  currentSiteId: number;
  isAffichagePMPAutorise: boolean = false;
  showPopupStockSite: boolean = false;
  selectedArticle: RechercheDxArticleDto;
  sansClassificationValue = false;

  constructor(
    private readonly authService: AuthService,
    private readonly articleListService: ArticleListService,
    private readonly partenaireListService: PartenaireListService,
    private readonly uniteListService: UniteListService,
    private readonly taxeGroupeListService: TaxeGroupeListService,
    private readonly prixListService: PrixListService,
    private readonly classificationTypesLexiClient: ClassificationTypesLexiClient,
    private readonly exportDataGridService: ExportDataGridService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe((siteId: number) => {
          this.currentSiteId = siteId;
          this.currentSiteIntitule = this.authService.currentSite.intitule;
      })
    );
    this.isAffichagePMPAutorise = this.authService.securityUserisGrantedWith(Permissions.canAfficherPmp);
  }

  async loadComponentData() {
    this.selectedArticlesIds = [];
    // LocalStorage
    const storedClassificationType = this.getFromLocalStorage(this.selectedClassificationTypeIdStorageKey, true);
    if (storedClassificationType) {
      this.selectedClassificationTypeId = storedClassificationType;
      this.classificationTypeChanged.emit();
    }
    // Par défaut, on prend la classification comptable de la société
    else if (this.authService.currentSociete?.classificationComptableTypeId != null) {
      this.selectedClassificationTypeId = this.authService.currentSociete.classificationComptableTypeId;
      this.classificationTypeChanged.emit();
    }
    const storedTypePrix = this.getFromLocalStorage(this.selectedPrixIdStorageKey, true);
    if (storedTypePrix) this.selectedPrixId = storedTypePrix;

    this.uniteCustomStore = new CustomStoreService(this.uniteListService).getCustomStore();
    this.partenaireCustomStore = new CustomStoreService(this.partenaireListService).getCustomStore();
    this.taxeGroupeCustomStore = new CustomStoreService(this.taxeGroupeListService).getCustomStore();
    this.prixCustomStore = new CustomStoreService(this.prixListService).getSelectBoxCustomStore();
    this.classificationTypes = await lastValueFrom(this.classificationTypesLexiClient.getByObjectType(ObjectType.article));
    this.setArticleDataSource();
  }

  setArticleDataSource(sansClassification: boolean = false) {
    this.sansClassificationValue = sansClassification;

    const additionalParams = new Map().set("isRechercheAvancee", true);
    additionalParams.set("avecVariante", this.inclureVariante);
    if (this.selectedClassificationTypeId != null) additionalParams.set("classificationTypeId", this.selectedClassificationTypeId);
    if (this.selectedPrixId != null) additionalParams.set("prixId", this.selectedPrixId);
    if (this.sourceStockageId != null) additionalParams.set("sourceStockageId", this.sourceStockageId);
    if (this.destinationStockageId != null) additionalParams.set("destinationStockageId", this.destinationStockageId);
    if (this.rechercheGlobale != null) additionalParams.set("rechercheGlobale", this.rechercheGlobale);
    if (this.onlyStockable != null) additionalParams.set("onlyStockable", this.onlyStockable);
    if (!sansClassification && this.classificationValueIds?.length > 0) additionalParams.set("classificationValueIds", this.classificationValueIds);
    if (sansClassification) additionalParams.set("sansClassification", true)
    this.articleDataSource = new CustomStoreService(this.articleListService).getCustomStore(additionalParams, [], {key: 'id'});
  }

  //#region Gestion des évènements
  onCancel = () => {
    this.dataGrid?.instance && this.dataGrid.instance.clearSelection();
    this.selectedArticlesIds = [];
    this.selectedArticles = [];
    this.closePopup.emit();
  }

  onConfirm = () => {
    this.selectedArticlesIdsChanged.emit(this.selectedArticlesIds);
    this.selectedArticlesChanged.emit(this.selectedArticles);
    this.onCancel();
  }

  onClassificationTypeChanged() {
    // LocalStorage
    this.selectedClassificationTypeId != null
      ? localStorage.setItem(this.selectedClassificationTypeIdStorageKey, JSON.stringify(this.selectedClassificationTypeId))
      : localStorage.removeItem(this.selectedClassificationTypeIdStorageKey);
    this.classificationTypeChanged.emit();
    this.setArticleDataSource(this.sansClassificationValue);
  }

  onPrixChanged() {
    // LocalStorage
    this.selectedPrixId != null
      ? localStorage.setItem(this.selectedPrixIdStorageKey, JSON.stringify(this.selectedPrixId))
      : localStorage.removeItem(this.selectedPrixIdStorageKey);
    this.setArticleDataSource(this.sansClassificationValue);
  }

  onSelectionChanged(e: { selectedRowKeys: number[], selectedRowsData: RechercheDxArticleDto[] }) {
    this.selectedArticlesIds = e.selectedRowKeys;
    this.selectedArticles = e.selectedRowsData;
  }

  onSansClassificationChanged(e: { value: boolean }) {
    if (e.value) {
      this.sansClassification.emit(e.value);
    }
    this.setArticleDataSource(e.value);
  }
  //#endregion

  //#region Méthodes helpers
  getDisplayExpression(article: ArticleForUpdateDto) {
    return article && article.codeBo + " - " + article.libelleLong;
  }

  getTexteLignesSelectionees() {
    const selectedRows = this.dataGrid?.instance.getSelectedRowsData();
    const s = selectedRows?.length > 1 ? 's' : '';
    return `${selectedRows?.length ?? 0} ligne${s} sélectionnée${s}`
  }

  classificationTypeDisplayExpr(type: ClassificationTypeDto) {
    return type && type.codeBo + " - " + type.intitule;
  }

  private getFromLocalStorage(key: string, mustBeNumber?: boolean): any {
    const storedString = localStorage.getItem(key);

    // Si doit être un nombre, si ça ne l'est pas on retire du storage
    if (mustBeNumber && isNaN(Number(storedString))) {
      localStorage.removeItem(key);
      return null;
    }
    return storedString ? JSON.parse(storedString) : null;
  }

  resizeDataGrid(hauteurEnPx: number = null, pxEnMoins: number = 0) {
    setTimeout(() => {
      // 56px filtres de recherche au dessus de la grille
      // 44px boutons confirmer sélection
      const heightToReduce = this.typeSelection == 'none' ? pxEnMoins + 56 : pxEnMoins + 56 + 44;
      const baseHeight = hauteurEnPx != null ? `${hauteurEnPx}px` : "100vh";
      this.dataGrid.instance.element().style.height = `calc(${baseHeight} - ${heightToReduce}px)`;
    }, 100);
  }

  calculateStockDisponibleSource(rowData) {
    return rowData.stockTheoriqueSource - rowData.quantiteReserveeSource;
  }

  calculateStockDisponibleDestination(rowData) {
    return rowData.stockTheoriqueDestination - rowData.quantiteReserveeDestination;
  }

  clearDataGridFilters() {
    this.dataGrid?.instance?.clearFilter();
    this.dataGrid?.instance?.clearSelection();
    this.dataGrid?.instance?.clearSorting();
  }
  //#endregion

  //#region Stock par site
  showStockParSite = async (article: RechercheDxArticleDto) => {
    this.selectedArticle = article;
    this.lotStockageDatagridRef.setDataSource();
    this.showPopupStockSite = true;
  }

  stockText(e: any) {
    return e.row.data.stockSociete;
  }
  hideStockParSitePopup = async () => {
    this.selectedArticle = null;
  };

  async setArticleEnStock() {
    const ecartColumnIndex = this.dataGrid.instance.columnOption("stockTheoriqueDestination", "index");
    this.dataGrid.instance.columnOption(ecartColumnIndex, "selectedFilterOperation", ">");
    this.dataGrid.instance.columnOption(ecartColumnIndex, "filterValue", 0);

    await this.dataGrid.instance.selectAll();
  }

  resetFiltre() {
    const ecartColumnIndex = this.dataGrid.instance.columnOption("stockTheoriqueDestination", "index");
    this.dataGrid.instance.columnOption(ecartColumnIndex, "selectedFilterOperation", null);
    this.dataGrid.instance.columnOption(ecartColumnIndex, "filterValue", null);
  }

  //#endregion

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}
