import { Component, Input } from '@angular/core';
import { BonDto, FluxEtapeDto, FluxStatut, ObjectType } from '@lexi-clients/lexi';

@Component({
  selector: 'app-bon-detail-entete',
  templateUrl: './bon-detail-entete.component.html',
  styleUrls: ['./bon-detail-entete.component.scss'],
})
export class BonDetailEnteteComponent {
  @Input() isCreation: boolean;
  @Input() canEditDateSouhaite: boolean;
  @Input() bon: BonDto;
  @Input() mouvementTypeIntitule: string;
  @Input() readonly fluxStatutDataSource: Array<{ id: FluxStatut, intitule: string }>
  @Input() isModificationEnCours: boolean;

  fluxEtapeDataSource: FluxEtapeDto[];
  FluxStatut = FluxStatut;
  ObjectType = ObjectType;

  get bonStatut(): string {
    if (!this.bon?.fluxStatut) return "";
    return this.fluxStatutDataSource.find(x => x.id == this.bon.fluxStatut)?.intitule;
  }

}
