<dx-data-grid
  [dataSource]="bonStore"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [filterSyncEnabled]="true"
  (onExporting)="onExporting($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div style="font-size: 20px;">Bons</div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="deletetable" hint="Réinitialiser les filtres" stylingMode="text" (onClick)="clearDatagridFilters()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="refreshDataGrid()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="grille_bons_avec_reference_et_paire" appStorage>
  </dxo-state-storing>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
  <dxo-export [enabled]="true" fileName="liste-des-bons-d-un-stockage"></dxo-export>

  <!-- Colonnes -->
  <dxi-column
    dataField="id"
    caption="N°"
    width="80"
    cellTemplate="idBonCellTemplate"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSearch]="false"
  >
    <div *dxTemplate="let data of 'idBonCellTemplate'">
      <a routerLink="/bon/{{data.value}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="intitule" caption="Intitule" [visible]="true"></dxi-column>
  <dxi-column dataField="fluxStatut" caption="Statut" width="200" [visible]="false">
    <dxo-lookup [dataSource]="fluxStatutDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="dateSouhaite" caption="Date souhaitée" dataType="date" format="dd/MM/yyyy HH:mm"
    [visible]="true" width="155"></dxi-column>
  <dxi-column dataField="dateOperation" caption="Date d'opération" dataType="date" format="dd/MM/yyyy HH:mm"
    [visible]="false" sortOrder="desc" width="155" cellTemplate="dateOperationCellTemplate">
    <div *dxTemplate="let data of 'dateOperationCellTemplate'">
      <span title="Date souhaitée : {{data.data.dateSouhaite | date:'dd/MM/yyyy HH:mm'}}">{{data.value |
        date:'dd/MM/yyyy HH:mm'}}</span>
    </div>
  </dxi-column>
  <dxi-column dataField="mouvementTypeIntitule" caption="Mouvement"></dxi-column>
  <dxi-column  caption="Contient"  cellTemplate="bonContenuCellTemplate"  alignment="center"  width="75"
    [visible]="societeIsFondAutorise" [showInColumnChooser]="societeIsFondAutorise" [allowFiltering]="false"
    [allowHeaderFiltering]="false" [allowSearch]="false"
  >
    <div *dxTemplate="let data of 'bonContenuCellTemplate'" style="display: flex; justify-content: space-between;">
      <div>
        <ng-container *ngIf="data.data.contientFonds">
          <span class="dx-icon-money" title="Fond"></span>
        </ng-container>
      </div>
      <div>
        <ng-container *ngIf="data.data.contientMachandises">
          <span class="dx-icon-box" title="Marchandise"></span>
        </ng-container>
      </div>
    </div>
  </dxi-column>
  <dxi-column dataField="datePremiereEtape" caption="Date de création" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="false"></dxi-column>
  <dxi-column dataField="derniereEtapeIntitule" caption="Étape intitule" [visible]="false"></dxi-column>
  <dxi-column dataField="dateDerniereEtape" caption="Date dernière étape" dataType="date" format="dd/MM/yyyy HH:mm" width="145"></dxi-column>
  <dxi-column dataField="utilisateurDerniereEtapeIntitule" caption="Utilisateur dernière étape" width="167"></dxi-column>
  <dxi-column dataField="version" caption="Version" dataType="date" format="dd/MM/yyyy HH:mm" [visible]="false"></dxi-column>
  <dxi-column dataField="referenceType" caption="Type" [visible]="false"></dxi-column>
  <dxi-column dataField="referenceIntitule" caption="Intitulé" [visible]="false"></dxi-column>
  <dxi-column dataField="referenceUtilisateurIntitule" caption="Utilisateur" [visible]="false" width="165"></dxi-column>

  <!-- Sens -->
  <dxi-column dataField="sens" caption="" cellTemplate="sensCellTemplate" alignment="center" [allowFiltering]="false"
    [allowHeaderFiltering]="false" [allowSearch]="false" [allowSorting]="false" width="56">
    <div *dxTemplate="let data of 'sensCellTemplate'">
      <span *ngIf="data.value == MouvementSens.entree" class="dx-icon-arrowleft" title="Entrée"></span>
      <span *ngIf="data.value == MouvementSens.sortie" class="dx-icon-arrowright" title="Sortie"></span>
      <span *ngIf="data.value == MouvementSens.inventaire" class="dx-icon-tags" title="Inventaire"></span>
    </div>
  </dxi-column>

  <dxi-column caption="Tiers" cellTemplate="sourceTemplate">
    <div *dxTemplate="let cell of 'sourceTemplate'">
      {{currentPartenaireId == cell.data.pairePartenaireId ? cell.data.paireStockageIntitule: cell.data.pairePartenaireIntitule}}
    </div>
  </dxi-column>

  <dxi-column dataField="evenementTypeId" caption="Étape" width="95" cellTemplate="paireEtatCellTemplate" alignment="center">
    <dxo-lookup [dataSource]="evenementTypes" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    <div *dxTemplate="let data of 'paireEtatCellTemplate'">
      <img [src]="getEtapeLogo(data.value)" width="24" height="24" [title]="data.data.evenementIntitule ?? 'Aucune'" />
    </div>
  </dxi-column>

  <!-- Marchandises (mouvementées/total) -->
  <dxi-column caption="Marchandises" cellTemplate="marchandisesTemplate" alignment="center" headerCellTemplate="marchandisesHeaderTemplate">
    <div *dxTemplate="let data of 'marchandisesHeaderTemplate'">
      <span style="margin-right: 2px;">Marchandises</span>
      <i id="marchandisesLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showMarchandisesLegende = true" (mouseleave)="showMarchandisesLegende = false"></i>
    </div>
    <div *dxTemplate="let cell of 'marchandisesTemplate'">
      <div class="quantites-marchandises">{{cell.data.nombreMarchandisesEncours?.toLocaleString() ?? 0}}</div> <div class="slash-quantites">/</div> <div class="quantites-marchandises">{{cell.data.nombreMarchandisesMouvementees?.toLocaleString() ?? " "}}</div> <div class="slash-quantites">/</div> <div class="quantites-marchandises">{{cell.data.nombreMarchandises?.toLocaleString()}}</div>
    </div>
  </dxi-column>
  <!-- Références (mouvementé/total) -->
  <dxi-column caption="Réf. mouvementées" cellTemplate="referencesTemplate" headerCellTemplate="referencesHeaderTemplate" alignment="center" width="120">
    <div *dxTemplate="let data of 'referencesHeaderTemplate'">
      <span style="margin-right: 2px;">Références</span>
      <i id="referencesLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showreferencesLegende = true" (mouseleave)="showreferencesLegende = false"></i>
    </div>
    <div *dxTemplate="let cell of 'referencesTemplate'">
      <div class="quantites-references">{{cell.data.nombreReferencesMouvementees?.toLocaleString()}}</div> <div class="slash-quantites">/</div> <div class="quantites-references">{{cell.data.nombreReferences?.toLocaleString()}}</div>
    </div>
  </dxi-column>

  <dxi-column dataField="PartenaireSourceId" caption="Partenaire source id" [visible]="false"></dxi-column>
  <dxi-column dataField="paireUtilisateurIntitule" caption="Utilisateur" [visible]="false"></dxi-column>
  <dxi-column dataField="paireType" caption="Type" [visible]="false" [customizeText]="customizeText"></dxi-column>
</dx-data-grid>

<!-- Popover: Légende Marchandises -->
<dx-popover target="#marchandisesLegendeIcon" [width]="230" [(visible)]="showMarchandisesLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    En cours / Mouvementé / Total
  </div>
</dx-popover>

<!-- Popover: Légende References -->
<dx-popover target="#referencesLegendeIcon" [width]="160" [(visible)]="showreferencesLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Mouvementé / Total
  </div>
</dx-popover>