<div style="padding: 12px;">
  <h2>Gestion des classifications</h2>

  <dx-tab-panel [dataSource]="tabs" [selectedIndex]="0" [deferRendering]="false">

    <div *dxTemplate="let item of 'tabTemplateClassification'">
      Classifications
    </div>
    <div *dxTemplate="let item of 'templateClassification'">
      <app-classification-values-list></app-classification-values-list>
    </div>

    <div *dxTemplate="let item of 'tabTemplateClassificationType'">
      Types de classifications
    </div>
    <div *dxTemplate="let item of 'templateClassificationType'">
      <app-classification-type-list [objectTypeKey]="objectTypeKey"></app-classification-type-list>
    </div>

  </dx-tab-panel>
</div>
