import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Permissions } from '@lexi-clients/lexi';
import { OidcUaaConfig, QUERY_KEY_RETURN_URL } from '@lexi/oidc-uaa';
import { AuthService } from '../settings/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanAccessModuleAdministrationGuard  {

  constructor(
    private authService: AuthService,
    private router: Router,
    private config: OidcUaaConfig
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      // Si l'utilisateur possède une permission nécessitant le menu administration
      // canActivate OK
      if (
        this.authService.securityUser?.isRoot
        || this.authService.securityUserisGrantedWith(Permissions.canManageUsers)
        || this.authService.securityUserisGrantedWith(Permissions.canManageRoles)
        || this.authService.securityUserisGrantedWith(Permissions.canAssignRoles)
        // Add permission nécessitant le menu administration
      ) {
        return true;
      }

      this.router.navigate([this.config.openIDConfiguration.forbiddenRoute], { queryParams: { [QUERY_KEY_RETURN_URL]: state.url }});
      return false;
  }
}
