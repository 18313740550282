import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ArticleForUpdateDto, ClassificationValueDto, CreateOrUpdateOperationCommercialeDetailDto, ObjectType, OperationCommercialeDetailDto,
  MethodeDeCalculDeRemise, OperationsCommercialesDetailsLexiClient, PartenaireDto, ImportOperationCommercialeDetailResult } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ArticleListService } from 'lexi-angular/src/app/services/article.service';
import { ClassificationTypeService } from 'lexi-angular/src/app/services/classification-type.service';
import { ClassificationValueService } from 'lexi-angular/src/app/services/classification-value.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { DownloadService } from 'lexi-angular/src/app/services/download.service';
import { OperationCommercialeDetailListService } from 'lexi-angular/src/app/services/operation-commerciale-detail.service';
import { PartenaireListService } from 'lexi-angular/src/app/services/partenaire.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { PopupImportFichierExcelComponent } from '../popup-import-fichier-excel/popup-import-fichier-excel.component';

@Component({
  selector: 'app-operation-commerciale-detail-list',
  templateUrl: './operation-commerciale-detail-list.component.html',
  styleUrls: ['./operation-commerciale-detail-list.component.scss'],
})
export class OperationCommercialeDetailListComponent implements OnDestroy {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild(PopupImportFichierExcelComponent) popupImportRef: PopupImportFichierExcelComponent;
  private subscriptions = new Subscription();
  private _articleId: number;
  get articleId(): number {
    return this._articleId;
  }
  @Input() set articleId(value: number) {
    if (value) {
      this._articleId = value;
      this.loadPageData();
    }
  }

  private _clientId: number;
  get clientId(): number {
    return this._clientId;
  }
  @Input() set clientId(value: number) {
    if (value) {
      this._clientId = value;
      this.loadPageData();
    }
  }

  private _operationCommercialeId: number;
  get operationCommercialeId(): number {
    return this._operationCommercialeId;
  }
  @Input() set operationCommercialeId(value: number) {
    if (value) {
      this._operationCommercialeId = value;
      this.loadPageData();
    }
  }

  @Input() dataGridHeight: number;
  readonly methodeDataSource = [
    { id: MethodeDeCalculDeRemise.coefficient, intitule: "Coefficient" },
    { id: MethodeDeCalculDeRemise.majoration, intitule: "Majoration" },
    { id: MethodeDeCalculDeRemise.minoration, intitule: "Minoration" },
    { id: MethodeDeCalculDeRemise.montant, intitule: "Montant" },
    { id: MethodeDeCalculDeRemise.pourcentage, intitule: "Pourcentage" },
  ];
  dataSource: CustomStore;
  articleDataSource: DataSource;
  partenaireDataSource: DataSource;
  classificationTypeArticleDataSource: DataSource;
  classificationTypeClientDataSource: DataSource;
  classificationArticleDataSource: DataSource;
  classificationClientDataSource: DataSource;
  classificationTypeArticleId: number;
  classificationTypeClientId: number;
  currentSociete: number;
  showImportPopup: boolean = false;
  showLoaderPopup: boolean = false;
  retoursImport = [];

  constructor(
    private readonly operationCommercialeDetailListService: OperationCommercialeDetailListService,
    private readonly articleService: ArticleListService,
    private readonly partenaireService: PartenaireListService,
    private readonly classificationTypeListService: ClassificationTypeService,
    private readonly classificationValueService: ClassificationValueService,
    private readonly operationsCommercialesDetailsLexiClient: OperationsCommercialesDetailsLexiClient,
    private readonly downloadService: DownloadService,
    authService: AuthService,
    private readonly dxDataSourceService: DxDataSourceService
  ) {
    this.subscriptions.add(
      authService.currentSocieteId$.subscribe(x => this.currentSociete = x)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadPageData() {
    this.setDataSource();
    this.setSecondaryDataSources();
  }

  setDataSource() {
    const additionalParams = new Map();
    if (this.operationCommercialeId) additionalParams.set("operationCommercialeId", this.operationCommercialeId);
    if (this.articleId) additionalParams.set("articleId", this.articleId);
    if (this.clientId) additionalParams.set("clientId", this.clientId);
    this.dataSource = new CustomStoreService(this.operationCommercialeDetailListService).getCustomStore(additionalParams);
  }

  setSecondaryDataSources() {
    const additionalParams = new Map().set("avecVariante", true);
    this.articleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.articleService, additionalParams);
    this.partenaireDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.partenaireService);
    const paramsArticle = new Map().set("objectType", ObjectType.article);
    this.classificationTypeArticleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationTypeListService, paramsArticle);
    const paramsClient = new Map().set("objectType", ObjectType.partenaire);
    this.classificationTypeClientDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationTypeListService, paramsClient);
    this.classificationArticleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationValueService);
    this.classificationClientDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationValueService);
  }

  articleDisplayExpr(article: ArticleForUpdateDto) {
    return article && `${article.codeBo} - ${article.libelleLong}`;
  }

  clientDisplayExpr(client: PartenaireDto) {
    return client && `${client.codeBo} - ${client.intitule}`;
  }

  classificationValueDisplayExpr(cv: ClassificationValueDto) {
    return cv && `${cv.codeBo} - ${cv.intitule}`;
  }

  onClassificationTypeClientChanged = (e: { value: number }) => {
    if (!this.classificationTypeClientId && e.value) this.classificationTypeClientId = e.value;
    const params = e.value ? new Map().set("classificationTypeId", e.value) : null;
    this.classificationClientDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationValueService, params);
  }

  onClassificationTypeArticleChanged = (e: { value: number }) => {
    if (!this.classificationTypeClientId && e.value) this.classificationTypeArticleId = e.value;
    const params = e.value ? new Map().set("classificationTypeId", e.value) : null;
    this.classificationArticleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.classificationValueService, params);
  }

  onEditingStart(e: {data: OperationCommercialeDetailDto}) {
    this.classificationTypeClientId = e.data.classificationClientTypeId;
    this.classificationTypeArticleId = e.data.classificationArticleTypeId;
  }

  onEditCanceled() {
    this.classificationTypeClientId = null;
    this.classificationTypeArticleId = null;
  }

  onReorder = async (e: any) => {
    const rows = e.component.getVisibleRows();
    const fromData: OperationCommercialeDetailDto = e.itemData;
    const toData: OperationCommercialeDetailDto = rows[e.toIndex].data;

    const fromDataDto = this.getDtoWithChangedIndex(fromData, toData.zIndex);
    const toDataDto = this.getDtoWithChangedIndex(toData, fromData.zIndex);

    await lastValueFrom(this.operationsCommercialesDetailsLexiClient.update(fromData.id, fromDataDto));
    await lastValueFrom(this.operationsCommercialesDetailsLexiClient.update(toData.id, toDataDto));
    this.setDataSource();
  }

  private getDtoWithChangedIndex(dto: OperationCommercialeDetailDto, newZIndex: number): CreateOrUpdateOperationCommercialeDetailDto {
    return {
      id: dto.id,
      codeBo: dto.codeBo,
      societeId: this.currentSociete,
      operationCommercialeId: dto.operationCommercialeId,
      classificationArticleId: dto.classificationArticleId,
      articleId: dto.articleId,
      classificationClientId: dto.classificationClientId,
      clientId: dto.clientId,
      zIndex: newZIndex,
      methode: dto.methode,
      value: dto.value,
    };
  }

  async onKeyDown(e) {
    if (!this.clientId && !this.articleId && e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }

  getModeleExcel() {
    this.operationsCommercialesDetailsLexiClient.getModeleExcel('response')
    .subscribe(response => this.downloadService.directDownloadFile(response, '.xlsx'));
  }

  async onCloseImportPopup(file: File) {
    if (file == null) {
      this.showImportPopup = false;
      this.retoursImport = [];
      return;
    }

    try {
      this.showLoaderPopup = true;
      const result: ImportOperationCommercialeDetailResult = await lastValueFrom(this.operationsCommercialesDetailsLexiClient.importerDonnees(this.operationCommercialeId, file));
      this.showLoaderPopup = false;
      const r = [];
      if (result.nombreLigneAjoutees && result.nombreLigneAjoutees != "") r.push(result.nombreLigneAjoutees);
      if (result.nombreLigneModifiee && result.nombreLigneModifiee != "") r.push(result.nombreLigneModifiee);
      if (result.erreurs && result.erreurs.length > 0) r.push(...result.erreurs);
      this.retoursImport = r;
      this.showImportPopup = result.erreurs.length > 0;
    }
    finally {
      this.showLoaderPopup = false;
      if (this.popupImportRef) this.popupImportRef.files = [];
      this.setDataSource();
    }
  }
}
