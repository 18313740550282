import { Component, Input } from '@angular/core';
import { MotifRefusDemande, PartenaireFretLocalSettings, PartenaireFretLocalSettingsStatutRevatua, StatutIntegrationAutomatiqueDemandeChargeur, TypeUtilisateurRuunui } from '@lexi-clients/lexi';
@Component({
  selector: 'app-partenaire-fret-local-settings-form',
  templateUrl: './partenaire-fret-local-settings-form.component.html',
  styleUrls: ['./partenaire-fret-local-settings-form.component.scss'],
})
export class PartenaireFretLocalSettingsFormComponent {
  @Input() colCount: number = 1;
  @Input() colSpan: number = 1;
  @Input() formIsReadOnly: boolean = false;
  @Input() fretLocalSettings: PartenaireFretLocalSettings = {};
  @Input() motifRefusDemande: MotifRefusDemande[];
  @Input() typeUtilisateur: TypeUtilisateurRuunui;
  TypeUtilisateurRuunui = TypeUtilisateurRuunui;
  StatutIntegrationAutomatiqueDemandeChargeur = StatutIntegrationAutomatiqueDemandeChargeur;
  PartenaireFretLocalSettingsStatutRevatua = PartenaireFretLocalSettingsStatutRevatua;

  readonly statutPrincipalOptions = [
    { id: StatutIntegrationAutomatiqueDemandeChargeur.desactiver, intitule: "Intégration auto. désactivée" },
    { id: StatutIntegrationAutomatiqueDemandeChargeur.parametreVoyage, intitule: "Par défaut" },
    { id: StatutIntegrationAutomatiqueDemandeChargeur.personnaliser, intitule: "Personnaliser" },
    { id: StatutIntegrationAutomatiqueDemandeChargeur.toujoursOfficialiser, intitule: "Toujours officialiser" },
    { id: StatutIntegrationAutomatiqueDemandeChargeur.toujoursOfficialiserSousReserve, intitule: "Toujours officialiser sous réserve" },
    { id: StatutIntegrationAutomatiqueDemandeChargeur.toujoursRefuser, intitule: "Toujours refuser" },
  ];

  readonly statutsRevatuaAcceptationParDefaut = [
    { id: null, intitule: "Par défaut" },
    { id: PartenaireFretLocalSettingsStatutRevatua.manuel, intitule: "Manuel" },
    { id: PartenaireFretLocalSettingsStatutRevatua.officialise, intitule: "Officialisé" },
    { id: PartenaireFretLocalSettingsStatutRevatua.officialiseSousReserve, intitule: "Officialisé sous réserve" },
  ];

  readonly statutsRevatuaClientBloque = [
    { id: null, intitule: "Par défaut" },
    { id: PartenaireFretLocalSettingsStatutRevatua.manuel, intitule: "Manuel" },
    { id: PartenaireFretLocalSettingsStatutRevatua.officialiseSousReserve, intitule: "Officialisé sous réserve" },
    { id: PartenaireFretLocalSettingsStatutRevatua.demandeRefusee, intitule: "Demande refusée" },
  ];

  getDescriptionPersonnaliser() {
    const statutParDefaut = this.fretLocalSettings?.statutAcceptationParDefaut;
    const statutBloque = this.fretLocalSettings?.statutSiBloque;

    const statutParDefautString = this.getIntituleStatut(statutParDefaut);
    const statutBloqueString = this.getIntituleStatut(statutBloque);

    // Si c'est null, c'est intégré selon les paramètres du voyage
    // Si c'est manuel, ça devra être intégré manuellement
    // Sinon, ça sera intégré au statut X
    const parDefautDescription = statutParDefaut == null ? "Si ce partenaire n'est pas bloqué, ces demandes seront intégrées dans REVATUA selon le <b>paramétrage du voyage.</b>"
      : statutParDefaut == PartenaireFretLocalSettingsStatutRevatua.manuel ? "Si ce partenaire n'est pas bloqué, ces demandes devront être intégrées dans REVATUA <b>manuellement</b>."
      : `Si ce partenaire n'est pas bloqué, ces demandes seront intégrées dans REVATUA au statut '<b>${statutParDefautString}</b>'.`;

    const bloqueDescription = statutBloque == null ? "Si ce partenaire est bloqué, ces demandes seront intégrées dans REVATUA selon le <b>paramétrage du voyage</b>."
      : statutBloque == PartenaireFretLocalSettingsStatutRevatua.manuel ? "Si ce partenaire est bloqué, ces demandes devront être intégrées dans REVATUA <b>manuellement</b>."
      : `Si ce partenaire est bloqué, ces demandes seront intégrées dans REVATUA au statut '<b>${statutBloqueString}</b>'.`;

    return `${parDefautDescription}<br />${bloqueDescription}`;
  }

  private getIntituleStatut(statut: PartenaireFretLocalSettingsStatutRevatua) {
    switch (statut) {
      case PartenaireFretLocalSettingsStatutRevatua.officialise:
        return "Officialisé";
      case PartenaireFretLocalSettingsStatutRevatua.officialiseSousReserve:
        return "Officialisé sous réserve";
      case PartenaireFretLocalSettingsStatutRevatua.demandeRefusee:
        return "Demande refusée";
      default:
        return "";
    }
  }
}
