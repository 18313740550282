import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LotStockageMarchandiseDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { LotStockageMarchandiseListResult } from '../models/lot-stockage-marchandise-list';

@Injectable({ providedIn: 'root' })
export class LotStockageMarchandiseListService implements IGridService<LotStockageMarchandiseDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<LotStockageMarchandiseListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/LotStockages/recherche-devexpress/marchandise`, { params: params });
  }

  getById(lotStockageId: number): Observable<LotStockageMarchandiseDto> {
    return this.http.get<any>(`${this.baseUrl}/api/LotStockages/${lotStockageId}/marchandise`);
  }

  post(valeur: LotStockageMarchandiseDto): Observable<any> {
    return null;
  }

  put(valeur: LotStockageMarchandiseDto): Observable<any> {
    return null;
  }

  delete(valeur: LotStockageMarchandiseDto): Observable<any> {
    return null;
  }
}
