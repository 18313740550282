import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RechercheDxArticleDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { ArticleListResult } from '../models/article-list';
import { IGridService } from '../models/igrid-service';

@Injectable({ providedIn: 'root' })
export class ArticleListService implements IGridService<RechercheDxArticleDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<ArticleListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Articles/recherche-devexpress`, { params: params });
  }

  getById(articleId: number): Observable<RechercheDxArticleDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Articles/recherche-devexpress/${articleId}`);
  }

  post(valeur: RechercheDxArticleDto): Observable<any> {
    return null;
  }

  put(valeur: RechercheDxArticleDto): Observable<any> {
    return null;
  }

  delete(valeur: RechercheDxArticleDto): Observable<any> {
    return null;
  }
}
