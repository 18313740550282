import { Component, OnInit } from '@angular/core';
import { TransporteurDto, TransporteurForUpdateDto, TransporteursLexiClient, TypeTransport, TypeLiaison } from '@lexi-clients/lexi';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../../modules/shared/references/references';
import { PartenaireListService } from '../../services/partenaire.service';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-transporteur-list',
  templateUrl: './transporteur-list.component.html',
  styleUrls: ['./transporteur-list.component.scss'],
})
export class TransporteurListComponent implements OnInit {
  transporteurs: TransporteurDto[] = [];
  partenaireDataSource: DataSource;

  constructor(
    private readonly transporteursLexiClient: TransporteursLexiClient,
    private readonly partenaireService: PartenaireListService
  ) {}

  async ngOnInit() {
    this.initPartenaireDataSource();
    await this.setTransporteurs();
  }

  async setTransporteurs() {
    const filters = {
      includeDeleted: false,
      filterByCurrentSociete: true,
      type: TypeTransport.maritime,
      typeLiaison: TypeLiaison.locale
    };
    this.transporteurs = await lastValueFrom(this.transporteursLexiClient.getByFilters(
      filters.includeDeleted,
      filters.filterByCurrentSociete,
      filters.type,
      filters.typeLiaison
    ));
  }

  async updateTransporteur(e: { data: TransporteurDto }) {
    const t: TransporteurForUpdateDto = {
      id: e.data.id,
      codeInternational: e.data.codeInternational,
      partenaireId: e.data.partenaireId
    };
    await lastValueFrom(this.transporteursLexiClient.update(t));
    await this.setTransporteurs();
    notify({closeOnClick: true, message: "Transporteur mis à jour avec succès"}, NotificationType.Success);
  }

  private initPartenaireDataSource() {
    // Nécessaire car le composant SelectBox freeze si on ne pagine pas
    this.partenaireDataSource = new DataSource({
      key: 'id',
      paginate: true,
      pageSize: 10,
      store: new CustomStoreService(this.partenaireService).getSelectBoxCustomStore()
    });
  }
}
