import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Permissions } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { LotStockageMarchandiseListService } from 'lexi-angular/src/app/services/lot-stockage-marchandise.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { LotStockageADateService } from "../../../services/lot-stockage-a-date.service";

export class ConfigFiltreLot {
  isByPartenaire: boolean;
}

@Component({
  selector: 'app-lot-stockage-datagrid',
  templateUrl: './lot-stockage-datagrid.component.html',
  styleUrls: ['./lot-stockage-datagrid.component.scss'],
})
export class LotStockageDatagridComponent implements OnInit {
  //#region Variables
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    if (value != null) {
      this.cd.detectChanges();
    }
  }

  private _configFiltre: ConfigFiltreLot;
  get configFiltre(): ConfigFiltreLot { return this._configFiltre; }
  @Input() set configFiltre(value: ConfigFiltreLot) {
    this._configFiltre = value;
    if (value != null) {
      this.loadDataIfNeeded();
    }
  }

  private _isInFicheArticle: boolean = false;
  get isInFicheArticle(): boolean { return this._isInFicheArticle; }
  @Input() set isInFicheArticle(value: boolean) {
    this._isInFicheArticle = value;
    if (value != null) {
      this.loadDataIfNeeded();
    }
  }

  private _currentSiteId: number;
  get currentSiteId(): number { return this._currentSiteId; }
  @Input() set currentSiteId(value: number) {
    this._currentSiteId = value;
    if (value != null) {
      this.loadDataIfNeeded();
    }
  }

  private _dateStock: Date;
  get dateStock(): Date { return this._dateStock; }
  @Input() set dateStock (value : Date) {
   this._dateStock = value;
   if (value != null) {
     this.loadDataIfNeeded();
   }
  }

  private _articleId: number;
  get articleId(): number { return this._articleId; }
  @Input() set articleId(value: number) {
    this._articleId = value;
    if (value != null) {
      this.dataGridStorageKey = "grille_lot_stockage_fiche_article";
      this.loadDataIfNeeded();
    }
  }

  private _prixId: number;
  get prixId(): number { return this._prixId; }
  @Input() set prixId(value: number) {
    this._prixId = value;
    if (value != null) {
      this.loadDataIfNeeded();
    }
  }

  @Input() canAfficherPMP: boolean = false;
  @Input() dataGridMarginTop: string;

  private initialized: boolean = false;
  isAffichageStockAutorise: boolean = false;
  lotStockageStore: CustomStore;
  dataGridStorageKey = "grille_lot_stockage_globale";
  //#endregion

  constructor(
    private readonly lotStockageMarchandiseListService: LotStockageMarchandiseListService,
    private readonly lotStockageADateService: LotStockageADateService,
    private readonly authService: AuthService,
    private readonly cd: ChangeDetectorRef,
    private readonly exportDataGridService: ExportDataGridService
  ) { }

  ngOnInit(): void {
    this.setDataSource();
  }

  private loadDataIfNeeded() {
    if (this.initialized) {
      this.setDataSource();
    }
  }

  setDataSource() {
    this.isAffichageStockAutorise = this.authService.securityUserisGrantedWith(Permissions.accesVueConsultationStock);
    if (!this.isAffichageStockAutorise) {
      this.initialized = true;
      return;
    }

    try {
      const additionalParams = new Map().set("siteId", this.currentSiteId);
      if (this.articleId != null) {
        additionalParams.set("articleId", this.articleId);
      }
      if (this.prixId != null && !this.dateStock) {
        additionalParams.set("prixId", this.prixId);
      }
      if (this.isInFicheArticle) {
        additionalParams.set("isByPartenaire", this.configFiltre?.isByPartenaire ?? false);
      }
      if (this.dateStock) {
        additionalParams.set("dateStock", this.dateStock.toLocaleDateString())
      }

      const listService = this.dateStock ? this.lotStockageADateService : this.lotStockageMarchandiseListService;
      this.lotStockageStore = new CustomStoreService(listService).getCustomStore(additionalParams);
    }
    finally {
      this.initialized = true;
    }
  }

  setDataSourceByPartenaire() {
    if (this.configFiltre == null) return;
    this._configFiltre.isByPartenaire = true;
    sessionStorage.setItem("grille_lot_stockage_fiche_article_by_partenaire", String(true));
    this.setDataSource();
  }

  setDataSourceBySociete() {
    if (this.configFiltre == null) return;
    this._configFiltre.isByPartenaire = false;
    sessionStorage.setItem("grille_lot_stockage_fiche_article_by_partenaire", String(false));
    this.setDataSource();
  }

  calculateQuantitePrixVente(rowData) {
    return rowData.quantite * rowData.prixVente;
  }

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}
