export class Site {
  public id: number;
  public intitule: string;
  public did: number;
  public societeId: number;
  public partenaireId: number;

  constructor(init?: Partial<Site>) {
    Object.assign(this, init);
  }
}