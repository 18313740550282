import { DecimalPipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-cell-ecart',
  templateUrl: './cell-ecart.component.html',
  styleUrls: ['./cell-ecart.component.scss']
})
export class CellEcartComponent {
  signedEcart: string;
  _ecart: number;

  constructor(private readonly numberPipe: DecimalPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isFormatMontant']) {
      this.updateSignedEcart(this._ecart);
    }
  }

  @Input() isFormatMontant = false;

  @Input() set ecart(value: number) {
    this._ecart = value;
    this.updateSignedEcart(this._ecart);
  }

  private updateSignedEcart(value: number) {
    this.signedEcart = value < 0 ? `${value}` : value > 0 ? `+${value}` : '';

    // Applique le format montant ex: "1 750"
    if (this.isFormatMontant && this.numberPipe) {
      this.signedEcart = (value == null || value == 0) ? '' : this.numberPipe.transform(value, '1.0-0');
    }
  }
}
