import { Component } from '@angular/core';
import { CheckLicenceDto, LicenceStatut, ObjectType, Permissions, RoleUtilisationDto, TypeUtilisateur, UserDto } from '@lexi-clients/lexi';
import notify from 'devextreme/ui/notify';
import { Site } from 'lexi-angular/src/app/domain/site';
import { NavigationCollaborateurMenuId } from 'lexi-angular/src/app/models/navigation-top';
import { NotificationType } from 'lexi-angular/src/app/modules/shared/references/references';
import { lastValueFrom } from 'rxjs';
import { HeaderBaseComponent } from './header-base.component';
import { Crisp } from 'crisp-sdk-web';
import { ThemeSwitcherModule } from '../theme-switcher/theme-switcher.component';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent extends HeaderBaseComponent {
  switchSocietePopupVisible = false;
  showErreurPopup = false;
  showPopupContactsEnAttentes = false;
  showBandeauAlerteLicence = false;
  licenceARenouveler: CheckLicenceDto = null;
  licenceEnPeriodeDeGrace: CheckLicenceDto = null;
  nombreErreursInQueue : number = 0;
  erreurConnectionBackOffice = false;
  contactsEnAttentes : RoleUtilisationDto[] = [];
  selectedContact : RoleUtilisationDto = {};
  // TODO : A activer lorsqu'on voudra passer à la création de plusieurs société
  // en attendant, on peut toujours taper manuellement l'url /creation-societe
  multiSocieteActif = false;

  get sites(): Site[] {
    return this.authService.sites.sort((a, b) => a.intitule.localeCompare(b.intitule));
  }

  selectedSite: Site;

  userMenuItems: any = [
    {
      text: 'Profil',
      icon: 'user',
      onClick: () => {
        this.router.navigate(['/profil']);
      }
    },
    {
      text: 'Préférences',
      icon: 'preferences',
      onClick: () => {
        this.router.navigate(['/settings']);
      }
    },
    {
      text: 'Changer de société',
      icon: 'movetofolder',
      onClick: () => {
        this.switchSocietePopupVisible = true;
      }
    },
    {
      text: 'Support',
      icon: 'help',
      onClick: () => {this.openSupport()}
    },
    {
      text: 'Déconnecter',
      icon: 'runner',
      onClick: () => {
        this.authService.logout();
      }
    }
  ];

  administrationMenuItem = {
    text: 'Administration',
    icon: 'activefolder',
    onClick: () => {
      this.moduleService.selectModule = NavigationCollaborateurMenuId.administration
    }
  };

  showAsUserSelectBoxMenuItem = {
    text: 'Voir en tant que',
    icon: 'user',
    onClick: () => {
      this.router.navigate(['/voir-en-tant-que']);
    }
  };

  versionMenuItem = {
    text: 'v.develop',
    disabled: true,
    beginGroup: true
  };


  async ngOnInit() {
    // On ajoute l'élément pour gérer l'affichage de la selectBox du asUser
    if (this.authService.authenticatedUser?.isRoot) {
      const index = this.userMenuItems.length - 1;
      this.userMenuItems.splice(index, 0, this.showAsUserSelectBoxMenuItem);
    }

    // Ajout du menu administration
    const canAccesAdministrationMenu = this.canAccesAdministrationMenu();
    if(canAccesAdministrationMenu) {
      const index = this.userMenuItems.length - 1;
      this.userMenuItems.splice(index, 0, this.administrationMenuItem);
      await this.checkSiErreurQueue();
      await this.checkSiErreurConnection();
      await this.checkLicencesActives();
    }
    await this.getContactsEnAttentes();

    // Ajout du numéro de version
    this.versionMenuItem.text = `v${this.appInfo.lexiVersionAPI }`;
    this.userMenuItems.push(this.versionMenuItem);
  }

  openSupport() {
    if (this.appInitService.isCrispEnabled) {
      if(Crisp.chat.isVisible()) {
        Crisp.chat.hide();
      } else {
        Crisp.chat.show();
        Crisp.chat.open();
      }
    }
  }

  async onValidate(e){
    this.selectedContact = Object.assign({}, this.contactsEnAttentes.find(contact =>
      contact.objectId == e.data.objectId &&
      contact.objectType == e.data.objectType &&
      contact.utilisateurId == e.data.utilisateurId &&
      contact.roleId == e.data.roleId &&
      contact.valide == e.data.valide));
    this.userRelationService.valider(this.selectedContact).subscribe(async result => {
      this.contactsEnAttentes.splice(this.contactsEnAttentes.indexOf(this.selectedContact) - 1, 1);
      notify({closeOnClick: true, message: "Demande de contact validée."}, NotificationType.Success);
    });
  }

  async onDelete(e){
    await lastValueFrom(this.userRelationService.supprimer(
      e.data.objectType,e.data.objectId, e.data.utilisateurId, e.data.roleId));
    this.contactsEnAttentes.splice(this.contactsEnAttentes.indexOf(this.selectedContact) - 1, 1);
    notify({closeOnClick: true, message: "Demande de contact supprimée."}, NotificationType.Success);
  }

  async getContactsEnAttentes(){
    const contactsEnAttentes = await lastValueFrom(this.userRelationService.getEnAttentesPourUtilisateur(ObjectType.partenaire, this.user.id));
    if (contactsEnAttentes.length > 0){
      this.contactsEnAttentes = contactsEnAttentes;
    }
  }

  async checkSiErreurQueue(){
    this.nombreErreursInQueue = await lastValueFrom(this.messagingClient.getNbMessagesInQueueARelancer());
  }

  async checkSiErreurConnection(){
    this.erreurConnectionBackOffice = !(await lastValueFrom(this.syncDataService.checkConnectionBackOffice()));
  }

  async checkLicencesActives() {
    try {
      const licenceActiveStatuts = await lastValueFrom(this.licencesClient.checkStatutLicencesActives());
      this.licenceARenouveler = licenceActiveStatuts.find(l => l.licenceStatut == LicenceStatut.renouvellementAPrevoir) ?? null;
      this.licenceEnPeriodeDeGrace = licenceActiveStatuts.find(l => l.licenceStatut == LicenceStatut.interventionRequise) ?? null;
      this.showBandeauAlerteLicence = this.licenceARenouveler != null || this.licenceEnPeriodeDeGrace != null;
    }

    catch (error) {
      console.error("Une erreur est survenue lors de la récupération des licences actives.");
      this.licenceARenouveler = null;
      this.licenceEnPeriodeDeGrace = null;
    }
  }

  togglePopup(){
    this.showErreurPopup = !this.showErreurPopup;
  }

  togglePopupContactsEnAttentes(){
    this.showPopupContactsEnAttentes = !this.showPopupContactsEnAttentes;
  }

  changerSociete() {
    this.switchSocietePopupVisible = false;
    if (this.societeIdSelectBox.value !== this.currentSociete?.id) {
      this.authService.setSocieteId(this.societeIdSelectBox.value);
    }
  }

  changerSite(selectedItem) {
    if (selectedItem.value !== this.currentSite?.id) {
      this.authService.setSiteId(selectedItem.value);
    }
  }

  redirectionVersMessage = () => {
    this.router.navigate(['/administration/message']);
    this.togglePopup();
  }

  canAccesAdministrationMenu = () => {
    return this.authService.securityUser?.isRoot
      || this.authService.securityUserisGrantedWith(Permissions.canManageUsers)
      || this.authService.securityUserisGrantedWith(Permissions.canManageRoles)
      || this.authService.securityUserisGrantedWith(Permissions.canAssignRoles);
      // Add permission nécessitant le menu admin
  }
}