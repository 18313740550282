import { Component, Input } from "@angular/core";
import { ArticleForUpdateDto, ArticlesLexiClient, TarifDto, TypeTaxe } from "@lexi-clients/lexi";
import { lastValueFrom } from "rxjs";
import { formatNumber } from "devextreme/localization";

@Component({
  selector: "app-tarif-article-simplifie",
  templateUrl: "./tarif-article-simplifie.component.html",
  styleUrls: ["./tarif-article-simplifie.component.scss"],
})
export class TarifArticleSimplifieComponent {
  protected readonly TypeTaxe = TypeTaxe;
  protected readonly formatNumber = formatNumber;
  tarifs: TarifDto[];
  isModeAvance: boolean = false;
  isTarifsDuParent: boolean = false;

  private _article: ArticleForUpdateDto;
  get article(): ArticleForUpdateDto { return this._article; }
  @Input() set article(value: ArticleForUpdateDto) {
    this._article = value;
    if (value != null) {
      this.setTarifs();
    }
  }
  showPopover: boolean = false;

  constructor(private readonly articlesLexiClient: ArticlesLexiClient) {}

  async setTarifs() {
    this.tarifs = await lastValueFrom(this.articlesLexiClient.getTarifsByArticleId(this.article.id));

    // Si c'est une variante sans tarifs, on récupère le tarif du parent
    if ((this.tarifs == null || this.tarifs.length == 0) && this.article.articleParentId != null) {
      this.tarifs = await lastValueFrom(this.articlesLexiClient.getTarifsByArticleId(this.article.articleParentId));
      this.isTarifsDuParent = this.tarifs != null && this.tarifs.length > 0;
    }
    else {
      this.isTarifsDuParent = false;
    }
  }

  showModeAvance() {
    this.isModeAvance = !this.isTarifsDuParent;
  }

  hideModeAvance() {
    this.isModeAvance = false;
  }
}
