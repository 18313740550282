import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { MagasinPieceNatureDto } from '@lexi-clients/lexi';
import { MagasinPieceNatureListResult } from '../models/magasin-piece-nature-list';

@Injectable({
  providedIn: 'root'
})
export class MagasinPieceNatureListService implements IGridService<MagasinPieceNatureDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<MagasinPieceNatureListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/MagasinPieceNatures/recherche-devexpress`, { params: params });
  }

  getById(id: number): Observable<any> {
    return null;
  }

  post(valeur: MagasinPieceNatureDto): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/MagasinPieceNatures`, valeur);
  }

  put(valeur: MagasinPieceNatureDto): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/MagasinPieceNatures`, valeur);
  }

  delete(valeur: MagasinPieceNatureDto): Observable<any> {
    return null;
  }
}
