import { Injectable } from "@angular/core";
import { CodeBarreValidite, ComptageStatut, MouvementSens, ObjectType, TypeUtilisateur, UtilisateursLexiClient } from "@lexi-clients/lexi";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { ComptageType } from 'lexi-angular/src/app/models/enums/comptage-type';
import { lastValueFrom } from "rxjs";
import { v4 as uuidV4 } from 'uuid';
import { InternationalisationPipe } from "../pipes/internationalisation.pipe";

@Injectable()
export class ReferenceService {

  constructor(
    private readonly internationalisation: InternationalisationPipe,
    private readonly utilisateurClient: UtilisateursLexiClient
  ) {}

  getLibelleStatutComptageInventaire(statut: ComptageStatut) {
    return this.getStatutsComptageInventaire().find(o => o.id == statut)?.libelle || 'Statut inconnu';
  }

  getStatutsComptageInventaire() {
    return [
      { id: ComptageStatut.enAttente, libelle: 'En attente'},
      { id: ComptageStatut.enCours, libelle: 'En cours'},
      { id: ComptageStatut.transmis, libelle: 'Verrouillé'},
      { id: ComptageStatut.annule, libelle: 'Annulé'},
      { id: ComptageStatut.valide, libelle: 'Validé'},
      { id: ComptageStatut.finalise, libelle: 'Envoyé'}
    ]
  }

  getStatutsComptageReception() {
    return [
      { id: ComptageStatut.enAttente, libelle: 'En attente'},
      { id: ComptageStatut.enCours, libelle: 'En cours'},
      { id: ComptageStatut.transmis, libelle: 'Verrouillé'},
      { id: ComptageStatut.annule, libelle: 'Annulé'},
      { id: ComptageStatut.valide, libelle: 'Validé'},
      { id: ComptageStatut.finalise, libelle: 'Finalisé'}
    ]
  }

  getStatutsReception() {
    return [
      { id: ComptageStatut.enAttente, libelle: 'En attente'},
      { id: ComptageStatut.enCours, libelle: 'En cours'},
      { id: ComptageStatut.transmis, libelle: 'Transmis'},
      { id: ComptageStatut.annule, libelle: 'Annulé'},
      { id: ComptageStatut.valide, libelle: 'Validé'},
      { id: ComptageStatut.rejete, libelle: 'Rejeté'},
      { id: ComptageStatut.finalise, libelle: 'Finalisé'}
    ]
  }

  getLibelleStatutReception(statut: ComptageStatut) {
    return this.getStatutsReception().find(o => o.id == statut)?.libelle || 'Statut inconnu';
  }

  getStatusComptagePreparation() {
    return [
      { id: ComptageStatut.enAttente, libelle: 'En attente'},
      { id: ComptageStatut.enCours, libelle: 'En cours'},
      { id: ComptageStatut.transmis, libelle: 'Transmis'},
      { id: ComptageStatut.annule, libelle: 'Annulé'},
      { id: ComptageStatut.valide, libelle: 'Validé'},
      { id: ComptageStatut.rejete, libelle: 'Rejeté'},
      { id: ComptageStatut.finalise, libelle: 'Finalisé'}
    ];
  }

  getDocumentComptageType() {
    return [
      { id: ComptageType.inventaire, libelle: 'Inventaire'},
      { id: ComptageType.reception, libelle: 'Récéption'},
    ];
  }

  getCodeBarreValidite() {
    return [
      { id: CodeBarreValidite.aValider, libelle: 'A valider'},
      { id: CodeBarreValidite.nonValide, libelle: 'Non valide'},
      { id: CodeBarreValidite.valide, libelle: 'Valide'}
    ];
  }

  getObjectTypes() {
    return [
      { id: ObjectType.root, intitule: "Root" },
      { id: ObjectType.societe, intitule: "Société" },
      { id: ObjectType.article, intitule: "Article" },
      { id: ObjectType.partenaire, intitule: "Partenaire" },
      { id: ObjectType.site, intitule: "Site" },
      { id: ObjectType.materiel, intitule: "Matériel" },
      { id: ObjectType.activite, intitule: "Intervention" },
      { id: ObjectType.interventionTemplate, intitule: "InterventionTemplate" },
      { id: ObjectType.dossier, intitule: "Dossier" },
      { id: ObjectType.facture, intitule: "Facture" },
      { id: ObjectType.avoir, intitule: "Avoir" },
      { id: ObjectType.bonCommande, intitule: "Bon de commande" },
      { id: ObjectType.reservationStock, intitule: "Réservation de stock" },
      { id: ObjectType.devis, intitule: "Devis" },
      { id: ObjectType.utilisateur, intitule: "Utilisateur" },
      { id: ObjectType.reglement, intitule: "Règlement" },
      { id: ObjectType.declarationDouaniere, intitule: "Declaration douanière" },
      { id: ObjectType.prixRevient, intitule: "Prix de revient" },
      { id: ObjectType.inventaire, intitule: "Inventaire" },
      { id: ObjectType.ficheNavette, intitule: "Fiche Navette" },
      { id: ObjectType.comptaMatiere, intitule: "ComptaMatiere" },
      { id: ObjectType.ventesChr, intitule: "Ventes CHR" },
      { id: ObjectType.demande, intitule: "Demande" },
      { id: ObjectType.portefeuille, intitule: "Portefeuille" },
      { id: ObjectType.classificationValeur, intitule: "Classification valeur" },
      { id: ObjectType.connaissement, intitule: "Connaissement" },
      { id: ObjectType.caisse, intitule: "Caisse" },
      { id: ObjectType.bon, intitule: "Bon de stock" },
      { id: ObjectType.mouvementType, intitule: "Type de mouvement" },
      { id: ObjectType.reglementMode, intitule: "Mode de règlement" },
      { id: ObjectType.comptage, intitule: "Comptage" },
      { id: ObjectType.tarification, intitule: "Tarification" },
      { id: ObjectType.lieuStockage, intitule: "Lieu Stockage" },
      { id: ObjectType.lieuStockage, intitule: "TypeRemise" },
      { id: ObjectType.autre, intitule: "Autre" },
      { id: ObjectType.documentLogistique, intitule: "Document Logistique" },
      { id: ObjectType.documentLogistique, intitule: "Document Logistique" }
    ];
  }

  sortByKey(array, key, order = 'asc') {
    return array.sort((a, b) => {
      const valueA = a[key].toUpperCase();
      const valueB = b[key].toUpperCase();
  
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
  
      return order === 'desc' ? comparison * -1 : comparison;
    });
  }

  getNomsQuantitesDocumentLogistique(isSource: boolean, isDestination: boolean) {
    let mvtSens = null;
    if (isSource && !isDestination) {
      mvtSens = MouvementSens.sortie;
    } else if (!isSource && isDestination) {
      mvtSens = MouvementSens.entree;
    } else if (isSource && isDestination) {
      mvtSens = MouvementSens.inventaire;
    }

    return this.getNomsQuantites(mvtSens);
  }
  
  getNomsQuantites(mouvementSens: MouvementSens) {
    const refs = {
      [MouvementSens.sortie]: { 
        initiale: 'quantite_initiale_preparation',
        reservee: 'quantite_reservee_preparation',
      },
      [MouvementSens.entree]: { 
        initiale: 'quantite_initiale_reception',
        reservee: 'quantite_reservee_reception',
      },
      [MouvementSens.inventaire]: { 
        initiale: 'quantite_initiale_inventaire',
        reservee: 'quantite_reservee_inventaire',
      },
    };

    const refInitiale = refs[mouvementSens]?.initiale || '';
    const refReservee = refs[mouvementSens]?.reservee || '';
    const nomQuantiteInitiale = this.internationalisation.transform(refInitiale, 'Quantité Initiale');
    const nomQuantiteReservee = this.internationalisation.transform(refReservee, 'Quantité Réservée');

    return { quantiteInitiale: nomQuantiteInitiale, quantiteReservee: nomQuantiteReservee };
  }

  async getUtilisateursDataSource(): Promise<DataSource> {
    const users = await lastValueFrom(this.utilisateurClient.getUtilisateurs(TypeUtilisateur.collaborateur, false, true));
    if (users != null) {
      return new DataSource({
        key: 'id',
        paginate: true,
        pageSize: 10,
        store: new CustomStore({
          load: (options) => {
            const { skip, take } = options;
            return users
              .filter((g) => !options.searchValue || g.intitule?.toUpperCase().includes(options.searchValue.toUpperCase()))
              .slice(skip, skip + take);
          },
          byKey: (key) => Promise.resolve(users.find((g) => g.id == key)),
        }),
      }) as DataSource;
    }
    return null;
  }

  generateUuidV4(): string {
    return uuidV4();
  }
}