<dx-data-grid #dataGrid
  [dataSource]="classifications"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [width]="785"
  (onRowInserting)="onRowInserting()"
  (onRowRemoving)="onRowRemoving($event.key)"
  (onSaving)="onSaving($event)"
  (onSaved)="resetSelectedValues()"
  (onEditCanceled)="resetSelectedValues()"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before" *ngIf="allowEdit">
      <div *dxTemplate>
        <dx-select-box
          #selectBoxClassificationType
          [dataSource]="classificationTypeDataSource"
          [displayExpr]="classificationTypeDisplayExpr"
          valueExpr="id"
          [width]="300" placeholder="Sélectionner un type de classification..."
          [(value)]="classificationTypeIdForSelectBox"
          (onSelectionChanged)="filterClassificationValuesForSelectBox()"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item location="before" *ngIf="allowEdit">
      <div *dxTemplate style="display: flex;">
        <dx-select-box #selectBoxClassificationValue
          [dataSource]="classificationValueDataSourceForSelectBox"
          [displayExpr]="classificationValueDisplayExpr"
          searchEnabled="true"
          [searchExpr]="['intitule', 'codeBo']"
          [width]="300" placeholder="Sélectionner une classification..."
          (keydown)="onSelectBoxClassificationValuePressEnter($event)"
          [disabled]="classificationTypeIdForSelectBox == null"
        ></dx-select-box>
        <dx-button icon="add" (onClick)="onAddClassificationValueWithSelectBox()" style="margin-left: 6px;" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setClassifications()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="popup" [allowAdding]="allowEdit" [allowUpdating]="false" [allowDeleting]="allowEdit">
    <dxo-popup
      title="Ajouter une classification"
      [showTitle]="true"
      [width]="700"
      [height]="700"
      [showCloseButton]="true"
      contentTemplate="contentPopup"
    ></dxo-popup>
    <div *dxTemplate="let data of 'contentPopup'">
      <div style="padding: 24px;">
        <div style="margin-bottom: 6px;">Type de classification</div>
        <div>
          <dx-select-box
            #selectBoxClassificationTypePopup
            [dataSource]="classificationTypeDataSource"
            [displayExpr]="classificationTypeDisplayExpr"
            valueExpr="id"
            [(value)]="classificationTypeIdForPopup"
            (onSelectionChanged)="filterClassificationValuesForTreeList()"
          ></dx-select-box>
        </div>

        <div style="margin: 24px 0 6px 0;">
          <span [hidden]="classificationTypeIdForPopup != null">Veuillez sélectionner un type de classification</span>
          <span [hidden]="classificationTypeIdForPopup == null">{{ classificationsToAddDisplayText }}</span>
        </div>
        <div style="height: 460px; overflow: auto;" [hidden]="classificationTypeIdForPopup == null">
          <dx-scroll-view>
            <dx-tree-list
              #treeList
              [dataSource]="classificationValueDataSourceForTreeList"
              parentIdExpr="parentId"
              [rootValue]="null"
              [showRowLines]="true"
              [showBorders]="true"
              [columnAutoWidth]="true"
              (onSelectionChanged)="onClassificationValueSelectionChanged($event)"
              (onRowExpanded)="onRowExpanded($event)"
              [height]="460"
            >
              <!-- Options -->
              <dxo-remote-operations [filtering]="true" [sorting]="true" [paging]="true" [grouping]="true"></dxo-remote-operations>
              <dxo-scrolling mode="infinite"></dxo-scrolling>
              <dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>
              <dxo-search-panel [visible]="true"></dxo-search-panel>

              <!-- Colonnes -->
              <dxi-column dataField="codeBo" caption="Code"></dxi-column>
              <dxi-column dataField="intitule" caption="Intitulé"></dxi-column>
            </dx-tree-list>
          </dx-scroll-view>
        </div>
      </div>
    </div>
  </dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="classificationTypeIntitule" caption="Type"></dxi-column>
  <dxi-column dataField="classificationValueCode" caption="Code"></dxi-column>
  <dxi-column dataField="classificationValueIntitule" caption="Intitulé"></dxi-column>
</dx-data-grid>