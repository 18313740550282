import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-date-creation',
  templateUrl: './tooltip-date-creation.component.html',
  styleUrls: ['./tooltip-date-creation.component.scss'],
})
export class TooltipDateCreationComponent {
  @Input() dateCreation: string;
  @Input() hasDateCreationDocument: boolean;
  @Input() hasDateCreationBon: boolean;

  showTooltipDateCreationDocument: boolean = false;
  showTooltipDateCreationBon: boolean = false;
  
  get message(): string {
    const date = new Date(this.dateCreation);
    const jour = date.getDate().toString().padStart(2, '0');
    const mois = (date.getMonth() + 1).toString().padStart(2, '0');
    const année = date.getFullYear();
    const heure = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    return `Créé le ${jour}/${mois}/${année} à ${heure}h${minute}`;
  }

  constructor() { }

}
