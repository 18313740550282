import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MouvementMarchandiseDto } from '@lexi-clients/lexi';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { MouvementMarchandiseListResult } from '../models/mouvement-marchandise-list';

@Injectable({ providedIn: 'root' })
export class MouvementMarchandiseListService implements IGridService<MouvementMarchandiseDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<MouvementMarchandiseListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/MouvementStocks/recherche-devexpress/marchandise`, { params: params });
  }

  getById(mouvementStockId: number): Observable<MouvementMarchandiseDto> {
    return this.http.get<any>(`${this.baseUrl}/api/MouvementStocks/${mouvementStockId}/marchandise`);
  }

  post(valeur: MouvementMarchandiseDto): Observable<any> {
    return null;
  }

  put(valeur: MouvementMarchandiseDto): Observable<any> {
    return null;
  }

  delete(valeur: MouvementMarchandiseDto): Observable<any> {
    return null;
  }
}
