<dx-data-grid
  [dataSource]="conditionnements"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onRowInserting)="onRowInserting($event.data)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event.key)"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setConditionnements()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"></dxo-editing>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" [allowEditing]="false" caption="Code"></dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
  <dxi-column dataField="uniteId" caption="Unité">
    <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="coefficient" caption="Colisage" dataType="number" format="#0.###"></dxi-column>
  <dxi-column cellTemplate="cellUniteBase" caption="Unité de Base"></dxi-column>
  <dxi-column dataField="volume" caption="Volume" dataType="number" format="#0.###"></dxi-column>
  <dxi-column dataField="poidsBrut" caption="Poids brut" dataType="number" format="#0.###"></dxi-column>
  <dxi-column dataField="poidsNet" caption="Poids net" dataType="number" format="#0.###"></dxi-column>
  <dxi-column dataField="_synchronized" caption="Synchronisé" dataType="boolean" [visible]="false"></dxi-column>
  <dxi-column dataField="uniteDouaneId" caption="Unité douane" [visible]="false">
    <dxo-lookup [dataSource]="uniteDouaneDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="quantiteUniteDouaneStatistique" caption="Qté douane statistique" [visible]="false" dataType="number" format="#0.###"></dxi-column>
  <dxi-column dataField="quantiteUniteDouaneSpecifique" caption="Qté douane spécifique" [visible]="false" dataType="number" format="#0.###"></dxi-column>

  <div *dxTemplate="let cell of 'cellUniteBase'">
    <span *ngIf="uniteBase">{{ uniteBase.intitule }} ( {{ uniteBase.codeBo }} )</span>
</div>
</dx-data-grid>