import { Injectable } from '@angular/core';
import { SocieteAvecPermissionsPortailClientDto, SocietesLexiClient, SocieteToUpdateDto } from '@lexi-clients/lexi';
import { AuthService } from '../settings/auth.service';
import { BehaviorSubject, filter, lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocieteService {
  private readonly defaultSocietePortailClient: SocieteAvecPermissionsPortailClientDto = {
    id: 0,
    intitule: "DefaultSocietePortailClient",
    portailClientAffichageFactures: false,
    portailClientAffichageDemandes: false,
    portailClientAffichageActivites: false,
    portailClientAffichageDossiers: false,
    portailClientAffichageConnaissements: false,
  };
  private societePortailClient: SocieteAvecPermissionsPortailClientDto;
  private societe$: BehaviorSubject<SocieteToUpdateDto> = new BehaviorSubject(null);

  get currentSociete$(): Observable<SocieteToUpdateDto> {
    return this.societe$.asObservable();
  }

  constructor(
    private readonly societeLexiClient: SocietesLexiClient,
    private readonly authService: AuthService,
  ) {
    authService.currentSocieteId$.pipe(
      filter((societeId: number) => societeId != null)
    ).subscribe(societeId => {
      societeLexiClient.getById(societeId).subscribe((societe: SocieteToUpdateDto) => this.societe$.next(societe));
    });
  }

  getSocietePortailClient() {
    return this.societePortailClient;
  }

  async setSocietePortailClient() {
    if(!this.authService.securityUser) {
      this.societePortailClient = this.defaultSocietePortailClient;
      return;
    }

    this.societePortailClient = await lastValueFrom(this.societeLexiClient.getByPartenaireId(this.authService.securityUser.partenaireId));
  }

  setCurrentSociete(societe: SocieteToUpdateDto) {
    this.societe$.next(societe);
  }

}
