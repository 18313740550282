import { Component, Input, ViewChild } from '@angular/core';
import { LexiMouvementTypeDto, PieceNature, LexiMouvementTypesLexiClient, GetSiteListDto, SitesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { MagasinPieceNatureListService } from 'lexi-angular/src/app/services/magasin-piece-nature.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-magasin-piece-nature-datagrid',
  templateUrl: './magasin-piece-nature-datagrid.component.html',
  styleUrls: ['./magasin-piece-nature-datagrid.component.scss'],
})
export class MagasinPieceNatureDatagridComponent {
  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;

  private _siteId: number = null;
  get siteId(): number {
    return this._siteId;
  }
  @Input() set siteId(value: number) {
    if (value) {
      this._siteId = value;
      this.dataGridStorageKey = "grille_magasin_piece_nature_site";
      this.setDataSource();
      this.setSecondaryDataSources();
    }
  }
  @Input() siteReadOnly: boolean = true;

  private _societeId: number = null;
  get societeId(): number {
    return this._societeId;
  }
  @Input() set societeId(value: number) {
    if (value) {
      this._societeId = value;
      this.dataGridStorageKey = "grille_magasin_piece_nature_globale";
      this.setDataSource();
      this.setSecondaryDataSources();
    }
  }

  dataGridStorageKey = "grille_magasin_piece_nature_globale";
  mouvementTypeDataSource: LexiMouvementTypeDto[] = [];
  siteDataSource: GetSiteListDto[] = [];
  magasinPieceNatureStore: CustomStore;
  pieceNatureDataSource = [
    { id: PieceNature.facture, intitule: "Facture" },
    { id: PieceNature.avoir, intitule: "Avoir" },
    { id: PieceNature.devis, intitule: "Devis" },
    { id: PieceNature.bonDePreparation, intitule: "Bon de préparation" },
    { id: PieceNature.commande, intitule: "Commande" },
    { id: PieceNature.factureFournisseur, intitule: "Facture fournisseur" },
    { id: PieceNature.consommationInterne, intitule: "Consommation interne" },
    { id: PieceNature.reversementInterne, intitule: "Reversement interne" },
    { id: PieceNature.bonDeLivraison, intitule: "Bon de livraison" },
  ];

  constructor(
    private readonly mouvementTypesLexiClient: LexiMouvementTypesLexiClient,
    private readonly magasinPieceNatureListService: MagasinPieceNatureListService,
    private readonly sitesLexiClient: SitesLexiClient
  ) { }

  async setDataSource() {
    const additionalParams = new Map();
    if (this.societeId != null) {
      additionalParams.set("societeId", this.societeId);
    }
    else if (this.siteId != null) {
      additionalParams.set("siteId", this.siteId);
    }
    this.magasinPieceNatureStore = new CustomStoreService(this.magasinPieceNatureListService).getCustomStore(additionalParams);
  }

  private async setSecondaryDataSources() {
    this.mouvementTypeDataSource = await lastValueFrom(this.mouvementTypesLexiClient.getMouvementType(null, false));
    this.siteDataSource = await lastValueFrom(this.sitesLexiClient.getBySociete());
  }

  async onKeyDown(e) {
    if (!this.siteReadOnly && e.event.key === "Insert") {
        this.dataGrid.instance.addRow();
    }
  }
}
