import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OidcUaaConfig, QUERY_KEY_RETURN_URL } from '@lexi/oidc-uaa';
import { AuthService } from '../settings/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManageStockGuard  {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly config: OidcUaaConfig
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.authService.currentSociete != null && this.authService.currentSociete.gestionStock) {
      return true;
    }

    this.router.navigate([this.config?.openIDConfiguration?.forbiddenRoute ?? '/'], { queryParams: { [QUERY_KEY_RETURN_URL]: state.url }});
    return false;
  }

}
