import { Injectable } from '@angular/core';
import { AboutLexiClient, TypeUtilisateur } from '@lexi-clients/lexi';
import   lexiVersion  from '../../app-info.json';
import { AuthService } from '../../settings/auth.service';

@Injectable()
export class AppInfoService {
  apiLexiVersion = "";

  constructor(private authService: AuthService, private aboutLexiClient: AboutLexiClient) {
    this.aboutLexiClient.getAppVersion().subscribe(v => this.apiLexiVersion = v)
  }

  public get title() {
    if (this.authService.securityUser?.typeUtilisateur == TypeUtilisateur.partenaire.toLowerCase()) {
      return 'Espace client';
    }
    return 'Lexi';
  }

  public get currentYear() {
    return new Date().getFullYear();
  }

  public get lexiVersionAngular() {
    return lexiVersion.lexiVersion;
  }

  public get lexiVersionAPI(): string {
    return this.apiLexiVersion;
  }
}
