<dx-data-grid
  [dataSource]="contacts"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onSaved)="onSubmit($event)"
  (onSelectionChanged)="onSelectionChanged()"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onRowClick)="showContactDetails($event.data)"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-editing [allowAdding]="true" [allowUpdating]="false" [allowDeleting]="isCollaborateur" mode="row"></dxo-editing>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

  <!-- Colonnes -->
  <dxi-column [allowHeaderFiltering]="true" dataField="intitule" caption="Intitulé"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="fonction" caption="Fonction"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="relationsString" caption="Rôles"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="email" cellTemplate="mailTemplate" caption="Email" width="180">
    <div *dxTemplate="let d of 'mailTemplate'">
      <a href="mailto:{{d.data['intitule']}}<{{d.data['email']}}>">{{ d.data['email'] }}</a>
    </div>
  </dxi-column>
  <dxi-column [allowHeaderFiltering]="true" [visible]="false" dataField="telephoneMobile" caption="Téléphone mobile" width="125"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" [visible]="false" dataField="telephoneDomicile" caption="Téléphone domicile" width="130"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" [visible]="false" dataField="telephoneBureau" caption="Téléphone bureau" width="125"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="accesPortail" dataType="boolean" caption="Portail" width="80"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="actif" dataType="boolean" caption="Actif" width="80"></dxi-column>

</dx-data-grid>


<!-- Popup modifier le contact -->
<dx-popup
  title = "Modification d'un contact"
  [(visible)]="showModificationContactPopup"
  (onHiding)="closeModificationContactPopup()"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
>
  <dx-scroll-view>
    <!-- Infos contact -->
    <div style="margin: 40px;">
      <dx-form [formData]="contactToUpdate" [colCount]="2">
        <dxi-item dataField="intitule" [label]="{text: 'Intitulé'}"></dxi-item>
        <dxi-item dataField="fonction"></dxi-item>
        <dxi-item dataField="email"></dxi-item>
        <dxi-item dataField="telephoneMobile" [label]="{text: 'Téléphone mobile'}"></dxi-item>
        <dxi-item dataField="telephoneDomicile" [label]="{text: 'Téléphone domicile'}"></dxi-item>
        <dxi-item dataField="telephoneBureau" [label]="{text: 'Téléphone bureau'}"></dxi-item>
        <dxi-item dataField="accesPortail" editorType="dxCheckBox" [label]="{text: 'Accès Portail'}"></dxi-item>
        <dxi-item dataField="actif" editorType="dxCheckBox"></dxi-item>
      </dx-form>
    </div>

    <!-- Roles -->
    <div style="margin-bottom: 40px;">
      <h4 style="border-bottom: solid 1px #e0e0e0;">Rôles sur ce client</h4>
      <div *ngFor="let role of roles" style="margin: 5px 0; display: flex; align-items: center;">
        <dx-check-box [value]="selectedRoleIds.includes(role.id)" [text]="role.intitule" (onValueChanged)="onRoleSelected($event.value, role)"></dx-check-box>
      </div>
    </div>

    <div style="display: flex; justify-content: center; margin-bottom: 20px;">
      <dx-button text="Annuler" style="margin: 0 10px 0 20px;" (onClick)="closeModificationContactPopup()"></dx-button>
      <dx-button text="Enregistrer" (onClick)="updateContact()"></dx-button>
    </div>
  </dx-scroll-view>

</dx-popup>


<!-- Popup création de contact -->
<dx-popup
  title = "Création d'un contact"
  [(visible)]="showCreationContactPopup"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
>

  <dx-scroll-view>
    <!-- Infos contact -->
    <div style="margin: 40px;">
      <h4 *ngIf="!isCollaborateur">Rechercher un utilisateur :<br></h4>
      <dx-form #formPartenaire *ngIf="!isCollaborateur" [formData]="contactToCreate" [colCount]="2" (onFieldDataChanged)="fieldChanged()">
        <dxi-item dataField="email">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
      </dx-form>
      <dx-form #formCollaborateur *ngIf="isCollaborateur" [formData]="contactToCreate" [colCount]="2" (onFieldDataChanged)="fieldChanged()">
        <dxi-item dataField="intitule" [label]="{text: 'Intitulé'}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="fonction"></dxi-item>
        <dxi-item dataField="email">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="telephoneMobile" [label]="{text: 'Téléphone mobile'}"></dxi-item>
        <dxi-item dataField="telephoneDomicile" [label]="{text: 'Téléphone domicile'}"></dxi-item>
        <dxi-item dataField="telephoneBureau" [label]="{text: 'Téléphone bureau'}"></dxi-item>
        <dxi-item dataField="accesAutorise" editorType="dxCheckBox" [label]="{text: 'Accès Portail'}"></dxi-item>
        <dxi-item dataField="actif" editorType="dxCheckBox"></dxi-item>
      </dx-form>
    </div>

    <!-- Associations possibles -->
    <div *ngIf="associationsPossibles.length > 0" style="margin-bottom: 40px;">
      <h4 style="border-bottom: solid 1px #e0e0e0;">Associations possibles</h4>
      <div style="margin: 5px 0; display: flex; align-items: center;">
        <dx-data-grid
          id="gridContainer"
          height="auto"
          [dataSource]="associationsPossibles"
          [allowColumnReordering]="false"
          [columnAutoWidth]="true"
          [allowColumnResizing]="false"
          [showBorders]="true"
        >
          <dxi-column dataField="nom" caption="Nom"></dxi-column>
          <dxi-column dataField="prenom" caption="Prénom"></dxi-column>
          <dxi-column dataField="email" caption="Email"></dxi-column>
          <dxi-column cellTemplate="associer">
            <div *dxTemplate="let cell of 'associer'" (click)="createFromUser(cell)">
              <dx-button icon="todo" hint="Associer cet utilisateur"></dx-button>
            </div>
          </dxi-column>
        </dx-data-grid>
      </div>
    </div>

    <!-- Roles -->
    <div *ngIf="isCollaborateur" style="margin-bottom: 40px;">
      <h4 style="border-bottom: solid 1px #e0e0e0;">Rôles sur ce client</h4>
      <div *ngFor="let role of roles" style="margin: 5px 0; display: flex; align-items: center;">
        <dx-check-box [value]="selectedRoleIds.includes(role.id)" [text]="role.intitule" (onValueChanged)="onRoleSelected($event.value, role)"></dx-check-box>
      </div>
    </div>

    <div *ngIf="isCollaborateur" style="display: flex; justify-content: center; margin-bottom: 20px;">
      <dx-button text="Annuler" style="margin: 0 10px 0 20px;" (onClick)="showCreationContactPopup = false"></dx-button>
      <dx-button text="Enregistrer" (onClick)="createContact()"></dx-button>
    </div>
  </dx-scroll-view>

</dx-popup>
