<div class="row">
  <div class="column">
    <p>
      Nombre de colis : {{_selectedConnaissementDetail?.nombreColis}}
    </p>
    <p>
      Destination : {{_selectedConnaissementDetail?.ileArriveeIntitule}}
    </p>
    <p>
      Navire : {{_selectedConnaissementDetail?.transporteurIntitule}}
    </p>
  </div>
  <div class="column">
    <p>
      Date de départ : {{_selectedConnaissementDetail?.dateDepart  | date:'dd/MM/yyyy'}}
    </p>
    <p>
      Voyage : {{_selectedConnaissementDetail?.numeroVoyage}}
    </p>
    <p>
      Numéro Revatua : {{_selectedConnaissementDetail?.numeroRevatua}}
    </p>

  </div>
</div>

<div>
  <dx-data-grid
    class="gridContainer"
    #grid
    [columns] = "columnsChargeur"
    [dataSource]="connaissementDetails"
    [showBorders]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    [height]="600"
  >
    <!-- Options -->
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <!-- CellTemplates -->
    <div *dxTemplate="let cell of 'volumeTemplate'">
      {{cell.data.volume}} {{convertUniteVolumeToString(cell.data.uniteVolume, cell.data.volume)}}
    </div>

    <div *dxTemplate="let cell of 'poidTemplate'">
      {{cell.data.poids}} {{convertUnitePoidsToString(cell.data.unitePoids, cell.data.poids)}}
    </div>

    <div *dxTemplate="let cell of 'stockageTemplate'">
      {{convertUniteStockageToString(cell.data.stockage)}}
    </div>
  </dx-data-grid>
</div>