import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";

@Injectable({ providedIn: "root" })
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(fallbackRoute?: string): void {
    // On retire de l'historique les urls qui renvoient vers l'accueil comme '/?returnUrl=...'
    this.history = this.history.filter(x => !x.startsWith("/?"));
    this.history.pop();

    if (this.history.length > 0) {
      this.location.back();
    }
    else if (fallbackRoute) {
      this.router.navigate([fallbackRoute]);
    }
    else {
      this.router.navigateByUrl("/");
    }
  }
}