import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { MouvementMarchandiseDto, Permissions, DocumentType } from '@lexi-clients/lexi';
import { AuthService } from '../../../settings/auth.service'
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { formatDate } from '@angular/common';
import { ExportingEvent } from 'devextreme/ui/data_grid';

export class ConfigFiltreMouvement {
  isBySociete: boolean;
  isByPartenaire: boolean;
  isByDocumentLogistique: boolean;
  isByBon: boolean;
  isByArticle: boolean;
  isByPiece: boolean;
}

@Component({
  selector: 'app-mouvement-stock-datagrid',
  templateUrl: './mouvement-stock-datagrid.component.html',
  styleUrls: ['./mouvement-stock-datagrid.component.scss'],
})
export class MouvementStockDatagridComponent {
  private _dataGrid: DxDataGridComponent;
  get dataGrid() { return this._dataGrid }
  @ViewChild(DxDataGridComponent, { static: false }) set dataGrid(value: DxDataGridComponent) {
    this._dataGrid = value;
    this.setDatagridHeight();
    this.cdr.detectChanges();
  }

  _offsetTopInPx: number;
  get offsetTopInPx(): number { return this._offsetTopInPx; }
  @Input() set offsetTopInPx(value: number) {
    this._offsetTopInPx = value;
    this.setDatagridHeight();
  }

  public isSource: boolean = false;
  public isDestination: boolean = false;
  public isInBonOrDocument: boolean = null;
  public isBySociete: boolean = false;

  // filter builder
  public isFilterPopupVisible = false;
  fields = [
    {dataField: 'quantite', dataType:'number', caption: 'Quantité'}
    , {dataField: 'dateOperation', dataType:'date', caption: 'Date d\'opération'}
    , {dataField: 'lotSourceArticleIntitule', dataType:'string', caption: 'Article'}
  ];
  filter: any;
  gridFilterValue: any;

  @Input() isSourceVisible = true;
  @Input() isDestinationVisible = true;
  @Input() showTitle = true;

  private _configFiltre: ConfigFiltreMouvement;
  get configFiltre(): ConfigFiltreMouvement {
    return this._configFiltre;
  }
  @Input() set configFiltre(value: ConfigFiltreMouvement) {
    if (value != null && value != undefined) {
      this._configFiltre  = value;
      this.isInBonOrDocument = (value.isByBon || value.isByDocumentLogistique);
      this.setDataSource();
    }
  }

  private _currentPartenaireId: number;
  get currentPartenaireId(): number {
    return this._currentPartenaireId;
  }
  @Input() set currentPartenaireId(value: number) {
    if (value != null && value != undefined) {
      this._currentPartenaireId = value;
      this.setDataSource();
    }
  }

  private _partenaireSourceId: number = null;
  @Input() set partenaireSourceId(value: number) {
    if (value != null && value != undefined) {
      this._partenaireSourceId = value;
      this.setIsSource();
      this.setIsDestination();
    }
  }

  private _partenaireDestinationId: number = null;
  @Input() set partenaireDestinationId(value: number) {
    if (value != null && value != undefined) {
      this._partenaireDestinationId = value;
    }
    this.setIsSource();
  }

  private _articleId: number;
  get articleId(): number {
    return this._articleId;
  }
  @Input() set articleId(value: number) {
    if (value != null && value != undefined) {
      this._articleId = value;
      this.dataGridStorageKey = "grille_mouvements_stock_fiche_article";
      this.setDataSource();
    }
  }

  private _bonId: number;
  get bonId(): number {
    return this._bonId;
  }
  @Input() set bonId(value: number) {
    if (value) {
      this._bonId = value;
      this.dataGridStorageKey = "grille_mouvements_stock_bon";
      this.setDataSource();
    }
  }

  private _documentLogistiqueCodeBo: string;
  get documentLogistiqueCodeBo(): string {
    return this._documentLogistiqueCodeBo;
  }
  @Input() set documentLogistiqueCodeBo(value: string) {
    if (value) {
      this._documentLogistiqueCodeBo = value;
      this.dataGridStorageKey = "grille_mouvements_stock_documentLogistique";
      this.setDataSource();
    }
  }

  private _noPiece: string;
  get noPiece(): string {
    return this._noPiece;
  }
  @Input() set noPiece(value: string) {
    if (value != null && value != undefined) {
      this._noPiece = value;
      this.dataGridStorageKey = "grille_mouvements_stock_piece_detail";
      this.setDataSource();
    }
  }

  mouvementStockStore: CustomStore;
  dataGridStorageKey = "grille_mouvements_stock_globale";
  showAlertColumn = false;
  // Tableau des index des lignes qui devrait montrer une alerte
  alertRowIndexes = [];
  isAffichageMouvementsAutorise: boolean = false;
  isAffichagePMPAutorise: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  setDataSource() {
    if (
      this.configFiltre == null
      || (this.configFiltre.isByPartenaire && this.currentPartenaireId == null)
      || (this.configFiltre.isByBon && this.bonId == null)
      || (this.configFiltre.isByDocumentLogistique && (this.documentLogistiqueCodeBo == null || this.documentLogistiqueCodeBo == ""))
      || (this.configFiltre.isByArticle && this.articleId == null)
      || (this.configFiltre.isByPiece && (this.noPiece == null || this.noPiece == ""))
    ) {
      return;
    }

    this.isAffichageMouvementsAutorise = this.authService.securityUserisGrantedWith(Permissions.accesVueConsultationStock);
    this.isAffichagePMPAutorise = this.authService.securityUserisGrantedWith(Permissions.canAfficherPmp);
    if (this.isAffichageMouvementsAutorise) {
      this.mouvementStockStore = this.dxDataSourceService.getMouvementsCustomStore(
        this.configFiltre.isByPartenaire ? this.currentPartenaireId : null,
        this.articleId, this.bonId, this.documentLogistiqueCodeBo, this.noPiece,
        this.setOriginLink
      );
    }
  }

  setDataSourceByPartenaire() {
    if (this.configFiltre == null) return;
    this._configFiltre.isByPartenaire = true;
    this._configFiltre.isBySociete = false;
    this.setDataSource();
  }

  setDataSourceBySociete() {
    if (this.configFiltre == null) return;
    this._configFiltre.isBySociete = true;
    this._configFiltre.isByPartenaire = false;
    this.setDataSource();
  }

  getLinkToArticleDetail(e: MouvementMarchandiseDto) {
    return `/article/${e.lotSourceArticleId}`;
  }

  setOriginLink = (list: MouvementMarchandiseDto[]) => {
    if (list == null) return;

    for (const m of list) {
      switch (m.documentType) {
        case DocumentType.documentLogistique:
          (m as any).originLink = `/logistique/documents/${m.documentReference}`;
          (m as any).origin = "Document";
          break;
        case DocumentType.stock:
          (m as any).originLink = `/bon/${m.documentReference}`;
          (m as any).origin = "Bon";
          break;
        case DocumentType.vente:
          (m as any).originLink = `/vente/pieces/par-numero/${m.documentReference}`;
          (m as any).origin = "Pièce";
          break;
        default:
          break;
      }
    }
  }

  onCellPrepared(event) {
    if(event.column.dataField === "quantite") {
      event.cellElement.style.textAlign = "left";
    }
  }

  setIsSource() {
    this.isSource = this._partenaireSourceId != null && this._partenaireSourceId == this.currentPartenaireId;
  }

  setIsDestination() {
    this.isDestination = this._partenaireDestinationId != null && this._partenaireDestinationId == this.currentPartenaireId;
  }

  // filter
  applyFilter = () => {
    this.gridFilterValue = this.filter;
    this.closeFilterPopup();
  }

  openFilterPopup = () => {
    this.isFilterPopupVisible = true;
  }

  closeFilterPopup = () => {
    this.isFilterPopupVisible = false;
  }

  prefixeDestination = (data: { data: MouvementMarchandiseDto, value: string }) => {
    if (this.isInBonOrDocument === false && !this.isDestination) {
      if (data.data.partenaireDestinationIntitule != null) {
        return data.data.partenaireDestinationIntitule + " - ";
      }
    }
    return "";
  }

  prefixeSource = (data: { data: MouvementMarchandiseDto, value: string }) => {
    if (this.isInBonOrDocument === false && !this.isSource) {
      if (data.data.partenaireSourceIntitule != null) {
        return data.data.partenaireSourceIntitule + " - ";
      }
    }
    return "";
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Mouvements');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {
          // →  gridCell.data
          // Source
          if (gridCell.column.caption === 'Source') {
            excelCell.value = `${this.prefixeSource(gridCell.data)}${gridCell.data.lieuStockageSourceIntitule} (${gridCell.data.quantiteSourceAvant} → ${gridCell.data.quantiteSourceApres})`;
          }

          // Destination
          if (gridCell.column.caption === 'Destination') {
            excelCell.value = `${this.prefixeDestination(gridCell.data)}${gridCell.data.lieuStockageDestinationIntitule} (${gridCell.data.quantiteDestinationAvant} → ${gridCell.data.quantiteDestinationApres})`;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-mouvements-stock.xlsx`);
      });
    });
  }

  setDatagridHeight() {
    if (this.dataGrid == null || this.offsetTopInPx == null) return;
    this.dataGrid.instance.element().style.height = `calc(100vh - ${this.offsetTopInPx}px)`;
  }

}
