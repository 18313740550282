import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ClassificationTypeDto, ClassificationTypesLexiClient, ObjectType } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-classification-type-list',
  templateUrl: './classification-type-list.component.html',
  styleUrls: ['./classification-type-list.component.scss'],
})
export class ClassificationTypeListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  classificationTypes: ClassificationTypeDto[] = [];
  readonly objectTypesPossibles = [
    { key: "articles", id: ObjectType.article, intitule: `Article` },
    { key: "clients", id: ObjectType.partenaire, intitule: `Client` },
  ];

  objectTypesDataSource = this.objectTypesPossibles;
  objectType: ObjectType = null;

  @Input() set objectTypeKey(value: string) {
    if (value != null) {
      const objectTypeTrouve = this.objectTypesPossibles.find(o => o.key == value);
      this.objectType = objectTypeTrouve != null ? objectTypeTrouve.id : ObjectType[value];
      this.objectTypesDataSource = objectTypeTrouve != null 
        ? [objectTypeTrouve] 
        : [ { id: this.objectType, intitule: this.objectType, key: this.objectType } ];
      this.loadClassifications();
    }
  }
    
  constructor(
    private readonly classificationTypesClient: ClassificationTypesLexiClient
  ) { }

  ngOnInit() {
    if (this.objectType == null) {
      this.loadClassifications();
    }
  }

  async loadClassifications() {
    this.classificationTypes = this.objectType == null
      ? await lastValueFrom(this.classificationTypesClient.getAll())
      : await lastValueFrom(this.classificationTypesClient.getByObjectType(this.objectType))
  }

  onCreateClassification(e) {
    const dto: ClassificationTypeDto = e.data;
    this.classificationTypesClient.create(dto).subscribe(() => {
      this.reloadAndNotifySuccess('Type de Classification créé');
    });
  }

  onUpdateClassification(e) {
    const dto: ClassificationTypeDto = { ...e.oldData, ...e.newData };
    this.classificationTypesClient.update(dto.id, dto).subscribe(() => {
      this.reloadAndNotifySuccess('Type de Classification modifié');
    });
  }

  onDeleteClassification(e) {
    this.classificationTypesClient._delete(e.data.id).subscribe(() => {
      this.reloadAndNotifySuccess('Type de Classification supprimé');
    });
  }

  onInitNewRow(e) {
    e.data.niveau = false;
    if (this.objectType) {
      e.data.objectType = this.objectType;
    }
  }

  private reloadAndNotifySuccess(message: string) {
    this.loadClassifications();
    notify({ closeOnClick: true, message}, 'success');
  }

  async onKeyDown(e) {
    if (e.event.key === "Insert") {
        this.datagrid.instance.addRow();
    }
  }
}
