<ng-container *ngIf="isAuthenticated; else unauthenticated">
  <div class="center" *ngIf="!isAuthorized">
    <h5>Vous êtes connecté en tant que : {{ authService.authenticatedUser?.username }}</h5>
    <h5>Malheureusement, vous n'avez pas accès à la plateforme.</h5>
    <dx-button text="Actualiser" (onClick)="refreshPage()" style="margin-right: 10px;"></dx-button>
    <dx-button text="Se déconnecter" (onClick)="authService.logout();"></dx-button>
  </div>
  <router-outlet *ngIf="isAuthorized"></router-outlet>
</ng-container>

<ng-template #unauthenticated>
  <div class="center">
    <img *ngIf="isLoading" height="198" width="198" [src]="logoURL" alt="Logo Lexi" (error)="getDefaultLogo()" />
    <ng-container *ngIf="!isLoading">
      <div style="margin-bottom: 12px;">
        <img src="assets/images/logo_lexi_simple.png" height="198" width="198" alt="Logo Lexi"/>
      </div>
      <!--
        On utilise logout car si l'utilisateur voit ce bouton login, c'est qu'il y a eu un souci lors de sa connexion.
        On s'assure donc qu'il soit bien logout avant de le renvoyer sur la page de connexion.
       -->
      <dx-button text="Se connecter" (onClick)="authService.logout()"></dx-button>
    </ng-container>
  </div>
</ng-template>