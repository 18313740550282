import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

/**
 * Voir AppInitService :
 * "En Prod, charge la configuration depuis les assets
 * En Dev, charge la configuration depuis environment.ts"
 */
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService  {

  constructor() {}

  get environment() {
    return environment;
  }
}
