import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { NomenclatureDouaneListDto } from '@lexi-clients/lexi';
import { NomenclatureDouaneListResult } from '../models/nomenclature-douane-list';

@Injectable({
  providedIn: 'root'
})
export class NomenclatureDouaneListService implements IGridService<NomenclatureDouaneListDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<NomenclatureDouaneListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/NomenclatureDouanes/recherche-devexpress`, { params: params });
  }

  getById(nomenclatureDouaneId: number): Observable<NomenclatureDouaneListDto> {
    return this.http.get<any>(`${this.baseUrl}/api/NomenclatureDouanes/${nomenclatureDouaneId}`);
  }

  post(valeur: NomenclatureDouaneListDto): Observable<any> {
    return null;
  }

  put(valeur: NomenclatureDouaneListDto): Observable<any> {
    return null;
  }

  delete(valeur: NomenclatureDouaneListDto): Observable<any> {
    return null;
  }
}
