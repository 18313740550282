import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { TaxeGroupeDto } from '@lexi-clients/lexi';
import { TaxeGroupeListResult } from '../models/taxe-groupe-list';

@Injectable({
  providedIn: 'root'
})
export class TaxeGroupeListService implements IGridService<TaxeGroupeDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<TaxeGroupeListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/TaxeGroupes/recherche-devexpress`, { params: params });
  }

  getById(taxeGroupeId: number): Observable<TaxeGroupeDto> {
    return this.http.get<any>(`${this.baseUrl}/api/TaxeGroupes/${taxeGroupeId}`);
  }

  post(valeur: TaxeGroupeDto): Observable<any> {
    return null;
  }

  put(valeur: TaxeGroupeDto): Observable<any> {
    return null;
  }

  delete(valeur: TaxeGroupeDto): Observable<any> {
    return null;
  }
}
