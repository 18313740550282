import { Injectable } from '@angular/core';
import { ObjectType } from '@lexi-clients/lexi';
import { InternationalisationPipe } from '../../modules/shared/pipes/internationalisation.pipe';

@Injectable()
export class ObjectTypeService {
  private readonly objectTypes = [
    { id: ObjectType.root, intitule: "Root" },
    { id: ObjectType.societe, intitule: "Société" },
    { id: ObjectType.article, intitule: "Article" },
    { id: ObjectType.partenaire, intitule: "Partenaire" },
    { id: ObjectType.site, intitule: "Site" },
    { id: ObjectType.materiel, intitule: "Matériel" },
    { id: ObjectType.activite, intitule: "Intervention" },
    { id: ObjectType.interventionTemplate, intitule: "InterventionTemplate" },
    { id: ObjectType.dossier, intitule: "Dossier" },
    { id: ObjectType.facture, intitule: "Facture" },
    { id: ObjectType.avoir, intitule: "Avoir" },
    { id: ObjectType.bonCommande, intitule: "Bon de commande" },
    { id: ObjectType.reservationStock, intitule: "Réservation de stock" },
    { id: ObjectType.devis, intitule: "Devis" },
    { id: ObjectType.utilisateur, intitule: "Utilisateur" },
    { id: ObjectType.reglement, intitule: "Règlement" },
    { id: ObjectType.declarationDouaniere, intitule: "Declaration douanière" },
    { id: ObjectType.prixRevient, intitule: "Prix de revient" },
    { id: ObjectType.inventaire, intitule: "Inventaire" },
    { id: ObjectType.ficheNavette, intitule: "Fiche Navette" },
    { id: ObjectType.comptaMatiere, intitule: "ComptaMatiere" },
    { id: ObjectType.ventesChr, intitule: "Ventes CHR" },
    { id: ObjectType.demande, intitule: "Demande" },
    { id: ObjectType.portefeuille, intitule: "Portefeuille" },
    { id: ObjectType.classificationValeur, intitule: "Classification valeur" },
    { id: ObjectType.connaissement, intitule: "Connaissement" },
    { id: ObjectType.caisse, intitule: "Caisse" },
    { id: ObjectType.bon, intitule: "Bon" },
    { id: ObjectType.mouvementType, intitule: "Type de mouvement" },
    { id: ObjectType.reglementMode, intitule: "Mode de règlement" },
    { id: ObjectType.tarification, intitule: "Tarification" },
    { id: ObjectType.autre, intitule: "Autre" },
    { id: ObjectType.typeRemise, intitule: "Type de remise" },
    { id: ObjectType.lieuStockage, intitule: "Lieu de stockage" },
    { id: ObjectType.comptage, intitule: "Comptage" },
    { id: ObjectType.documentLogistique, intitule: "Document Logistique" }
  ];

  private pipeUsed = false;

  constructor(private readonly internationalisationPipe: InternationalisationPipe) {
    for(const t of this.objectTypes) {
      t.intitule = this.internationalisationPipe.transform(t.intitule);
    }
    this.pipeUsed = true;
  }

  public getObjectTypes() {
    if (this.pipeUsed) return this.objectTypes;
    for(const t of this.objectTypes) {
      t.intitule = this.internationalisationPipe.transform(t.intitule);
    }
    this.pipeUsed = true;
    return this.objectTypes;
  }
}
