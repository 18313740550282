import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ArticleScanResultDto, ArticlesLexiClient, BonDto, ComptageDto, ComptageLigneDto, ComptageLignesLexiClient, ComptageStatut, ComptagesLexiClient, ControleType, EcartsQuantiteLigneResultDto, EtapeAction, FluxStatut, MouvementSens, PaquetDto, Permissions, RechercheDxArticleDto, RegistresNumerosSerieLexiClient, UniteDto } from '@lexi-clients/lexi';
import { DxDataGridComponent, DxTextBoxComponent } from 'devextreme-angular';
import { alert, confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { lastValueFrom } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { NotificationType } from '../../../references/references';
import { ReferenceService } from '../../../references/references.service';
import { MarchandiseLigne } from '../bon-detail.component';
import { ConditionnementService, ConditionnementType } from 'lexi-angular/src/app/services/conditionnement.service';
import DataSource from 'devextreme/data/data_source';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { ExportingEvent } from 'devextreme/ui/data_grid';

const statutsComptagesEnCours = [ComptageStatut.enCours, ComptageStatut.transmis];
const statutsComptagesAnnulables = [ComptageStatut.enCours, ComptageStatut.transmis, ComptageStatut.enAttente];
const statutsComptagesInvalides = [ComptageStatut.annule, ComptageStatut.rejete];

@Component({
  selector: 'app-bon-detail-comptage',
  templateUrl: './bon-detail-comptage.component.html',
  styleUrls: ['./bon-detail-comptage.component.scss']
})
export class BonDetailComptageComponent implements OnInit {
  private _bon: BonDto;
  get bon(): BonDto {
    return this._bon;
  }
  @Input()
  set bon(value: BonDto) {
    this._bon = value;
    if (value.id) {
      this.loadComptages();
    }
  }

  private _conditionnementTypeParDefaut: ConditionnementType;
  public get conditionnementTypeParDefaut(): ConditionnementType {
    return this._conditionnementTypeParDefaut;
  }
  @Input()
  public set conditionnementTypeParDefaut(value: ConditionnementType) {
    this._conditionnementTypeParDefaut = value;
    this.changerConditionnement(value);
  }

  @Input() bonSens: MouvementSens;
  @Input() userIsSource: boolean;
  @Input() userIsDestination: boolean;
  @Input() uniteDataSource: UniteDto[] = [];
  private _marchandiseLignes: MarchandiseLigne[] = [];
  get marchandiseLignes(): MarchandiseLigne[] {
    return this._marchandiseLignes;
  }
  @Input()
  set marchandiseLignes(value: MarchandiseLigne[]) {
    this._marchandiseLignes = value;
    if (value) {
      this.setDataSource();
    }
  }
  private _toggle;
  public get toggle() {
    return this._toggle;
  }
  @Input()
  public set toggle(value) {
    this.heightDataGrid = (value ? "calc(100vh - 274px)" : "calc(100vh - 381px)");
    this._toggle = value;
  }

  private _paquets: PaquetDto[] = [];
  public get paquets(): PaquetDto[] {
    return this._paquets;
  }
  @Input()
  public set paquets(value: PaquetDto[]) {
    this._paquets = value;
    if (!this.currentPaquet && value?.length > 0) {
      this.currentPaquet = value[0];
    }
  }

  private _showNoSerieInputs: boolean = false;
  get showNoSerieInputs(): boolean {
    return this._showNoSerieInputs;
  }
  @Input()
  set showNoSerieInputs(value: boolean) {
    this._showNoSerieInputs = value;
    this.showNumeroSerie = value && this.bon?.strategieComptage?.saisieNumeroSerie;
  }

  @Input() isModificationEnCours = false;
  @Input() canAfficherQuantitesTheoriquesSurUnBonInventaire = false;

  heightDataGrid: string;

  @Output() comptageChanged = new EventEmitter<boolean>();

  @ViewChild('scanTextBox') dxTextBoxScan: DxTextBoxComponent;

  get exportFileName() {
    return `comptages_${this.bon?.id}`;
  }

  comptages: ComptageDto[] = null;
  comptage: ComptageDto = null;
  comptageLignes: ComptageLigneDto[] = [];
  lignesParDefautIds = new Set<string>();
  isComptageEnCours = false;
  isComptageAnnulable = false;
  /**
   * Mode simple: Comptage web, maximum 2 comptages: 1 à la préparation et 1 à la réception
   */
  isModeSimple = null;
  /**
   * Mode avancé: Comptages avec terminaux mobiles
   */
  isModeAvance = null;

  //  Comprend des comptages qui ne sont pas au statut "annule" ou "rejete"
  hasComptagesNonAbandonnes = null;

  canDemarrerComptage = false;
  canValiderComptage = false;
  canReprendreComptage = false;
  canCreerComptage = false;

  get canAjouterArticle() {
    return this.isModeSimple && this.isComptageEnCours;
  }

  articlesToAddDataSource: RechercheDxArticleDto[] = [];
  articlesACompterDataSource: ArticleForComptageLigne[] = [];
  isLoading = false;
  popupMultiLignesVisible = false;
  popupAjoutArticlesVisible = false;
  popupEcartsQuantitesVisible = false;
  ecartDataSource: EcartsQuantiteLigneResultDto[] = [];
  ecartInattendusDataSource: EcartsQuantiteLigneResultDto[] = [];

  fluxStatutEnum = [
    {id: FluxStatut.closed, libelle: 'Fermer'},
    {id: FluxStatut.new, libelle: 'Nouveau'},
    {id: FluxStatut.opened, libelle: 'Ouvert'},
    {id: FluxStatut.paused, libelle: 'En attente'}
  ]

  get bonFluxStatutLibelle() {
    return this.fluxStatutEnum.find(x => x.id == this.bon?.fluxStatut)?.libelle;
  }

  /**
   * Scan
   */
  currentArticle: ArticleForComptageLigne;
  scanValue: string = null;
  isScanningNumeroSerie = false;
  shouldFocusScanAfterQteFocus = false;
  lastLigneWasScanned = false;

  private marchandiseLigneSelected: EventEmitter<MarchandiseLigne>;
  private scanArticleCache: {[key: string]: ArticleScanResultDto} = {};
  private currentRegistreSource: { articleId: number, numeroSeries: string[] } = { articleId: 0, numeroSeries: [] };
  private currentRegistreDestination : { articleId: number, numeroSeries: string[] } = { articleId: 0, numeroSeries: [] };

  /**
   * DataGrid
   */
  private _datagrid: DxDataGridComponent;
  get datagrid() { return this._datagrid }
  @ViewChild(DxDataGridComponent) set datagrid(value: DxDataGridComponent) {
    this._datagrid = value;
    const isGroupedString = sessionStorage.getItem(this.isGroupedStoredKey);
    if (isGroupedString != null) {
      // On inverse la condition pour être dans le même mode (groupé ou pas) stocké en mémoire
      this.toggleGrouping(!(isGroupedString == "true"));
    }
  }
  dataSource: ComptageMarchandiseLigne[] = [];
  currentLigne: ComptageMarchandiseLigne;
  showQuantitePrevue = false;
  showNumeroSerie = false;
  showNumeroColis = false;
  canEditNumeroColis = false;
  shouldAddRowOnContentReady = false;
  statutsDataSource: { id: ComptageStatut; libelle: string }[] = [];
  articleMultiZIndexItems: MarchandiseLigne[] = [];
  currentInputQuantite: HTMLInputElement;
  currentInputNumeroSerie: any;
  isGrouped = true;
  fluxStatut = FluxStatut;
  currentPaquet: PaquetDto = null;
  conditionnementTypeDataSource: { id: ConditionnementType, intitule: string}[] = [];
  selectedConditionnementType: ConditionnementType = ConditionnementType.base;

  comptagePopupVisible = false;
  comptageForPopup: ComptageDto;
  utilisateursDataSource: DataSource;
  listeNumerosComptage = [1, 2, 3, 4, 5];
  titlePopupComptage: string;
  showLegende = false;

  get comptageStatutIntitule(): string {
    return this.statutsDataSource.find((s) => s.id === this.comptage?.statut)?.libelle ?? '';
  }

  get comptageStoredKey(): string {
    return this.bon == null ? null : `Bon${this.bon.id}ComptageId`;
  }

  get isGroupedStoredKey(): string {
    return this.bon == null ? null : `Bon${this.bon.id}IsGrouped`;
  }

  constructor(
    private readonly comptagesLexiClient: ComptagesLexiClient,
    private readonly comptageLignesLexiClient: ComptageLignesLexiClient,
    private readonly authService: AuthService,
    private readonly referenceService: ReferenceService,
    private readonly articleLexiClient: ArticlesLexiClient,
    private readonly registresLexiClient: RegistresNumerosSerieLexiClient,
    private readonly conditionnementService: ConditionnementService,
    private readonly cd: ChangeDetectorRef,
    private readonly exportDataGridService: ExportDataGridService
  ) {
    this.conditionnementTypeDataSource = conditionnementService.getConditionnementTypes();
    this.loadUsers();
  }

  private async loadUsers() {
    this.utilisateursDataSource = await this.referenceService.getUtilisateursDataSource();
  }

  // TODO Différentes évolutions :
  // Affecter plusieurs lignes à 1 Colis
  // Boutons filtre sur les écarts + et -
  // Bonus Supprimer plusieurs lignes d'un coup
  // Voir pour permettre de modifier la stratégie sur le bon directement
  // Ajouter Ref Fournisseur dans Popup Ajout Article
  // Ajouter article depuis référence fournisseur
  // Permettre de changer de comptage (préparation/réception)

  ngOnInit() {
    this.statutsDataSource = this.referenceService.getStatusComptagePreparation();
  }

  async demarrerComptage() {
    if (this.bon.sens === MouvementSens.inventaire) {
      const isQuantitesInitialesNonNulles = this.marchandiseLignes.some(m => m.quantiteInitiale != 0);
      if (!isQuantitesInitialesNonNulles) {
        const confirmed = await confirm(
          `Toutes les quantités théoriques sont nulles, avez-vous fait l'image du stock ?
          Cliquer sur 'Oui' pour poursuivre
          `,
          `Demande de confirmation`
        ).catch(() => false); // catch nécessaire sinon erreur quand on appuie sur "échap"
        if (!confirmed) return;
      }
    }

    if (this.bon.strategieComptage?.activerMultiComptage) {
      this.showComptagePopup();
      return;
    }

    this.isLoading = true;
    try {
      await lastValueFrom(this.comptagesLexiClient.creerComptageParDefaut(this.bon.id));
    } finally {
      this.isLoading = false;
      this.loadComptages();
      this.comptageChanged.emit(true);
    }
  }

  async reprendreComptage() {
    if (!this.comptage) return;

    const confirmed = await confirm(`Le comptage sera de nouveau modifiable`, `Reprendre le comptage ?`);
    if (!confirmed) return;

    try {
      this.isLoading = true;
      await lastValueFrom(this.comptagesLexiClient.reprendreComptage(this.comptage.id));
      this.storeComptage();
      this.comptageChanged.emit(true);
    } finally {
      this.isLoading = false;
    }
  }

  async annulerComptage() {
    const confirmed = await confirm('Confirmer l\'annulation du comptage ?', 'Annuler le comptage').catch(() => false);
    if (!confirmed) {
      return;
    }
    this.isLoading = true;
    try {
      await lastValueFrom(this.comptagesLexiClient._delete(this.comptage.id, this.bon.id));
      this.notify(NotificationType.Success, `Le comptage a bien été annulé`);
      this.comptageChanged.emit(true);
    } finally {
      this.isLoading = false;
    }
  }

  async validerComptage(confirmerEcarts = false) {
    const confirmed = await confirm('Confirmer la validation du comptage ?', 'Valider le comptage').catch(() => false);
    if (!confirmed) {
      return;
    }

    if (!this.showQuantitePrevue) {
      // Comptage à l'aveugle :
      // Puisqu'on masque les quantités prévues, on doit aussi masquer les écarts et donc on valide directement le comptage
      confirmerEcarts = true;
    }

    this.isLoading = true;
    try {
      const result = await lastValueFrom(this.comptagesLexiClient.validerComptage(this.comptage.id, { confirmerEcarts }));
      if (result.anyEcart) {
        this.popupEcartsQuantitesVisible = true;
        this.ecartDataSource = result.lignes;
        this.ecartInattendusDataSource = result.lignesArticleInattendus;
      } else {
        this.notify(NotificationType.Success, `Le comptage a bien été validé`);
        this.storeComptage();
        this.comptageChanged.emit(true);
      }
    } finally {
      this.isLoading = false;
    }
  }

  onAjouterArticle(articles: RechercheDxArticleDto[]) {
    const article = articles[0];
    if (!article) return;

    this.addLigneByArticle({
      id: article.id,
      codeBo: article.codeBo,
      intitule: article.libelleLong,
      uniteBaseId: article.uniteBaseId,
      nombreNumeroSerie: article.nombreNumeroSerie
    });
  }

  onCurrentArticleChanged(e: ValueChangedEvent) {
    // e.value == null quand on clear le current article
    if (e.value == null) {
      return;
    }

    const article: ArticleForComptageLigne = this.articlesACompterDataSource.find(a => a.id === e.value);
    if (this.currentArticle?.id !== article?.id) {
      this.currentArticle = article;
      this.setScanningMode(article);
      this.dxTextBoxScan?.instance.focus();
    }
    if (article) {
      this.filtrerSurArticle(article, null);
    }
  }

  onClearCurrentArticle() {
    this.currentArticle = null;
    this.setScanningMode(null);
    this.clearFilter();
  }

  /**
   * Permettre de sélectionner l'article en cours : via click, via dropbox
   * Au scan, si scanValue match un des numSérie attendus, alors ajouter la ligne avec Qté=1 et le numSérie, sauvegarder la ligne
   * sinon si scanValue match un des code-barres/codeBo (requête serveur), alors
   *    si article est serialisé, alors ajouter ligne avec Qté=1, focus sur la colonne numSérie et passe en mode "scan numSérie"
   *    sinon ajouter la ligne avec Qté=1 et remettre le focus sur input Scan
   * sinon si scanValue = '', alors focus la colonne quantité
   * sinon si mode "scan numSérie", alors ajouter ligne avec Qté=1 et le numSérie, sauvegarder la ligne
   * sinon afficher alerte code-barres inconnu
   * permettre d'enchainer les scans (quand row saved, et qu'on vient d'un scan, focus input Scan)
   */
  async onScanArticle() {
    const value = this.scanValue?.trim()?.toUpperCase();

    // Focus input quantité
    if (value == null) {
      this.currentInputQuantite?.select();
      return;
    }

    this.scanValue = null; // vide l'input
    this.lastLigneWasScanned = true; // Indique qu'il faut focus la zone de scan après avoir inséré la nouvelle ligne

    // Recherche des n° série en doublon pour un même article
    if (this.dataSource.some(l => l.nombreNumeroSerie > 0 && l.numeroSerie === value && (this.currentArticle == null || l.articleId == this.currentArticle.id))) {
      await this.alertNumeroSerieDoublon(value);
      return;
    }

    // Mode scan numéro série
    if (this.isScanningNumeroSerie && this.currentArticle) {
      await this.setCurrentRegistres(this.currentArticle.id);
      await this.addLigneByArticle(this.currentArticle, 1, value);
      return;
    }

    // Recherche par code-barres > codeBo > n° de série
    else {
      const articleScanDto = await this.getArticleFromCacheOrFetch(value);
      if (articleScanDto) {
        const quantiteUB = await this.changerConditionnementSelonCodebarre(articleScanDto, value);
        const article = this.mapToArticleFromScanDto(articleScanDto);
        this.setScanningMode(article);
        if (this.isScanningNumeroSerie) {
          this.shouldFocusScanAfterQteFocus = true;
        }

        const numeroSerie = articleScanDto.isFoundByNumeroSerie ? value : null;
        await this.addLigneByArticle(article, quantiteUB, numeroSerie);

        return;
      }
    }

    await alert(`Aucun article trouvé pour le code ${value}`, `Code-barres inconnu`);
    this.dxTextBoxScan?.instance.focus();
  }

  private async getArticleFromCacheOrFetch(value: string): Promise<ArticleScanResultDto> {
    let cachedArticle = this.scanArticleCache[value];
    if (!cachedArticle) {
      // En Réception, on recherche uniquement parmi les codes-barres + CodeBo donc registreStockageId = null
      // En Préparation et Inventaire, on recherche en plus parmi le registre des n° série du lieu de stockage (Préparation = source, Inventaire = destination)
      const registreStockageId = this.bon.sens === MouvementSens.inventaire ? this.bon.destinationStockageId
        : this.userIsDestination ? null
        : this.bon.sourceStockageId
      ;

      // Recherche par Article.CodeBarres > Article.CodeBo
      const articles = await lastValueFrom(this.articleLexiClient.rechercherPourScanArticle(value, registreStockageId));
      if (articles?.length) {
        cachedArticle = articles[0];
        this.scanArticleCache[value] = cachedArticle;
      }
    }

    return cachedArticle;
  }

  onAjouterArticleSurLigne = (e) => {
    const l: ComptageMarchandiseLigne = e?.row?.data;
    if (l) {
      this.addLigneByArticle({
        id: l.articleId,
        codeBo: l.articleCodeBo,
        intitule: l.articleIntitule,
        uniteBaseId: l.uniteId,
        nombreNumeroSerie: l.nombreNumeroSerie
      });
    }
  }

  // Masque les zéros de la Quantité Prévue afin de ne pas surcharger visuellement l'écran
  customizeQtePrevue = (e) => {
    return this.isGrouped ? '' : e.value === 0 ? '' : e.valueText;
  }

  /**
   * Sélectionne la quantité afin de permettre de la remplacer
   * https://supportcenter.devexpress.com/ticket/details/t618554/datagrid-how-to-select-text-when-a-cell-is-in-an-editing-mode
   */
  onFocusQteComptee = (e) => {
    const input: HTMLInputElement = e?.element?.querySelector('.dx-texteditor-input');
    if (input != null) {
      this.currentInputQuantite = input;
    }

    if (this.shouldFocusScanAfterQteFocus) {
      // Permet d'enchaîner avec le scan de numéro de série
      this.shouldFocusScanAfterQteFocus = false;
    } else {
      input.select();
    }
  };

  setCurrentInputNumeroSerie = (e) => {
    this.currentInputNumeroSerie = e?.component;
  }

  onConditionnementTypeChanged = (e) => {
    if (e?.value) {
      this.changerConditionnement(e.value);
    }
  }

  private changerConditionnement(value: ConditionnementType) {
    this.selectedConditionnementType = value;
    this.dataSource?.forEach(ligne => {
      ligne.quantiteInitialeConvertie = this.convertirQuantite(ligne.quantiteInitiale, ligne);
      ligne.quantiteCompteeConvertie = this.convertirQuantite(ligne.quantiteComptee, ligne);
    });

    this.datagrid?.instance?.refresh();
  }

  private async changerConditionnementSelonCodebarre(articleScanDto: ArticleScanResultDto, codebarre: string): Promise<number> {
    const codebarreDto = articleScanDto.codesbarres.find(cb => cb.code?.toUpperCase() === codebarre?.toUpperCase());
    const conditionnementId = codebarreDto?.conditionnementId ?? null;

    let conditionnementType = ConditionnementType.base;
    if (conditionnementId != null && conditionnementId === articleScanDto.conditionnementAchatId) {
      conditionnementType = ConditionnementType.achat;
    } else if (conditionnementId != null && conditionnementId === articleScanDto.conditionnementVenteId) {
      conditionnementType = ConditionnementType.vente;
    }

    if (conditionnementType != this.selectedConditionnementType) {
      this.changerConditionnement(conditionnementType);
      const condiIntitule = this.conditionnementTypeDataSource.find(c => c.id === conditionnementType)?.intitule;
      this.notify(NotificationType.Success, `Conditionnement changé en ${condiIntitule} pour correspondre au code-barres scanné`);

      // Attend le refresh de la datagrid: fonctionne en attendant de trouver mieux
      // Sans ce délai :
      // - la quantité n'est pas focus (ou rarement)
      // - et/ou parfois la nouvelle ligne (suite au datagrid.addRow()) n'est pas visible (obligé de modifier le filtre pour enfin la voir)
      await new Promise((resolve) => setTimeout(resolve, 400));
    }

    return codebarreDto?.conditionnementCoefficient ?? 1;
  }

  onAddToGroup = (e) => {
    if (e.data?.items?.length) {
      // Crée un nouvel objet en le copiant, notamment car on modifie la quantite comptée
      const ligne: ComptageMarchandiseLigne = { ...e.data.items[0] };
      ligne.quantiteCompteeConvertie = 1;
      ligne.quantiteComptee = this.convertirQuantiteCompteeEnUB(ligne);
      ligne.numeroSerie = null;
      ligne.createdOn = (new Date()).toISOString();
      this.currentArticle = this.mapToArticle(ligne);
      this.addLigneToDatagrid(ligne);
    }
  };

  private addLigneToDatagrid = (ligne: ComptageMarchandiseLigne) => {
    const addedLignes = this.dataSource.filter(l => l.articleId === ligne.articleId);
    if (
      !this.showQuantitePrevue
      && (
        addedLignes?.length !== 1
        || this.comptageLignes.some(cl => cl.bonLigneId === ligne.bonLigneId)
        || addedLignes[0]?.quantiteComptee !== 0
      )
    ) {
      // Masque la quantité prévue (initiale) des lignes ajoutées
      ligne.quantiteInitiale = 0;
      ligne.quantiteInitialeConvertie = 0;
    }
    this.currentLigne = ligne; // utilisé dans onInitNewRow
    this.shouldAddRowOnContentReady = true;
    // Filtre sur l'article sélectionné afin de faciliter la saisie de la nouvelle ligne
    this.filtrerSurArticle(this.mapToArticle(ligne), ligne.zIndex);
  };

  /**
   * Filtre sur l'article donné
   * Déclenche onContentReady()
   */
  private filtrerSurArticle(article: ArticleForComptageLigne, zIndex: number) {
    this.filterColumnHeader('articleCodeBo', [article.codeBo]);
    this.filterColumnHeader('zIndex', [zIndex]);
    this.datagrid.instance.refresh();
  }

  /**
   * Déclenche onInitNewRow()
   * Utilisé pour ajouter automatiquement une ligne après filtrage (voir addLigneToDatagrid)
   * Cet événement est appelé quand la datagrid a terminé son "repaint" suite au filtrage
   * https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onContentReady
   */
  onContentReady(e) {
    if (this.shouldAddRowOnContentReady) {
      this.datagrid.instance.addRow();
      this.shouldAddRowOnContentReady = false;
    }
    this.isGrouped = this.datagrid?.instance.columnOption('articleIntitule', 'groupIndex') === 0;
  }

  /**
   * Initialise la ligne qui va être insérée dans la DataDrid
   * Appelé par this.datagrid.instance.addRow()
   */
  onInitNewRow(e) {
    const data: ComptageMarchandiseLigne = e.data;
    Object.getOwnPropertyNames(this.currentLigne).forEach(propName => {
      data[propName] = this.currentLigne[propName];
    });
    data.comptageLigneUniqueId = uuidV4();
    if (this.currentPaquet) {
      data.numeroColis = this.currentPaquet.numero;
    }
  }

  onEditCanceled(e) {
    this.datagrid.instance.refresh();
  }

  onEditorPreparing(e) {
    // Disable la colonne numeroSerie si l'article n'est pas sérialisable.
    if (e.parentType === 'dataRow' && e.dataField === 'numeroSerie') {
      const rowData: ComptageMarchandiseLigne = e.row?.data;
      if (rowData) {
        this.setCurrentRegistres(rowData.articleId);
        e.editorOptions.disabled = this.marchandiseLignes.find(ml => ml.articleId === rowData.articleId)?.nombreNumeroSerie === 0;
      }
    }
  }

  onRowPrepared(e) {
    // doc: https://js.devexpress.com/Documentation/Guide/UI_Components/DataGrid/Columns/Customize_Cells/#Customize_Row_Apperance
    if (!this.isGrouped && e?.rowType === 'data') {
      const el: HTMLElement = e.rowElement;
      const data: ComptageMarchandiseLigne = e.data;
      if (el && data && data.quantiteInitiale === 0 && !data.isInattendu) {
        // Quand les lignes sont dégroupées, on garde un semblant de groupe en utilisant le highlight
        // Toutes les lignes qui n'ont pas la quantité prévue sont en highlight
        // et pour faciliter la lecture de la quantité prévue, la ligne qui la contient est d'une autre couleur (blanc par défaut)
        // TODO : Ne fonctionne pas comme ça devrait
        // TODO : Une fois rétabli, décommentez la partie html qui affiche la légende dans la toolbar
        // el.classList.add('highlight');
      }
    }

    if (e?.isNewRow) {
      if (this.isScanningNumeroSerie) {
        if (e.data.numeroSerie) {
          this.datagrid.instance.saveEditData();
        } else {
          setTimeout(() => {
            const el = this.datagrid.instance.getCellElement(e.rowIndex, 'numeroSerie');
            this.datagrid.instance.focus(el);
          }, 10);
        }
      }
    }
  }

  onRowInserting = async (e) => {
    const data: ComptageMarchandiseLigne = e.data;

    if (data.nombreNumeroSerie > 0) {
      data.numeroSerie = data.numeroSerie?.trim()?.toUpperCase();

      if (this.showNoSerieInputs && data.numeroSerie == null || data.numeroSerie === "") {
        this.datagrid.instance.cancelEditData();
        e.cancel = true;
        alert(`Le numéro de série doit être renseigné.`, `N° Série obligatoire`);
        return;
      }

      // Vérification des N° Série en doublon
      if (this.hasErreurNumeroSerieDoublon(data, e)) {
        return;
      }

      // Vérification du N° Série par rapport au registre
      if (this.hasErreurNumeroSerieRegistre(data, e)) {
        return;
      }
    }

    data.quantiteComptee = this.convertirQuantiteCompteeEnUB(data);
    const dto: ComptageLigneDto = this.toComptageLigneDto(this.comptage.id, data);

    try {
      await lastValueFrom(this.comptageLignesLexiClient.create(dto));
    } catch (err) {
      e.cancel = true;
      console.error(`Erreur lors de l'enregistrement de la ligne`, err);
      this.notify( NotificationType.Error, `Erreur lors de l'enregistrement de la ligne`);
    }
  }

  onRowInserted = (e) => {
    // Supprime la ligne par défaut si une nouvelle ligne a été insérée par scan
    const data: ComptageMarchandiseLigne = e.data;
    const lignes = this.dataSource.filter(l => l.articleId === data.articleId && l.quantiteComptee === 0);
    if (lignes.length === 1 && this.lignesParDefautIds.has(lignes[0].comptageLigneUniqueId)) {
      const idx = this.dataSource.findIndex(l => l.articleId === data.articleId && l.quantiteComptee === 0);
      this.dataSource.splice(idx, 1);
      this.datagrid.instance.refresh();
    }

    // Focus sur la zone de scan
    if (this.lastLigneWasScanned) {
      this.lastLigneWasScanned = false;
      setTimeout(() => {
        this.dxTextBoxScan?.instance.focus();
      }, 10);
    }
  }

  async onRowUpdating(e) {
    const data: ComptageMarchandiseLigne = { ...e.oldData, ...e.newData };
    if (e.newData.quantiteCompteeConvertie != null) {
      // Uniquement si quantiteCompteeConvertie a été modifié
      data.quantiteComptee = this.convertirQuantiteCompteeEnUB(data);
    }

    if (data.nombreNumeroSerie > 0) {
      data.numeroSerie = data.numeroSerie?.trim()?.toUpperCase();

      if (e.newData.numeroSerie && e.oldData.numeroSerie != e.newData.numeroSerie) {
        // Vérification des N° Série en doublon
        if (this.hasErreurNumeroSerieDoublon(data, e)) {
          return;
        }

        // Vérification du N° Série par rapport au registre
        if (this.hasErreurNumeroSerieRegistre(data, e)) {
          return;
        }
      }
    }

    const dto: ComptageLigneDto = this.toComptageLigneDto(this.comptage.id, data);

    try {
      if (this.lignesParDefautIds.has(dto.id)) {
        // La ligne par défaut n'est pas encore persistée en BDD, donc on la crée
        await lastValueFrom(this.comptageLignesLexiClient.create(dto));
        this.lignesParDefautIds.delete(dto.id);
      } else {
        e.oldData.modifiedOn = dto.modifiedOn = (new Date()).toISOString();
        await lastValueFrom(this.comptageLignesLexiClient.update(dto.id, dto));
      }
    } catch (err) {
      e.cancel = true;
      console.error(`Erreur lors de l'enregistrement de la ligne`, err);
      this.notify(NotificationType.Error, `Erreur lors de l'enregistrement de la ligne`);
    }
  }

  async onRowRemoving(e) {

    try {
      const ligneToDelete: ComptageMarchandiseLigne = e.data;

      const lignesSameBonLigne = this.dataSource.filter(l => l.bonLigneId === ligneToDelete.bonLigneId);

      // Si on tente de supprimer la ligne par défaut et qu'il n'y a que cette ligne sur cet article, on ne fait rien
      if (this.lignesParDefautIds.has(ligneToDelete.comptageLigneUniqueId) && lignesSameBonLigne.length === 1) {
        e.cancel = true;
        this.notify(NotificationType.Info, `Cette ligne n'est pas supprimable`);
        return;
      }

      // Suppression côté serveur
      await lastValueFrom(this.comptageLignesLexiClient._delete(e.data.comptageLigneUniqueId));

      // Quand on supprime la ligne qui possède la QtéPrévue, on ne doit pas se retrouver visuellement avec QtéPrévue = 0;
      // Il faut remettre la QtéPrévue sur une des lignes restantes, la modification est uniquement graphique (pas de sauvegarde côté serveur)
      if (lignesSameBonLigne.length > 0 && ligneToDelete.quantiteInitiale > 0) {
        const ligneToUpdate = lignesSameBonLigne.find(l => l.quantiteInitiale === 0);
        if (ligneToUpdate) {
          ligneToUpdate.quantiteInitiale = ligneToDelete.quantiteInitiale;
        } else if (this.bon.strategieComptage?.afficherArticlesPrevus) {
          // Recréer une ligne par défaut
          const newId = uuidV4();
          this.dataSource.push({ ...ligneToDelete, comptageLigneUniqueId: newId, quantiteComptee: 0, quantiteCompteeConvertie: 0, commentaire: null, numeroColis: null, numeroSerie: null });
          this.lignesParDefautIds.add(newId);
        }
      }
    } catch (err) {
      e.cancel = true;
      console.error(`Erreur lors de la suppression de la ligne`, err);
      this.notify( NotificationType.Error, `Erreur lors de la suppression de la ligne`);
    }
  }

  validerQuantiteComptee = (e: { value: number, data: ComptageMarchandiseLigne }) => {
    const valide = e.data.nombreNumeroSerie < 1 || e.value <= 1;
    return valide;
  }

  quantiteComparisonTarget() {
    return 0;
  }

  toggleGrouping(isGrouped: boolean) {
    if (this.datagrid == null) {
      return;
    }

    this.isGrouped = !isGrouped;
    if (this.isGrouped) {
      this.datagrid.instance.columnOption('articleIntitule', 'groupIndex', 0);
    } else {
      this.datagrid.instance.clearGrouping();
    }
    sessionStorage.setItem(this.isGroupedStoredKey, this.isGrouped.toString());
    this.cd.detectChanges();
  }

  articleDisplayExpr(article: ArticleForComptageLigne) {
    return article && `${article.codeBo} - ${article.intitule}`;
  }

  annulerMultiLigne() {
    this.marchandiseLigneSelected.emit(null);
    this.marchandiseLigneSelected.complete();
    this.popupMultiLignesVisible = false;
  }

  confirmerMultiLigne(ml: MarchandiseLigne) {
    this.marchandiseLigneSelected.emit(ml);
    this.marchandiseLigneSelected.complete();
    this.popupMultiLignesVisible = false;
  }

  paquetDisplayExpr(p: PaquetDto) {
    return `N° ${p?.numero ?? 'Colis'}`;
  }

  calculateUniteValue = (ligne: ComptageMarchandiseLigne) => {
    return this.conditionnementService.definirUnite(this.selectedConditionnementType, ligne);
  }

  private convertirQuantite(quantite: number, ligne: ComptageMarchandiseLigne | MarchandiseLigne) {
    return this.conditionnementService.convertirQuantiteConditionnee(this.selectedConditionnementType, quantite, ligne);
  }

  private convertirQuantiteCompteeEnUB(data: ComptageMarchandiseLigne) {
    return this.conditionnementService.convertirQuantiteUB(this.selectedConditionnementType, data.quantiteCompteeConvertie, data);
  }

  private clearFilter() {
    this.datagrid.instance.clearFilter();
  }

  private async setScanningMode(article: ArticleForComptageLigne) {
    this.isScanningNumeroSerie = article && article.nombreNumeroSerie > 0;
    if (article) {
      await this.setCurrentRegistres(article.id);
    }
  }

  private async getRegistre(articleId: number, stockageId: number) {
    try {
      const numeroSeries: string[] = this.bon?.strategieComptage.saisieNumeroSerie
        ? await lastValueFrom(this.registresLexiClient.getByArticleIdEtLieuStockageId(articleId, stockageId))
        : [];
      return {articleId: articleId, numeroSeries};
    }
    catch {
      return {articleId: articleId, numeroSeries: []};
    }
  }

  private async setCurrentRegistres(articleId: number) {
    // On exclut l'inventaire pour ne pas récupérer les n° de série du stockage de régule
    if (this.userIsSource && this.bonSens !== MouvementSens.inventaire) {
      if (this.currentRegistreSource.articleId != articleId) {
        this.currentRegistreSource = await this.getRegistre(articleId, this.bon.sourceStockageId);
      }
    }
    if (this.userIsDestination) {
      if (this.currentRegistreDestination.articleId != articleId) {
        this.currentRegistreDestination = await this.getRegistre(articleId, this.bon.destinationStockageId);
      }
    }
  }

  private async addLigneByArticle(article: ArticleForComptageLigne, quantiteUniteBase = 1, numeroSerie: string = null) {
    if (!this.canAjouterArticle) {
      return;
    }

    let ligne: ComptageMarchandiseLigne = null;

    const sameArticleLignes = this.marchandiseLignes.filter((ml) => ml.articleId === article.id);

    if (sameArticleLignes.length === 0) {
      // Article inattendu, demande confirmation selon stratégie
      ligne = await this.getLigneForArticleToAdd(article, quantiteUniteBase);
      if (ligne && !this.articlesACompterDataSource.some(a => a.id === article.id)) {
        this.articlesACompterDataSource.push(article);
      }
    } else if (sameArticleLignes.length === 1) {
      // Article attendu, on crée la ligne et on l'ajoute
      ligne = this.mapFromMarchandiseLigne(sameArticleLignes[0], { quantite: quantiteUniteBase });
    } else {
      // Article attendu mais plusieurs lignes de marchandises sur différents zIndex (sameArticleLignes.length > 1)
      // Sélection parmi les marchandiseLignes
      ligne = await this.choisirArticleMultiZindex(sameArticleLignes, quantiteUniteBase);
    }

    if (ligne) {
      this.currentArticle = article;
      if (numeroSerie) {
        ligne.numeroSerie = numeroSerie;
      }
      ligne.createdOn = (new Date()).toISOString();
      this.addLigneToDatagrid(ligne);
    }
  }

  /**
   * Sélection parmi une liste d'un même article ayant différents zIndex
   * Utilise un EventEmitter pour attendre le résultat de la popup
   */
  private async choisirArticleMultiZindex(sameArticleLignes: MarchandiseLigne[], quantiteUniteBase: number): Promise<ComptageMarchandiseLigne> {
    this.marchandiseLigneSelected?.complete();
    this.marchandiseLigneSelected = new EventEmitter<MarchandiseLigne>();
    this.articleMultiZIndexItems = sameArticleLignes.map(l => (
      {
        ...l,
        quantiteInitialeConvertie: this.convertirQuantite(l.quantiteInitiale, l),
        uniteId: this.conditionnementService.definirUnite(this.selectedConditionnementType, l)
      }
    ));
    this.popupMultiLignesVisible = true;

    const selectedLigne: MarchandiseLigne = await lastValueFrom(this.marchandiseLigneSelected.asObservable());
    if (selectedLigne) {
      return this.mapFromMarchandiseLigne(selectedLigne, { quantite: quantiteUniteBase });
    }
    return null;
  }

  /**
   * Article inattendu, demande confirmation selon stratégie
   */
  private async getLigneForArticleToAdd(article: ArticleForComptageLigne, quantiteUniteBase: number) {
    // On peut arriver ici avec ou sans ligne de marchandise (l'attendu), donc revérifie si c'est nécessaire de demander confirmation
    // note: Le cas sans ligne de marchandise attendue est celui d'un Inventaire par zone
    const isInattendu = this.marchandiseLignes.length > 0;

    if (isInattendu && this.bon?.strategieComptage?.ajoutArticle === ControleType.interdire) {
      await alert(`Impossible d'ajouter un article inattendu sur ce Bon`, `Article inattendu`);
      if (this.lastLigneWasScanned) this.dxTextBoxScan?.instance.focus();
      return null;
    }

    if (isInattendu
      && this.bon?.strategieComptage?.ajoutArticle === ControleType.alerter
      // Vérifie si cet article inattendu a déjà été compté, auquel cas on ne réaffiche pas l'alerte
      && !this.dataSource.some(cl => cl.articleId === article.id)) {
      const confirmed = await confirm(
        `L'article ${article.intitule} [${article.codeBo}] ne figure pas dans la liste des marchandises attendues.
          Souhaitez-vous compter cet article ?
        `,
        `Article inattendu`
      ).catch(() => false); // catch nécessaire sinon erreur quand on appui "échap"

      if (!confirmed) {
        return null;
      }
    }

    const ligne = this.mapFromArticle(article, isInattendu);
    ligne.quantiteComptee = quantiteUniteBase;
    ligne.quantiteCompteeConvertie = this.convertirQuantite(quantiteUniteBase, ligne);
    return ligne;
  }

  async loadComptages(comptageId?: string) {
    this.isLoading = true;
    try {
      const comptages = await lastValueFrom(this.comptagesLexiClient.getAll(this.bon.id));
      // sort by Numero DESC
      comptages.sort((a, b) => b.numero - a.numero);
      // set d'une propriété custom statutIntitule
      comptages.forEach(x => (x as any).statutIntitule = this.getStatutIntitule(x));
      this.comptages = comptages;
      comptageId ??= sessionStorage.getItem(this.comptageStoredKey);
      this.comptage = comptageId ? comptages.find(x => x.id == comptageId) ?? comptages[0]
        : this.authService.securityUser != null ? comptages.find(x => x.utilisateurAffecteId == this.authService.securityUser.id) ?? comptages[0]
        : comptages[0];

      this.hasComptagesNonAbandonnes = (this.comptages?.length ?? 0) > 0
                                    && this.comptages.some(c => !statutsComptagesInvalides.includes(c.statut));
      var autoriserComptageTerminal = this.bon.strategieComptage?.autoriserComptageTerminal;
      this.isModeSimple = autoriserComptageTerminal === false;
      this.isModeAvance = autoriserComptageTerminal === true;

      const isGroupedString = sessionStorage.getItem(this.isGroupedStoredKey);
      if (isGroupedString != null && this.datagrid != null) {
        // On inverse la condition pour être dans le même mode (groupé ou pas) stocké en mémoire
        this.toggleGrouping(!(isGroupedString == "true"));
      }
    } finally {
      this.isLoading = false;
    }

    if (this.comptages) {
      await this.initVueComptage();
    }
  }

  private async initVueComptage() {
    if (!this.bon.strategieComptage) {
      if (this.comptage) {
        // Affiche ce message uniquement si on a un comptage.
        this.notify(
          NotificationType.Error,
          `Aucune stratégie de comptage sur ce bon. Veuillez en paramétrer une.`,
        );
      }
      return;
    }

    this.showQuantitePrevue = this.bon.strategieComptage.afficherQuantitesPrevues && this.canAfficherQuantitesTheoriquesSurUnBonInventaire;
    this.showNumeroSerie = this.showNoSerieInputs && this.bon.strategieComptage.saisieNumeroSerie;
    this.showNumeroColis = this.bon.strategieComptage.saisieNumeroColis;

    // Mode simple: Charge les lignes s'il y a un comptage
    if (this.isModeSimple && this.comptage) {
      this.comptageLignes = await lastValueFrom(this.comptageLignesLexiClient.getByComptageUniqueId(this.comptage.id));
      await this.setDataSource();
    }

    // Mode simple : dernier comptage actif ; Mode avancée : au moins 1 comptage actif
    // actif = ComptageStatut enCours ou transmis
    this.isComptageEnCours = this.isModeSimple
      ? this.comptage && statutsComptagesEnCours.includes(this.comptage.statut)
      : this.comptages.some((c) => statutsComptagesEnCours.includes(c.statut));

    this.isComptageAnnulable = this.isModeSimple
      ? this.comptage && statutsComptagesAnnulables.includes(this.comptage.statut)
      : this.comptages.some((c) => statutsComptagesAnnulables.includes(c.statut));

    this.canCreerComptage = this.authService.securityUserisGrantedWith(Permissions.canCreerComptage);
    this.canDemarrerComptage =
      this.isModeSimple
      && this.bon.etapeAction === EtapeAction.compter
      && (this.comptages.length === 0 ||
        // on peut créer le 2ème comptage uniquement si on est à la réception et suite à un comptage de préparation
        (this.comptages.length === 1
          && this.bon.sens != MouvementSens.inventaire
          && this.comptage.partenaireId === this.bon.partenaireSourceId
          && this.userIsDestination));

    this.canValiderComptage = this.authService.securityUserisGrantedWith(Permissions.canValiderComptage);
    this.canReprendreComptage = this.isModeSimple
          && this.bon.etapeAction === EtapeAction.compter
          && this.canValiderComptage
          && (this.comptage?.statut === ComptageStatut.valide || this.comptage?.statut === ComptageStatut.transmis)
          && (this.bon.fluxStatut === FluxStatut.opened || this.bon.fluxStatut === FluxStatut.paused);
    this.canEditNumeroColis = !this.userIsDestination && this.bon.sens != MouvementSens.inventaire;
  }

  private async setDataSource() {
    if (!this.bon.strategieComptage) {
      return;
    }

    let lignes: ComptageMarchandiseLigne[] = [];
    if (!this.bon.strategieComptage.afficherArticlesPrevus) {
      // Créer à partir des comptageLignes
      for (const cl of this.comptageLignes) {
        const l = this.mapFromComptageLigne(cl);

        // On alimente la quantité initiale si l'article est prévu
        if (this.showQuantitePrevue) {
          const sameArticleLignes = this.marchandiseLignes.filter((ml) => ml.articleId === cl.articleId);
          if (sameArticleLignes.length > 0) {
            if (sameArticleLignes.length == 1) {
              l.quantiteInitiale = sameArticleLignes[0].quantiteInitiale;
              l.quantiteInitialeConvertie = sameArticleLignes[0].quantiteInitialeConvertie;
            }
            else {
              var ligneMappedFromMarchandiseLigne = await this.choisirArticleMultiZindex(sameArticleLignes, 1);
              if (ligneMappedFromMarchandiseLigne != null) {
                l.quantiteInitiale = ligneMappedFromMarchandiseLigne.quantiteInitiale;
                l.quantiteInitialeConvertie = ligneMappedFromMarchandiseLigne.quantiteInitialeConvertie;
              }
            }
          }
        }

        lignes.push(l);
      }
    } else {
      // Créer à partir des marchandiseLignes
      lignes = this.marchandiseLignes.flatMap((ml) => {
        const comptageLignes = this.comptageLignes.filter((cl) => ml.bonLigneId === cl.bonLigneId);
        if (comptageLignes.length === 0) {
          const defautId = uuidV4();
          this.lignesParDefautIds.add(defautId);
          comptageLignes.push({ quantite: 0, id: defautId });
        }

        return comptageLignes.map((cl, index) => {
          const l = this.mapFromMarchandiseLigne(ml, cl);
          // Affiche la quantité prévue (initiale) uniquement sur la 1ère ligne de comptage.
          // C'est nécessaire quand les lignes sont éclatées (pas regroupée par article) afin de ne pas perturber l'utilisateur
          if (index > 0) {
            l.quantiteInitiale = 0;
            l.quantiteInitialeConvertie = 0;
          }
          return l;
        });
      });

      // Une ligne inattendue n'est pas liée à une ligne du Bon.
      const lignesInattendues = this.comptageLignes.filter(cl => !cl.bonLigneId).map(cl => this.mapFromComptageLigne(cl, true));
      lignes.push(...lignesInattendues);
    }

    this.dataSource = lignes;
    this.articlesACompterDataSource = this.makeArticleACompterDataSource(lignes);
    this.articlesToAddDataSource = this.makeArticleToAddDataSource(this.marchandiseLignes, this.bon.strategieComptage.ajoutArticle);
    this.cd.detectChanges();
  }

  private makeArticleToAddDataSource(marchandiseLignes: MarchandiseLigne[], strategieAjoutArticle: ControleType) {
    // Lorsqu'on interdit l'ajout d'article inattendu, on permet d'ajouter uniquement les articles attendus
    if (strategieAjoutArticle === ControleType.interdire) {
      return marchandiseLignes.map(ml => ({
        id: ml.articleId,
        codeBo: ml.articleCodeBo,
        libelleLong: ml.articleIntitule
      }));
    }

    return [];
  }

  private makeArticleACompterDataSource(lignes: ComptageMarchandiseLigne[]) {
    const articleIds = new Set();
    const aCompter = [];
    for (const l of lignes) {
      if (articleIds.has(l.articleId))
        continue;

      articleIds.add(l.articleId);
      aCompter.push(this.mapToArticle(l));
    }

    return aCompter;
  }

  private filterColumnHeader(column: string, values: any) {
    this.datagrid.instance.columnOption(column, 'filterType', 'include');
    this.datagrid.instance.columnOption(column, 'filterValues', values);
  }

  private hasErreurNumeroSerieDoublon(data: ComptageMarchandiseLigne, e: any): boolean {
    if (this.dataSource.some(l => l.nombreNumeroSerie > 0 && l.numeroSerie === data.numeroSerie && (this.currentArticle == null || l.articleId == this.currentArticle.id))) {
      e.cancel = true;
      // ferme la nouvelle ligne afin de pouvoir continuer de scanner
      this.datagrid.instance.cancelEditData();
      this.alertNumeroSerieDoublon(data.numeroSerie);
      return true;
    }
    return false;
  }

  private hasErreurNumeroSerieRegistre(data: ComptageMarchandiseLigne, e: any): boolean {
    // Mise à jour des registres si nécessaire
    if (this.currentRegistreSource.articleId !== data.articleId
      || this.currentRegistreDestination.articleId !== data.articleId)
    {
      this.setCurrentRegistres(data.articleId);
    }

    // Vérification uniquement si ce n'est pas un inventaire et article sérialisable
    if (
      data.nombreNumeroSerie > 0
      && data.numeroSerie
      && this.bon.sens !== MouvementSens.inventaire
    )
    {
      // Cas : Réception
      if (this.userIsDestination) {
        if (this.currentRegistreDestination.numeroSeries.includes(data.numeroSerie)) {
          this.datagrid.instance.cancelEditData();
          e.cancel = true;
          this.alertNumeroSerieRegistre(data.numeroSerie, 'present');
          return true;
        }
      }

      // Cas : Préparation
      if (this.userIsSource) {
        if (!this.currentRegistreSource.numeroSeries.includes(data.numeroSerie)) {
          this.datagrid.instance.cancelEditData();
          e.cancel = true;
          this.alertNumeroSerieRegistre(data.numeroSerie, 'absent');
          return true;
        }
      }
    }

    return false;
  }

  private notify(type: NotificationType, message: string, duree: number = 4000) {
    notify({ closeOnClick: true, message }, type, duree);
  }

  private async alertNumeroSerieDoublon(noSerie: string) {
    await alert(`Le N° Série ${noSerie} a déjà été compté.`, `N° Série en doublon`);
    if (this.lastLigneWasScanned) this.dxTextBoxScan?.instance.focus();
  }

  private async alertNumeroSerieRegistre(noSerie: string, causeErreur: 'absent'|'present') {
    let coeurMessage: string;
    if (causeErreur == 'present') {
      coeurMessage = "existe déjà dans le registre de destination";
    }
    else if (causeErreur == 'absent') {
      coeurMessage = "n'existe pas dans le registre source";
    }
    await alert(`Le N° Série ${noSerie} ${coeurMessage}.`, `Alerte registre N° Série`);
    if (this.lastLigneWasScanned) this.dxTextBoxScan?.instance.focus();
  }

  private mapFromComptageLigne(cl: ComptageLigneDto, isInattendu = false): ComptageMarchandiseLigne {
    const ligne = {
      comptageLigneUniqueId: cl.id,
      articleId: cl.articleId,
      articleCodeBo: cl.articleCodeBo,
      articleIntitule: cl.articleIntitule,
      quantiteComptee: cl.quantite,
      zIndex: cl.zIndex,
      commentaire: cl.commentaire,
      numeroSerie: cl.numeroSerie,
      numeroColis: cl.numeroColis,
      uniteId: cl.uniteBaseId,
      bonLigneId: cl.bonLigneId,
      createdOn: cl.createdOn,
      modifiedOn: cl.modifiedOn,
      isInattendu
    } as ComptageMarchandiseLigne;

    ligne.quantiteCompteeConvertie = this.convertirQuantite(ligne.quantiteComptee, ligne);

    return ligne;
  }

  private mapFromMarchandiseLigne(ml: MarchandiseLigne, cl: ComptageLigneDto): ComptageMarchandiseLigne {
    const ligne = {
      ...ml,
      comptageLigneUniqueId: cl.id,
      quantiteComptee: cl.quantite,
      numeroSerie: cl.numeroSerie,
      numeroColis: cl.numeroColis,
      commentaire: cl.commentaire,
      createdOn: cl.createdOn,
      modifiedOn: cl.modifiedOn,
      isInattendu: false
    } as ComptageMarchandiseLigne;

    ligne.quantiteCompteeConvertie = this.convertirQuantite(ligne.quantiteComptee, ligne);

    return ligne;
  }

  private mapFromArticle(article: ArticleForComptageLigne, isInattendu: boolean): ComptageMarchandiseLigne {
    const zIndex = Math.max(...this.marchandiseLignes.map((ml) => ml.zIndex), ...this.comptageLignes.map((cl) => cl.zIndex)) + 1;
    return {
      articleId: article.id,
      articleCodeBo: article.codeBo,
      articleIntitule: article.intitule,
      uniteId: article.uniteBaseId,
      zIndex,
      commentaire: null,
      numeroSerie: null,
      numeroColis: null,
      bonLigneId: null,
      isInattendu,
    } as ComptageMarchandiseLigne;
  }

  private mapToArticle(l: ComptageMarchandiseLigne): ArticleForComptageLigne {
    return {
      id: l.articleId,
      codeBo: l.articleCodeBo,
      intitule: l.articleIntitule,
      uniteBaseId: l.uniteId,
      nombreNumeroSerie: l.nombreNumeroSerie
    }
  }

  private mapToArticleFromScanDto(dto: ArticleScanResultDto): ArticleForComptageLigne {
    return {
      id: dto.id,
      codeBo: dto.codeBo,
      intitule: dto.libelleLong,
      uniteBaseId: dto.uniteBaseId,
      nombreNumeroSerie: dto.nombreNumeroSerie
    };
  }

  private toComptageLigneDto(comptageId: string, data: ComptageMarchandiseLigne): ComptageLigneDto {
    return {
      id: data.comptageLigneUniqueId,
      comptageId,
      articleId: data.articleId,
      zIndex: data.zIndex,
      quantite: data.quantiteComptee,
      numeroSerie: data.numeroSerie,
      numeroColis: data.numeroColis,
      commentaire: data.commentaire,
      bonLigneId: data.bonLigneId,
      createdOn: data.createdOn
    };
  }

  showComptagePopup = (comptageId?: string) => {
    // Création d'un comptage
    if (comptageId == null) {
      this.titlePopupComptage = `Création d'un comptage`;
      const numeroComptage = 1;
      const nombreComptages = (this.comptages?.length ?? 0) + 1;
      this.comptageForPopup = {
        numero: numeroComptage,
        intitule: `Comptage ${nombreComptages}` ,
        statut: ComptageStatut.enCours,
        utilisateurAffecteId: this.authService.authenticatedUser?.id,
      };
    }

    // Modification d'un comptage
    else {
      this.titlePopupComptage = `Modification d'un comptage`;
      this.comptageForPopup = this.comptages.find(x => x.id == comptageId) ?? this.comptage;
    }

    this.comptagePopupVisible = true;
  }

  hideComptagePopup = () => {
    this.comptagePopupVisible = false;
    this.comptageForPopup = null;
  }

  createOrUpdateComptage = async () => {
    try {
      this.isLoading = true;
      // Création
      if (this.comptageForPopup.id == null) {
        this.comptage = await lastValueFrom(this.comptagesLexiClient.creerComptage(this.comptageForPopup, this.bon.id));
        notify({closeOnClick: true, message: `Comptage ${this.comptage.intitule} créé`}, NotificationType.Success);
      }
      // Modification
      else {
        this.comptage = await lastValueFrom(this.comptagesLexiClient.updateComptage(this.comptageForPopup));
        notify({closeOnClick: true, message: `Comptage ${this.comptage.intitule} modifiée avec succès`}, NotificationType.Success);
      }
      this.hideComptagePopup();
    } finally {
      this.isLoading = false;
      this.loadComptages(this.comptage.id);
    }
  }

  async onChangementComptage(comptage: ComptageDto) {
    if (this.comptage?.id != comptage.id) {
      await this.loadComptages(comptage.id);
      this.storeComptage();
    }
  }

  getStatutIntitule(comptage: ComptageDto) {
    return this.statutsDataSource.find((s) => s.id === comptage?.statut)?.libelle ?? '';
  }

  storeComptage() {
    if (
      this.comptage != null
      && this.bon?.strategieComptage?.activerMultiComptage
      && this.comptages?.some(x => x.id != this.comptage.id)
    ) {
      sessionStorage.setItem(this.comptageStoredKey, this.comptage.id);
    }
    else {
      sessionStorage.removeItem(this.comptageStoredKey);
    }
  }

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}

export interface ComptageMarchandiseLigne extends MarchandiseLigne {
  comptageLigneUniqueId: string;
  quantiteComptee: number;
  quantiteCompteeConvertie: number;
  numeroSerie: string;
  numeroColis: number;
  commentaire: string;
  isInattendu: boolean;
  createdOn?: string;
  modifiedOn?: string;
}

interface ArticleForComptageLigne {
  id: number;
  codeBo: string;
  intitule: string;
  uniteBaseId: number;
  nombreNumeroSerie: number;
}
