import { Component, OnInit } from "@angular/core";
import notify from "devextreme/ui/notify";
import { NotificationType } from "../../modules/shared/references/references";
import { lastValueFrom } from "rxjs";
import { PersonnalisationGrillesLexiClient } from "@lexi-clients/lexi";
import { ThemeService } from "../../services/theme.service";
import { FeatureFlags, FeatureFlagService } from "../../shared/services/feature-flag.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

    themeList: any;
    selectBoxOptions: any;
    selectedThemeId: any;
    featureDisponible: FeatureFlags[] = [
      FeatureFlags.VOIR_BONS_SITES_PERMIS,
      FeatureFlags.IMPORTER_LIGNES_BON_CSV
    ];

    constructor(private readonly personnalisationGrilles: PersonnalisationGrillesLexiClient, private readonly themeService: ThemeService, private readonly featureTagService: FeatureFlagService) {

    }

    ngOnInit() {
        this.selectedThemeId = this.themeService.currentThemeId;
        this.themeList = this.themeService.getThemes();
    }

    onThemeChange(event) {
        if(event.value) {
            this.themeService.setAppThemeByThemeId(event.value);
        }
    }

    async onSupprimerStateStorage() {
        await lastValueFrom(this.personnalisationGrilles.resetAllForCurrentUser());

        const keywords = ['grille', 'DataGrid', 'treeList'];

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && keywords.some(keyword => key.includes(keyword))) {
                localStorage.removeItem(key);
            }
        }

        notify({closeOnClick: true, message: "Les préférences de grilles ont été supprimées avec succès"}, NotificationType.Success);
    }

  updateFeatureTags(feature: FeatureFlags, e: any) {
    this.featureTagService.updateFeatureTag(feature, e.value);
  }

  isFeatureDisponible(feature: FeatureFlags): boolean {
    return this.featureTagService.isFeatureEnabled(feature);
  }
}
