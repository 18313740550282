import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { ClassificationValueDto } from '@lexi-clients/lexi';
import { ClassificationValueListResult } from '../models/classification-value-list';

@Injectable({
  providedIn: 'root'
})
export class ClassificationValueService implements IGridService<ClassificationValueDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<ClassificationValueListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/ClassificationValues/recherche-devexpress`, { params: params });
  }

  getById(id: number): Observable<ClassificationValueDto> {
    return this.http.get<any>(`${this.baseUrl}/api/ClassificationValues/${id}`);
  }
  
  post(dto: ClassificationValueDto): Observable<ClassificationValueDto> {
    return this.http.post<ClassificationValueDto>(`${this.baseUrl}/api/ClassificationValues`, dto);
  }
  
  put(dto: ClassificationValueDto): Observable<ClassificationValueDto> {
    return this.http.put<ClassificationValueDto>(`${this.baseUrl}/api/ClassificationValues/${dto.id}`, dto);
  }

  patch(id: number, dto: ClassificationValueDto): Observable<ClassificationValueDto> {
    return this.http.patch<ClassificationValueDto>(`${this.baseUrl}/api/ClassificationValues/${id}`, dto);
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/api/ClassificationValues/${id}`);
  }
}
