<dx-data-grid
    [dataSource]="codesBarres"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    (onRowInserting)="onRowInserting($event)"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowRemoving)="onRowRemoving($event)"
    (onKeyDown)="onKeyDown($event)"
>
    <!-- Toolbar -->
    <dxo-toolbar>
        <dxi-item>
            <div *dxTemplate>
                <dx-button icon="refresh" hint="Rafraîchir" (onClick)="load()" stylingMode="text"></dx-button>
            </div>
        </dxi-item>
        <dxi-item name="addRowButton"></dxi-item>
    </dxo-toolbar>

    <!-- Options -->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
        <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-editing mode="form" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"></dxo-editing>

    <!-- Colonnes -->
    <dxi-column dataField="code" caption="Code-barres" width="40%">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="conditionnementId" caption="Conditionnement">
        <dxo-lookup [dataSource]="conditionnements" valueExpr="id" displayExpr="libelle"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column cellTemplate="cellCoefficient" caption="Colisage" [allowEditing]="false" [width]="120"></dxi-column>
    <dxi-column cellTemplate="cellUniteBase" caption="Unité de base" [allowEditing]="false"></dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'cellCoefficient'">
        {{ cell.data.conditionnement?.coefficient ?? 1 }}
    </div>

    <div *dxTemplate="let cell of 'cellUniteBase'">
        <span *ngIf="uniteBase">{{ uniteBase.intitule }} ( {{ uniteBase.codeBo }} )</span>
    </div>
</dx-data-grid>
