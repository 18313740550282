import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { SuffixeValeurListDto } from '@lexi-clients/lexi';
import { SuffixeValeurListResult } from '../models/suffixe-valeur-list';

@Injectable({
  providedIn: 'root'
})
export class SuffixeValeurListService implements IGridService<SuffixeValeurListDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<SuffixeValeurListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/SuffixeValeurs/recherche-devexpress`, { params: params });
  }

  getById(suffixeValeurId: number): Observable<SuffixeValeurListDto> {
    return this.http.get<any>(`${this.baseUrl}/api/SuffixeValeurs/${suffixeValeurId}`);
  }

  post(valeur: SuffixeValeurListDto): Observable<any> {
    return null;
  }

  put(valeur: SuffixeValeurListDto): Observable<any> {
    return null;
  }

  delete(valeur: SuffixeValeurListDto): Observable<any> {
    return null;
  }
}
