import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, concatMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationHeaderHttpInterceptor implements HttpInterceptor {
  constructor(private readonly oidcSecurityService: OidcSecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oidcSecurityService.getConfigurations().length > 0) {
      return this.oidcSecurityService.getAccessToken().pipe(
        concatMap(token => {
          if (token) { // Vérifier que le token n'est pas null
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              }
            });
          }
          return next.handle(req);
        })
      )
    }
    return next.handle(req);
  }
}
