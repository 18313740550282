<ng-template header-host></ng-template>

<dx-drawer class="layout-body" position="before" [closeOnOutsideClick]="shaderEnabled" [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode" [minSize]="minMenuSize" [shading]="shaderEnabled" [maxSize]="250"
  [(opened)]="menuOpened">

  <app-side-navigation-menu [compactMode]="!menuOpened" [selectedItem]="selectedRoute"
    class="dx-swatch-additional"
    *dxTemplate="let dataMenu of 'panel'" (selectedItemChanged)="navigationChanged($event)"
    (openMenu)="navigationClick()">
    <app-footer>
      <img src="assets/images/Lexi_Frise1_Bleu.png" style="padding-right: 14px;" width="100%" height="" alt="Logo Lexi"/>
    </app-footer>
  </app-side-navigation-menu>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</dx-drawer>