import { Directive, HostListener, Input } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation-service";

@Directive({
  selector: "[backButton]",
})
export class BackButtonDirective {
  @Input() fallbackRoute: string;

  constructor(private navigation: NavigationService) {}

  @HostListener("click")
  onClick(): void {
    this.navigation.back(this.fallbackRoute);
  }
}