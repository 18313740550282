<dx-data-grid
  id="gridContainerDetailOC"
  [dataSource]="dataSource"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [height]="dataGridHeight"
  (onEditingStart)="onEditingStart($event)"
  (onEditCanceled)="onEditCanceled()"
  (onKeyDown)="onKeyDown($event)"
  [masterDetail]="{ enabled: true, template: 'details' }"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before" name="addRowButton"></dxi-item>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="download" hint="Télécharger le modèle" stylingMode="text" (onClick)="getModeleExcel()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="upload" hint="Importer des données" stylingMode="text" (onClick)="showImportPopup = true;"></dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-editing mode="popup" [allowAdding]="!clientId && !articleId" [allowUpdating]="!clientId && !articleId" [allowDeleting]="!clientId && !articleId">
    <dxo-popup
      title="Détail d'une opération commerciale"
      [showTitle]="true"
      [width]="700"
      [height]="500"
      [showCloseButton]="true"
    ></dxo-popup>
      <dxo-form>
        <dxi-item dataField="codeBo" [label]="{text: 'Code'}" [editorOptions]="{readOnly: true}"></dxi-item>
        <dxi-item dataField="zIndex" [label]="{text: 'Ordre'}"></dxi-item>

        <dxi-item
          dataField="articleId"
          [label]="{text: 'Article'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: articleDataSource,
            searchEnabled: true,
            displayExpr: articleDisplayExpr,
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['libelleLong', 'codeBo'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>
        <dxi-item
          dataField="clientId"
          [label]="{text: 'Client'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: partenaireDataSource,
            searchEnabled: true,
            displayExpr: clientDisplayExpr,
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>

        <dxi-item
          [label]="{text: 'Type de classification (Article)'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: classificationTypeArticleDataSource,
            searchEnabled: true,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true,
            onValueChanged: onClassificationTypeArticleChanged,
            value: classificationTypeArticleId
          }"
        ></dxi-item>
        <dxi-item
          [label]="{text: 'Type de classification (Client)'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: classificationTypeClientDataSource,
            searchEnabled: true,
            displayExpr: 'intitule',
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true,
            onValueChanged: onClassificationTypeClientChanged,
            value: classificationTypeClientId
          }"
        ></dxi-item>

        <dxi-item
          dataField="classificationArticleId"
          [label]="{text: 'Classification Article'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: classificationArticleDataSource,
            searchEnabled: true,
            displayExpr: classificationValueDisplayExpr,
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true
          }"
        ></dxi-item>
        <dxi-item
          dataField="classificationClientId"
          [label]="{text: 'Classification Client'}"
          editorType="dxSelectBox"
          [editorOptions]="{
            dataSource: classificationClientDataSource,
            searchEnabled: true,
            displayExpr: classificationValueDisplayExpr,
            valueExpr: 'id',
            showClearButton: true,
            searchExpr: ['intitule', 'codeBo'],
            showDataBeforeSearch: true
          }"
          ></dxi-item>

        <dxi-item itemType="group" caption="Paramétrage par défaut" [colSpan]="2" [colCount]="2">
          <dxi-item
            dataField="methode"
            [label]="{text: 'Méthode'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: methodeDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule'],
              showDataBeforeSearch: true
            }"
            [colSpan]="1"
          >
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="value" [label]="{text: 'Valeur'}" [colSpan]="1">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="seuilQuantite" [label]="{text: 'Seuil quantité'}" [editorOptions]="{ showClearButton:'True' }" [colSpan]="1"></dxi-item>
          <dxi-item dataField="seuilMontant" [label]="{text: 'Seuil montant'}" editorType="dxNumberBox" [editorOptions]="{ format:'#,##0.## F', showClearButton:'True' }" [colSpan]="1"></dxi-item>
        </dxi-item>
      </dxo-form>
  </dxo-editing>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-row-dragging [allowReordering]="!clientId && !articleId" [showDragIcons]="!clientId && !articleId" [onReorder]="onReorder"></dxo-row-dragging>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" caption="Code"></dxi-column>

  <dxi-column caption="Client">
    <dxi-column dataField="clientId" [showInColumnChooser]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="clientIntitule" caption="Intitulé"></dxi-column>
    <dxi-column dataField="classificationClientId" [showInColumnChooser]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="classificationClientIntitule" caption="Catégorie"></dxi-column>
  </dxi-column>

  <dxi-column caption="Article">
    <dxi-column dataField="articleId" [showInColumnChooser]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="articleIntitule" caption="Intitulé" cellTemplate="cellTemplate">
      <div *dxTemplate="let data of 'cellTemplate'">
        <a routerLink="/article/{{data.data.articleId}}">
          {{data.value}}
        </a>
      </div>
    </dxi-column>
    <dxi-column dataField="classificationArticleId" [showInColumnChooser]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="classificationArticleIntitule" caption="Catégorie"></dxi-column>
  </dxi-column>

  <dxi-column caption="Paramétrage par défaut">
    <dxi-column dataField="methode" caption="Méthode">
      <dxo-lookup [dataSource]="methodeDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="value" caption="Valeur" dataType="number"></dxi-column>
    <dxi-column dataField="seuilQuantite" caption="Seuil quantité" dataType="number"></dxi-column>
    <dxi-column dataField="seuilMontant" caption="Seuil montant" dataType="number" format="#,##0.## F"></dxi-column>
  </dxi-column>

  <dxi-column dataField="zIndex" caption="Ordre" sortOrder="asc" [allowSorting]="false" [allowHeaderFiltering]="false" [width]="73"></dxi-column>

  <!-- Master Detail Template -->
  <div *dxTemplate="let detail of 'details'">
    <app-operation-commerciale-details-seuils [operationCommercialeDetailDto]="detail.data"></app-operation-commerciale-details-seuils>
  </div>
</dx-data-grid>

<app-popup-import-fichier-excel
  [showImportPopup]="showImportPopup"
  [retoursImport]="retoursImport"
  (closeImportPopup)="onCloseImportPopup($event)"
></app-popup-import-fichier-excel>


<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: 'operationCommercialeDetail' }"
  [(visible)]="showLoaderPopup"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
