import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { IGridService } from '../models/igrid-service';
import { DeviseDto } from '@lexi-clients/lexi';
import { DeviseListResult } from '../models/devise-list';

@Injectable({
  providedIn: 'root'
})
export class DeviseListService implements IGridService<DeviseDto> {
  baseUrl: string;

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  get(params: HttpParams): Observable<DeviseListResult> {
    return this.http.get<any>(`${this.baseUrl}/api/Devises/recherche-devexpress`, { params: params });
  }

  getById(deviseId: number): Observable<DeviseDto> {
    return this.http.get<any>(`${this.baseUrl}/api/Devises/${deviseId}`);
  }

  post(valeur: DeviseDto): Observable<any> {
    return null;
  }

  put(valeur: DeviseDto): Observable<any> {
    return null;
  }

  delete(valeur: DeviseDto): Observable<any> {
    return null;
  }
}
