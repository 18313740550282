<dx-data-grid
  id="gridContainer"
  [dataSource]="clientPrixList"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onRowInserting)="onRowInserting($event.data)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event.key)"
  (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="allowUpdating" [allowDeleting]="allowDeleting"></dxo-editing>
  <dxo-row-dragging [allowReordering]="true" [showDragIcons]="true" [onReorder]="onReorder"></dxo-row-dragging>

  <!-- Colonnes -->
  <dxi-column dataField="zIndex" caption="Ordre" sortOrder="asc" [allowSorting]="false" [width]="73"></dxi-column>
  <dxi-column dataField="codeBo" caption="Code" [allowEditing]="false" [width]="75"></dxi-column>
  <dxi-column dataField="prixId" caption="Prix">
    <dxo-lookup [dataSource]="prixDataSource" displayExpr="libelleLong" valueExpr="id"></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="clientReferenceIntitule" caption="Client référent" [allowSorting]="false" [allowEditing]="false" cellTemplate="cellTemplate">
    <div *dxTemplate="let data of 'cellTemplate'">
      <a routerLink="/client/{{data.data.clientReferenceId}}">
        <span>{{data.value}}</span>
      </a>
    </div>
  </dxi-column>
</dx-data-grid>