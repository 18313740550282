<dx-data-grid id="gridContainer" 
  [dataSource]="datasource"
  [repaintChangesOnly]="true"
  [showBorders]="true"
  (onKeyDown)="onKeyDown($event)"
  (onExporting)="onExporting($event, 'evenement-types')"
  >
  <dxo-editing
        mode="cell"
        [refreshMode]="'reshape'"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true">
  </dxo-editing>

  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-state-storing [enabled]="true" [storageKey]="'grille_evenement_types'" appStorage></dxo-state-storing>
  <dxo-scrolling mode="virtual"></dxo-scrolling>

  <dxi-column [allowHeaderFiltering]="true" dataField="intitule" caption="Intitulé"></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="defaultStatut" caption="Etat"><dxo-lookup [dataSource]="etats"></dxo-lookup></dxi-column>
  <dxi-column [allowHeaderFiltering]="true" dataField="nature" caption="Nature d'évènement"><dxo-lookup [dataSource]="natures"></dxo-lookup></dxi-column>
</dx-data-grid>
