import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class InternationalisationService {
  baseUrl: string;
  private references$: BehaviorSubject<{[key: string]: string}> = new BehaviorSubject(null);

  get currenReferences(): { [key: string]: string } {
    return this.references$?.value;
  }

  get currentReferences$(): Observable<{ [key: string]: string }> {
    return this.references$.asObservable();
  }

  constructor(private http: HttpClient, envService: EnvironmentService) {
    this.baseUrl = envService.environment.baseServiceUrl;
  }

  init() {
    this.http.get<any>(`${this.baseUrl}/ressources/vocabulaire.json`)
      .subscribe(data => this.references$.next(data));
  }

}
