import { Injectable } from '@angular/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

@Injectable()
export class ExportDataGridService {

    /**
     * Export Excel par défaut du contenu d'une data-grid.
     * 
     * Depuis la v23.1 de DevExtreme, l'implémentation par défaut a été supprimée. Il est donc nécessaire de le faire explicitement.
     * Voir le lien ci-dessous pour plus d'infos.
     * https://supportcenter.devexpress.com/ticket/details/T1177244/datagrid-pivotgrid-removed-deprecated-default-inner-export-logic
     * 
     * @param e Evénement de demande d'export
     * @param filename Nom du fichier à générer
     */
    onExporting(e: ExportingEvent, filename: string) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(filename.toUpperCase());

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function() {
            workbook.xlsx.writeBuffer().then(function(buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename + '.xlsx');
            });
        });
    }
}
