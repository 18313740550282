import { Component, Input } from '@angular/core';
import { FluxStatut } from '@lexi-clients/lexi';

@Component({
  selector: 'app-flux-statut-icon',
  templateUrl: './flux-statut-icon.component.html',
  styleUrls: ['./flux-statut-icon.component.scss'],
})
export class FluxStatutIconComponent {
  @Input() title: string;
  @Input() statut: FluxStatut;
  @Input() fontSize: string = "24px";
  FluxStatut = FluxStatut;
}
