import { Injectable } from '@angular/core';

@Injectable()
export class ObjectUtilitiesService {
  deepCopy(obj: any) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      const arrCopy = [];
      for (let i = 0; i < obj.length; i++) {
        arrCopy[i] = this.deepCopy(obj[i]);
      }
      return arrCopy;
    }

    const copy = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        copy[key] = this.deepCopy(obj[key]);
      }
    }
    return copy;
  }

  isDeepEqual(object1: any, object2: any): boolean {

    if (object1 == null && object2 == null) return true;
    
    const objectKeys1 = Object.keys(object1);
    const objectKeys2 = Object.keys(object2);

    if (objectKeys1.length !== objectKeys2.length) return false;

    for (var key of objectKeys1) {
      const value1 = object1[key];
      const value2 = object2[key];

      const isObjects = (value1 !== null && typeof value1 === 'object') && (value2 !== null && typeof value2 === 'object');

      if ((isObjects && !this.isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
          return false
      }
    }
    return true;
  }
}
